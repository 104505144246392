import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'orderBy'})

export class orderBy implements PipeTransform{
  transform(obj: any, orderFields: string): any {

    var orderType = 'ASC';

    if (orderFields[0] === '-') {
      orderFields = orderFields.substring(1);
      orderType = 'DESC';
    }

    obj.sort(function(a, b) {
      if (orderType === 'ASC') {
        if (a['data'][orderFields] < b['data'][orderFields]) return -1;
        if (a['data'][orderFields] > b['data'][orderFields]) return 1;
        return 0;
      } else {
        if (a['data'][orderFields] < b['data'][orderFields]) return 1;
        if (a['data'][orderFields] > b['data'][orderFields]) return -1;
        return 0;
      }
    });

    return obj;
  }
}
