export class VerifyAccountObject {
  formData:any;

  static create(encrypted_user_id, token, password){
    return new VerifyAccountObject(encrypted_user_id, token, password);
  }

  constructor(encrypted_user_id, token, password){
    this.formData = new FormData();

    this.formData.append('encrypted_user_id', encrypted_user_id);
    this.formData.append('token', token);
    this.formData.append('password', password);
  }
}
