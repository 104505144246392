import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared';
import { LoginComponent } from '../app/login/login.component';
import { ForgotPasswordComponent } from '../app/forgot-password/forgot-password.component';
import { UpdatePasswordComponent } from '../app/update-password/update-password.component';
import { VerifyAccountComponent } from '../app/verify-account/verify-account.component';
import { SelectAccountComponent } from './select-account/select-account.component';
import { PrivacyPolicyComponent } from './login/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './login/terms-of-use/terms-of-use.component';
import { MailfeedComponent } from './mailfeed/mailfeed.component';
import { VideocheckComponent } from './videocheck/videocheck.component';

const routes: Routes = [
    { path: '', loadChildren: './layout/layout.module#LayoutModule' },
    { path: 'login', component : LoginComponent },
    { path: 'mail-feed', component : MailfeedComponent },
    { path: 'video_check', component : VideocheckComponent },
    { path: 'privacy-policy', component : PrivacyPolicyComponent },
    { path: 'terms-of-use', component : TermsOfUseComponent },
    { path: 'forgot-password', component : ForgotPasswordComponent },
    {
        path : 'verify-account/:token/:identity', component : VerifyAccountComponent
    },
    {
        path : 'update-password/:token/:identity', component : UpdatePasswordComponent
    },
    {
        path : 'select-account', component : SelectAccountComponent
    },
    { path: 'error', loadChildren: './server-error/server-error.module#ServerErrorModule' },
    { path: 'access-denied', loadChildren: './access-denied/access-denied.module#AccessDeniedModule' },
    { path: 'not-found', loadChildren: './not-found/not-found.module#NotFoundModule' },
    { path: '**', redirectTo: 'not-found' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
