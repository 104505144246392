import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import * as XLSX from 'xlsx';
import { Router } from '@angular/router';
import {RequestOptions, Headers} from "@angular/http";
import {UtilService} from "../../util/utility.services";
import {SharedService} from "../../util/shared-service.services";
import {CommonURL} from "../../commons/commons";
import {CommonUIStrings} from "../../commons/commonUIStrings";
import {DialogResultCustomDialog} from "../../dialogs/custom-dialog/custom-dialog-component";
import { elementDef, elementEventFullName } from '@angular/core/src/view';
import { ExcelService } from 'src/app/services/excel';

@Component({
  selector: 'app-active-box-report',
  templateUrl: './active-box-report.component.html',
  styleUrls: ['./active-box-report.component.scss']
})
export class ActiveBoxReportComponent implements OnInit {

  constructor(private router: Router,
    private util: UtilService,
    private sharedService : SharedService, private excelService: ExcelService) { }

  result;
  userObject:any = {};
  authDetails:any = {};
  displayedColumns: string[] = ['city', 'branch_name', 'branch_code', 'alias', 'box_numer', 'HDMI_status','lastSeen', 'status'];
  dataSource = new MatTableDataSource();


  ngOnInit() {
    // location.reload();
    this.userObject = JSON.parse(this.util.getLocalStorageKey(CommonURL.LOCAL_STORAGE_USER_KEY));
    // console.log("this", this.userObject);
    if(this.userObject == null || this.userObject == undefined){
      this.router.navigate(['/login']);
    }
    else
    {
      this.authDetails = {
        auth_token : this.userObject.token,
        company_id : this.userObject.company_id,
        group_id: this.userObject.assigned_group,
        role_id : this.userObject.role_id
      };
      // console.log("this", this.authDetails)
      this.GetActiveBoxdata();
    }
    // console.log("this",this.sort)
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('TABLE') table: ElementRef;
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

  }

  ExportTOExcel(){
    this.excelService.exportAsExcelFile(this.result, "sample");
  return console.log("table",this.table.nativeElement)
  const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement);
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  /* save to file */
  XLSX.writeFile(wb, 'SheetJS.xlsx');

}


GetActiveBoxdata()
  {
    let headers = new Headers({ 'Authorization' : this.authDetails.auth_token });
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });

    let activeboxobject = {
      company_id : this.authDetails.company_id,
      group_id: this.authDetails.group_id,
      role_id: this.authDetails.role_id
    }
    let body = JSON.stringify(activeboxobject);

    this.sharedService.updateIsRequestOn(true);
    this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_GET_REPORT, body, options).subscribe(
      (response) => {
        // console.log("res",response.result)
        this.result = response.result;

        this.result.forEach(element => {

         element.lastSeen = element.syncedAt;
         let syncdate = new Date(element.syncedAt);
         let today = new Date();
         let diffMs = (today.getTime() - syncdate.getTime()); // milliseconds
        //  let diffDays = Math.floor(diffMs / 86400000); // days
        //  let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
         let diffMins = Math.round(diffMs / 60000); // minutes
        //  console.log(typeof(diffMins));
        //  let diffSec = Math.round((((diffMs % 86400000) % 3600000) / 60000 )/ 60000); // Seconds

        //  let str = "";
        //  if(diffDays > 0)
        //   str += diffDays + ' Days ';
        //  if(diffHrs > 0)
        //   str += diffHrs + ' Hours ';
        //  if(diffMins > 0)
        //   str += diffMins + ' Minutes ';
        //  if(diffSec)
        //   str += diffSec + ' Seconds';

         element.lastseenmin = diffMins;

         if(diffMs > 300000)
          element.status = 'Offline';
        else
          element.status = 'Live';


          delete element.syncedAt;

        });

        this.dataSource.data = this.result;
        // console.log("dar", this.dataSource)
        this.sharedService.updateIsRequestOn(false);
      },
      (error) => {
        console.log(JSON.stringify(error));
        this.sharedService.updateIsRequestOn(false);
      }
    );
  }
}
