import { Component, OnInit } from '@angular/core';
import { SharedService } from '../util/shared-service.services';
import { ActivatedRoute } from '@angular/router';
import { UtilService } from '../util/utility.services';
import { MatDialog, MatGridTileHeaderCssMatStyler } from '@angular/material';

@Component({
  selector: 'app-videocheck',
  templateUrl: './videocheck.component.html',
  styleUrls: ['./videocheck.component.scss']
})
export class VideocheckComponent implements OnInit {

  constructor(  private activeRouter:ActivatedRoute, public dialog: MatDialog) { }

    video_url:string="";
    isVideoPresent:boolean = false;
  ngOnInit() {
    this.video_url =this.activeRouter.snapshot.queryParams.video_url ;
    console.log("lee bhai ",this.video_url);
    
    this.video_url?this.isVideoPresent=true:this.isVideoPresent=false;
  }

}
