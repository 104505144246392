import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { NgbModalConfig, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {MatDialog,MatDialogConfig, MatDialogRef, MatTableDataSource, MatPaginator, MatSort} from '@angular/material';
import { Router } from '@angular/router';
import {RequestOptions, Headers} from "@angular/http";
import {UtilService} from "../../util/utility.services";
import {SharedService} from "../../util/shared-service.services";
import {CommonURL} from "../../commons/commons";
import {CommonUIStrings} from "../../commons/commonUIStrings";
import {DialogResultCustomDialog} from "../../dialogs/custom-dialog/custom-dialog-component";
import {CompanytvuserOnBoardObject} from "../../commons/classes/company_tvuser_add_object";
import {CompanytvuserUpdateObject} from "../../commons/classes/company_tvuser_update_object";

@Component({
  selector: 'app-view-branch',
  templateUrl: './view-branch.component.html',
  styleUrls: ['./view-branch.component.scss']
})
export class ViewBranchComponent implements OnInit, OnDestroy {

  userObject:any = {};
  authDetails:any = {};
  TVScreentype = CommonURL.SCREEN_TYPE;
  Timezone;
  private modalRef: NgbModalRef;
  /* Element Ref */
  displayedColumns: string[] = ['screen_type_id','alias','box_number','username','option'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('TABLE') table: ElementRef;
/* For company users */
  displayedColumnsForAdmin: string[] = ['screen_type_id','alias','box_number'];
  dataSourceForAdmin = new MatTableDataSource();
  user= {
    box_numer : '',
    username : '',
    alias: '',
    branch_id : '',
    lastchange_by : 1,
    timezone_id : '',
    screen_type_id : ''
  };

  edituser;
  BranchDet;
  UserDet;

  requiredFields:any = ["box_numer","username","branch_id", 'timezone_id', 'screen_type_id'];

  constructor(private router: Router, private util: UtilService, private sharedService : SharedService, public dialog: MatDialog,config: NgbModalConfig, private modalService: NgbModal) {
    config.backdrop = 'static';
    config.keyboard = false;
   }

  ngOnInit() {

    this.userObject = JSON.parse(this.util.getLocalStorageKey(CommonURL.LOCAL_STORAGE_USER_KEY));

    if(this.userObject == null || this.userObject == undefined)
      this.router.navigate(['/login']);
    else
    {
      this.authDetails = {
        auth_token : this.userObject.token,
        company_id : this.userObject.company_id,
        cu_id : this.userObject.cu_id
      };
      if(this.sharedService.branchObject != null)
      {
          console.log("is this not null ?", this.sharedService.branchObject)
          this.authDetails.branch_id = this.sharedService.branchObject.branch_id;
      }

      // console.log(this.authDetails);

      if(this.authDetails.branch_id == null || this.authDetails.branch_id == undefined)
        this.router.navigate(['/branch-list']);

      this.user.lastchange_by = this.authDetails.cu_id;
      this.user.branch_id = this.authDetails.branch_id;

      this.GetBranchDetails();
      this.getBranchUsers();

      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }

  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

  }


  GetBranchDetails()
  {
    let headers = new Headers({ 'Authorization' : this.authDetails.auth_token });
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });

    let groupParamsObject = {
      branch_id: this.authDetails.branch_id
    }
    let body = JSON.stringify(groupParamsObject);

    // this.sharedService.updateIsRequestOn(true);
    this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_GET_COMPANYBRANCHDETAILS, body, options).subscribe(
      (response) => {
        // console.log("this is branch", response.result);
        this.BranchDet = response.result;
        // this.sharedService.updateIsRequestOn(false);
      },
      (error) => {
        console.log(JSON.stringify(error));
        this.sharedService.updateIsRequestOn(false);
      }
    );
  }


  getBranchUsers(){

    let headers = new Headers({ 'Authorization' : this.authDetails.auth_token });
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });

    let groupParamsObject = {
      branch_id: this.authDetails.branch_id
    }
    let body = JSON.stringify(groupParamsObject);

    // this.sharedService.updateIsRequestOn(true);
    this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_GET_TV_USERS, body, options).subscribe(
      (response) => {

        this.dataSource.data = response.result;
        this.dataSourceForAdmin.data = response.result;
        // console.log("tv user", this.dataSource);
        this.UserDet = response.result;
        // this.sharedService.updateIsRequestOn(false);
      },
      (error) => {
        console.log(JSON.stringify(error));
        // this.sharedService.updateIsRequestOn(false);
      }
    );
  }

  open(content,type,data) {

    this.GetTimezondata();

    if(type == "Edit")
    {
      this.edituser = { ...data }
      delete this.edituser.password;
      // console.log(this.edituser);
    }

    this.modalRef = this.modalService.open(content, { size: 'sm' });
  }

  Addtvuser()
  {
    if(this.user.alias == '' || this.user.alias == null || this.user.alias == null) {
      this.user.alias = 'Display ' + (this.UserDet.length+1)
    }

    let user_info = {
      username : this.user.username,
      alias: this.user.alias,
      branch_id : this.user.branch_id,
      lastchange_by : 1,
      timezone_id : this.user.timezone_id,
      screen_type_id : this.user.screen_type_id
    }
    let validatedData = this.validateFields(user_info);

    if(validatedData != "" && validatedData != true)
    {
      let dialogRef;
      this.sharedService.updateIsRequestOn(false);
      this.sharedService.dialogText = {
        title : CommonUIStrings.FAILURE,
        description : this.validateFields(this.user) + ' is required',
        isInfoDialog : true,
        headerBgClass : CommonUIStrings.REJECT_POPUP_BG_CLASS,
        headerIcon : CommonUIStrings.REJECT_POPUP_ICON_CLASS
      };

      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        // console.log(result);

        if(result != undefined && result != null)
        {

        }
      });
      return false;
    }


  let headers = new Headers({ 'Authorization' : this.userObject.token });
  let options = new RequestOptions({ headers: headers }); 
  let paramsObject = CompanytvuserOnBoardObject.create(this.user);
    // return console.log("tv user alias", this.user);
  this.modalRef.close();
  this.sharedService.updateIsRequestOn(true);
  this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_ADD_COMPANY_TVUSER, paramsObject.formData, options).subscribe(
    (response) => {

      this.sharedService.dialogText = {
        title : CommonUIStrings.SUCCESS,
        description : CommonUIStrings.COMPANY_ADD_USER_SUCCESS,
        isInfoDialog : true,
        headerBgClass : CommonUIStrings.SUCCESS_POPUP_BG_CLASS,
        headerIcon : CommonUIStrings.SUCCESS_POPUP_ICON_CLASS
      };

      let dialogRef;

      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        // console.log(result);

        if(result != undefined && result != null)
        {
          this.GetBranchDetails();
          this.getBranchUsers();
        }
      });

      this.sharedService.updateIsRequestOn(false);

    }, (error) => {
      console.log(JSON.stringify(error));
      this.sharedService.updateIsRequestOn(false);
      this.sharedService.dialogText = {
        title : CommonUIStrings.FAILURE,
        description :error.message,
        isInfoDialog : true,
        headerBgClass : CommonUIStrings.REJECT_POPUP_BG_CLASS,
        headerIcon : CommonUIStrings.REJECT_POPUP_ICON_CLASS
      };

      let dialogRef;
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

      dialogRef.afterClosed().subscribe(result => {

        if(result != undefined && result != null)
        {
          this.GetBranchDetails();
          this.getBranchUsers();
        }
      });
    });
  }

  validateFields(user){
    for(let i = 0;i < this.requiredFields.length; i++)
    {
      if(user[this.requiredFields[i]] == "" && !user[this.requiredFields[i]].replace(/\s/g, '').length)
          return this.requiredFields[i];
    }

    return true;
  }

  submit(addtvuser){

  }

  edit(tvuser){

  }

  GetTimezondata()
  {
    let headers = new Headers({ 'Authorization' : this.authDetails.auth_token });
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });

    let groupParamsObject = {
      company_id: this.authDetails.company_id
    }
    let body = JSON.stringify(groupParamsObject);

    this.sharedService.updateIsRequestOn(true);
    this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_GET_TIMEZONELIST, body, options).subscribe(
      (response) => {

        // console.log(response.result);
        this.Timezone = response.result;
        this.sharedService.updateIsRequestOn(false);
      },
      (error) => {
        console.log(JSON.stringify(error));
        this.sharedService.updateIsRequestOn(false);
      }
    );
  }

  //Remove User
  Removeuser(tvuser)
  {
    console.log("REMOVE USER VALUE", tvuser)
    this.sharedService.dialogText = {
      title : 'Delete Tvuser Details',
      description :CommonUIStrings.DELETE_USER_TITLE,
      isInfoDialog : false,
      headerBgClass : CommonUIStrings.CONFIRM_POPUP_BG_CLASS,
      headerIcon : CommonUIStrings.CONFIRM_POPUP_ICON_CLASS
    };

    let dialogRef;
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

    dialogRef.afterClosed().subscribe(result => {

      if(result != undefined && result != null)
      {
        if(result)
        {
          let body = new FormData();

          body.append('tvu_id', tvuser.tv_id);
          this.DeleteUser(body);
        }
        else
        {

        }
      }
    });
  }

DeleteUser(body)
{
  let headers = new Headers({
    'authorization': this.authDetails.auth_token
     });
  let options = new RequestOptions({ headers: headers});

  this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_DELETE_TVUSER, body, options).subscribe(
    (response) => {
      this.sharedService.dialogText = {
        title : CommonUIStrings.SUCCESS,
        description :CommonUIStrings.USER_DELETED_SUCCESS,
        isInfoDialog : true,
        headerBgClass : CommonUIStrings.CONFIRM_POPUP_BG_CLASS,
        headerIcon : CommonUIStrings.CONFIRM_POPUP_ICON_CLASS
      };

      let dialogRef;
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

      dialogRef.afterClosed().subscribe(result => {

        if(result != undefined && result != null)
        {
          this.GetBranchDetails();
          this.getBranchUsers();
        }
      });
    }, (error) => {
      console.log(error);

      let dialogRef;
      this.sharedService.dialogText = {
      title : CommonUIStrings.FAILURE,
      description : error.message,
      isInfoDialog : true,
      headerBgClass : CommonUIStrings.REJECT_POPUP_BG_CLASS,
      headerIcon : CommonUIStrings.REJECT_POPUP_ICON_CLASS
    };

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);

      if(result != undefined && result != null)
      {
        this.GetBranchDetails();
        this.getBranchUsers();
      }
    });
    }
  );
}

Updateuser(user,type)
{
  // console.log("user", user)
  if(type == 'EDIT')
  {
    let validatedData = this.validateFields(user);

    if(validatedData != "" && validatedData != true)
    {
      let dialogRef;
      this.sharedService.updateIsRequestOn(false);
      this.sharedService.dialogText = {
        title : CommonUIStrings.FAILURE,
        description : this.validateFields(user) + ' is required',
        isInfoDialog : true,
        headerBgClass : CommonUIStrings.REJECT_POPUP_BG_CLASS,
        headerIcon : CommonUIStrings.REJECT_POPUP_ICON_CLASS
      };

      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        // console.log(result);

        if(result != undefined && result != null)
        {

        }
      });
      return false;
    }
  }

user.lastchange_by = this.authDetails.cu_id;
let headers = new Headers({ 'Authorization' : this.userObject.token });
let options = new RequestOptions({ headers: headers });
let paramsObject = CompanytvuserUpdateObject.create(user);
this.modalRef.close();
this.sharedService.updateIsRequestOn(true);
this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_UPDATE_TVUSER, paramsObject.formData, options).subscribe(
  (response) => {

    this.sharedService.dialogText = {
      title : CommonUIStrings.SUCCESS,
      description : CommonUIStrings.COMPANY_UPDATE_USER_SUCCESS,
      isInfoDialog : true,
      headerBgClass : CommonUIStrings.SUCCESS_POPUP_BG_CLASS,
      headerIcon : CommonUIStrings.SUCCESS_POPUP_ICON_CLASS
    };

    let dialogRef;

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);

      if(result != undefined && result != null)
      {
        this.GetBranchDetails();
        this.getBranchUsers();
      }
    });

    this.sharedService.updateIsRequestOn(false);

  }, (error) => {
    console.log(JSON.stringify(error));
    this.sharedService.updateIsRequestOn(false);
    this.sharedService.dialogText = {
      title : CommonUIStrings.FAILURE,
      description :error.message,
      isInfoDialog : true,
      headerBgClass : CommonUIStrings.REJECT_POPUP_BG_CLASS,
      headerIcon : CommonUIStrings.REJECT_POPUP_ICON_CLASS
    };

    let dialogRef;
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

    dialogRef.afterClosed().subscribe(result => {

      if(result != undefined && result != null)
      {
        this.GetBranchDetails();
        this.getBranchUsers();
      }
    });
  });
}

openLg(content) {
  this.modalService.open(content, { size: 'lg' });
}

Pwd(tvuser)
{
  console.log("tv user", tvuser)
  this.sharedService.dialogText = {
    title : 'Update Tv User Password ?',
    description :CommonUIStrings.UPDATE_USER_TITLE,
    isInfoDialog : false,
    headerBgClass : CommonUIStrings.CONFIRM_POPUP_BG_CLASS,
    headerIcon : CommonUIStrings.CONFIRM_POPUP_ICON_CLASS
  };

  let dialogRef;
  const dialogConfig = new MatDialogConfig();

  dialogConfig.disableClose = true;
  dialogConfig.autoFocus = true;
  dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

  dialogRef.afterClosed().subscribe(result => {

    if(result != undefined && result != null)
    {
      if(result)
      {
        let user = {
          tv_id : tvuser.tv_id,
          password : '1'
        }
        this.Updateuser(user,'PWD');
      }
      else
      {

      }
    }
  });

}

isEdit = false;
//Edit Branch
  EditBranch(element){
    this.isEdit = true;
    this.sharedService.branchObject = element;
    this.router.navigate(["add-company-branch"]);
  }

 ngOnDestroy(){
   console.log("on destroy getting called ?", this.isEdit);
   if(!this.isEdit) {
     console.log("are we clearing ?");
    this.sharedService.branchObject = null;
   }
 }
}
