import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogConfig ,MatDialogRef} from '@angular/material';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import { routerTransition } from '../router.animations';
import {UtilService} from "../util/utility.services";
import {SelectAccountService} from "../util/select-account.services";
import {CommonURL} from "../commons/commons";
import {RequestOptions, Headers} from "@angular/http";
import {SharedService} from "../util/shared-service.services";
import {CommonUIStrings} from "../commons/commonUIStrings";
import {DialogResultCustomDialog} from "../dialogs/custom-dialog/custom-dialog-component";
import {ForgotPasswordObject} from "../commons/classes/forgot-password-object";
declare var $: any;

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  animations: [routerTransition()]
})
export class ForgotPasswordComponent implements OnInit {

  constructor (private router: Router, private util: UtilService, private selectAccountService : SelectAccountService, private sharedService : SharedService, private activatedRoute: ActivatedRoute, public dialog: MatDialog){}

  userObject:any = {};
  forgot:any = {
    email : ""
  };

  verifyData:any = {};
  formData:any;

  ngOnInit() {
  }

  result:any = {};

  sendMail(form: NgForm):void {

    // console.log(form);
    // console.log(this.forgot);

    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    let body = ForgotPasswordObject.create(this.forgot.email);

    // console.log(body);

    let dialogRef;

    this.sharedService.updateIsRequestOn(true);
    this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_FORGOT_PASSWORD, JSON.stringify(body), headers).subscribe(
      (response) => {
        // console.log(response);
        this.sharedService.dialogText = {
          title : CommonUIStrings.SUCCESS,
          description : response.message,
          isInfoDialog : true,
          headerBgClass : CommonUIStrings.SUCCESS_POPUP_BG_CLASS,
          headerIcon : CommonUIStrings.SUCCESS_POPUP_ICON_CLASS
        };

        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
          // console.log(result);

          if(result != undefined && result != null)
          {
            this.router.navigate(['/login']);
          }
        });
      }, (error) => {
        this.sharedService.updateIsRequestOn(false);
        this.sharedService.dialogText = {
          title : CommonUIStrings.FAILURE,
          description : error.message,
          isInfoDialog : true,
          headerBgClass : CommonUIStrings.REJECT_POPUP_BG_CLASS,
          headerIcon : CommonUIStrings.REJECT_POPUP_ICON_CLASS
        };

        let dialogRef;

        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogRef = this.dialog.open(DialogResultCustomDialog, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
          // console.log(result);

          if(result != undefined && result != null)
          {

          }
        });
      }
    );

  }

}
