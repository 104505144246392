import { Injectable } from '@angular/core'
import { Http, URLSearchParams, Response } from '@angular/http'
import { RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';

import { CommonURL } from "../commons/commons";
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import 'rxjs/Rx';

declare var $: any;

@Injectable()
export class SelectAccountService {

  constructor(private http: Http, private router: Router) {

  }

  companyList:any = [];

}
