import { Component,ViewChild, OnInit, ViewEncapsulation, ElementRef } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbTimeStruct, NgbCalendar, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { MatOption, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import {MatDialog,MatDialogConfig, MatDialogRef} from '@angular/material';
import { Router } from '@angular/router';
import {RequestOptions, Headers} from "@angular/http";
import {UtilService} from "../../util/utility.services";
import {SharedService} from "../../util/shared-service.services";
import {CommonURL} from "../../commons/commons";
import {CommonUIStrings} from "../../commons/commonUIStrings";
import {DialogResultCustomDialog} from "../../dialogs/custom-dialog/custom-dialog-component";
import { DomSanitizer } from '@angular/platform-browser';
import { isNil, remove, reverse } from 'lodash';
import { UpdateMediaObject } from "../../commons/classes/update_media_object";
import { TreeviewItem, TreeviewConfig, DownlineTreeviewItem, TreeviewEventParser, OrderDownlineTreeviewEventParser, } from 'ngx-treeview';
declare var $: any;
import * as _ from 'lodash';
import { timeInterval } from 'rxjs-compat/operator/timeInterval';
import { element } from '@angular/core/src/render3';
import { slotNumberObject } from 'src/app/commons/classes/slot_number_object';
import { forEach } from '@angular/router/src/utils/collection';
import { NgxSpinnerService } from 'ngx-spinner';
import { any } from 'codelyzer/util/function';

@Component({
  selector: 'app-view-media',
  templateUrl: './view-media.component.html',
  styleUrls: ['./view-media.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers : [
    { provide: TreeviewEventParser, useClass: OrderDownlineTreeviewEventParser }
  ]
})
export class ViewMediaComponent implements OnInit {


  /* Added for EMEDIA while media update and delete */
  description;
  /*  */
  @ViewChild('update') update: ElementRef;
  branches: any = [];
  branches_res;
  screens:any = [];
  group_filter_value:any;
  group_filter;
  screentype = new FormControl();
  screentypelist:any = [
    {value:'HTML',text:'HTML link'},
    {value:"GPUBLISHER",text:'Global Publisher'},
    {value:"LPUBLISHER",text:'Local Publisher'},
    {value:"RPUBLISHER",text:'Regional Publisher'},
    {value:"LEVENTS",text:'Local Events'},
    {value:"MAINSCREEN",text:'Main Screen'}
  ];
  @ViewChild('allSelected') private allSelected: MatOption;
  @ViewChild('image_') image_: ElementRef;

  device_status = [
    {value: 'Live', viewValue: 'Live or Scheduled'},
    {value: 'Completed', viewValue: 'Completed'},
    {value: 'Deleted', viewValue: 'Deleted'}
  ];
  selectedstatus = 'Live';
  // isLoading: boolean = true;
  color = '#2196F3';
filterData;

modalChange = false;
selectedforupdate:any;

Media_details:any = {
  ctd_id : 0,
  branch_id : "",
  branch_name : "",
  h_media : "",
  h_duration : "",
  h_extension : "",
  h_type : "",
  v_media : "",
  v_duration : "",
  v_extension : "",
  v_type : "",
  company_user_id : 1
};
url;
cdate = new Date();
selectedbranch:any = [];

private modalRef: NgbModalRef;
  constructor(public sanitizer: DomSanitizer,
    private router: Router,
    private util: UtilService,
    private sharedService : SharedService,
    public dialog: MatDialog,
    private modalService: NgbModal,
    config: NgbModalConfig
    ) {
    config.backdrop = 'static';
    config.keyboard = false;
  }
  result;
  groups:any;
  Branch;
  items : TreeviewItem[];
  values: number[];
  parent = 0;
  isError : boolean = false;
  isValid :boolean = false;
  isValidFile :boolean = true;
  imagevalidcheck;
  hmaxval;
  vmaxval;
  uploaderrmsg;
  type;
  stype = 'h';
  calculated_result;

  logs_info = {
    branch_id : "",
    branch_name: "",
    email: ""
  }
  logs_details;
  logs_details_mongo;

  config = TreeviewConfig.create({
    hasAllCheckBox: true,
    hasFilter: true,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    maxHeight: 400
  });

  buttonClasses = [
    'btn-outline-primary',
    'btn-outline-secondary',
    'btn-outline-success',
    'btn-outline-danger',
    'btn-outline-warning',
    'btn-outline-info',
    'btn-outline-light',
    'btn-outline-dark'
  ];
  buttonClass = this.buttonClasses[5];


  userObject:any = {};
  authDetails:any = {};
  requiredFields:any = ['branch_id', 'branch_name'];


  ngOnInit() {

    this.userObject = JSON.parse(this.util.getLocalStorageKey(CommonURL.LOCAL_STORAGE_USER_KEY));
    // console.log("user obj", this.userObject);
    if(this.userObject == null || this.userObject == undefined)
      this.router.navigate(['/login']);
    else
    {
      this.authDetails = {
        branch_id : this.userObject.branch_id,
        auth_token : this.userObject.token,
        company_id : this.userObject.company_id,
        cu_id : this.userObject.cu_id,
        group_id : this.userObject.assigned_group,
        role_id: this.userObject.role_id
      };
        this.GetLivedata();
        this.GetBrachDetailByCompany();
        this.GetGroupdataForFilteration();
    }


  // $('.fs-button').on('click', function(){
  //   var elem = document.getElementById('fullscreen');
  //   console.log("elem", elem);
  //   if(document["webkitFullscreenElement"]) {
  //     document["webkitCancelFullScreen"]();
  //   }
  //   else {
  //     elem["webkitRequestFullScreen"]();
  //   };
  // });

  }


  getDefaultOptions() {
    const options = {
      sortField: 'name',
      sortDirection: 'asc',
      page: 0,
      // pageSize: this.pagesize
    };

    return options;
  }

  search(term: string,i) {
    if(term == null) {
      this.result[i].filterData = this.result[i].branchdetails;
    } else {
      this.result[i].filterData = this.result[i].branchdetails.filter(x =>
         x.name.trim().toLowerCase().includes(term.trim().toLowerCase())
      );
    }
  }


  openWindowCustomClass(content) {
    // console.log("content", content)
    this.modalService.open(content, { size: 'sm' });
  }

  openWindowCustomClassSecond(content,pemedia) {
    // console.log("content", pemedia);
    // this.selectedbranch = pemedia.branchdetails;
    // console.log("inside custome class", this.selectedbranch);
    // console.log("In");
    this.sharedService.updateIsRequestOn(true);
    this.selectedforupdate = _.cloneDeep(pemedia);
    // console.log("this", this.selectedforupdate);
    // console.log(this.selectedforupdate, "here");
    // console.log(this.selectedforupdate.media_url[0].media_url, "here1");
    this.Media_details.ctd_id = this.selectedforupdate.ctd_id;
    this.isError = false;
    if(!_.isEmpty(this.selectedforupdate.media_url[0]))
    {
      if(this.selectedforupdate.media_url[0].type != 'LINK')
        this.selectedforupdate.media_url[0].media_url = 'https://'+ this.selectedforupdate.media_url[0].media_url;
    }
    if(!_.isEmpty(this.selectedforupdate.media_url[1]) || this.selectedforupdate.media_url[1] != undefined)
    {
      if(this.selectedforupdate.media_url[1].type != 'LINK')
        this.selectedforupdate.media_url[1].media_url = 'https://'+ this.selectedforupdate.media_url[1].media_url;
    }
    this.GetGroupdata();
    this.sharedService.updateIsRequestOn(false);

    this.modalRef = this.modalService.open(content, { size: 'lg' });
  }

  updatemedia()
  {

    // console.log("this in", this.selectedforupdate);
    // return console.log("inside update clickded");4
    // console.log("this in update", this.selectedbranch);
    let update_screen = this.selectedforupdate.screen_type
    let branch_info = this.branches_res;
    // console.log("update_screen", update_screen);


    if(update_screen == 'GPUBLISHER' || update_screen == 'MAINSCREEN'){

      let branch_id = []; let branch_name = [];
      branch_info.forEach(element => {
        //  console.log("element", element);
        branch_id.push(element.branch_id);
        branch_name.push(element.branch_name);
        // branch_id.push(element.item.value)
        // branch_name.push(element.item.text)
      });
      this.Media_details.branch_id = branch_id.toString();
      this.Media_details.branch_name = branch_name.toString();
    }
    else {
      if (update_screen == 'HTML' ){
        // console.log("In")
        this.Media_details.h_extension = "LINK";
        this.Media_details.h_type = 'LINK';
      }
      // console.log("Inside")
      let branch_id = []; let branch_name = [];
      this.selectedbranch.forEach(element => {
        //  console.log("element", element);
        branch_id.push(element.item.value);
        branch_name.push(element.item.text);
        // branch_id.push(element.item.value)
        // branch_name.push(element.item.text)
      });
      this.Media_details.branch_id = branch_id.toString();
      this.Media_details.branch_name = branch_name.toString();
    }
    this.Media_details.company_user_id = this.authDetails.cu_id;
    this.Media_details.h_duration = this.selectedforupdate.media_url[0].duration;
    this.Media_details.v_duration = this.selectedforupdate.media_url[0].duration;

  let headers = new Headers({ 'Authorization' : this.userObject.token });
  let options = new RequestOptions({ headers: headers });
  let paramsObject = UpdateMediaObject.create(this.Media_details);

  // console.log('code',this.Media_details);
  this.modalRef.close();
  this.sharedService.updateIsRequestOn(true);
  this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_UPDATE_MEDIA, paramsObject.formData, options).subscribe(
    (response) => {

      this.sharedService.dialogText = {
        title : CommonUIStrings.SUCCESS,
        description : CommonUIStrings.MEDIA_UPDATE_SUCCESS,
        isInfoDialog : true,
        headerBgClass : CommonUIStrings.SUCCESS_POPUP_BG_CLASS,
        headerIcon : CommonUIStrings.SUCCESS_POPUP_ICON_CLASS
      };

      let dialogRef;

      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        // console.log(result);

        if(result != undefined && result != null)
        {
          this.GetLivedata();
        }
      });

      // console.log(response);
      this.sharedService.updateIsRequestOn(false);

    }, (error) => {
      console.log(JSON.stringify(error));
      this.sharedService.updateIsRequestOn(false);
      this.sharedService.dialogText = {
        title : CommonUIStrings.FAILURE,
        description :error.message,
        isInfoDialog : true,
        headerBgClass : CommonUIStrings.REJECT_POPUP_BG_CLASS,
        headerIcon : CommonUIStrings.REJECT_POPUP_ICON_CLASS
      };

      let dialogRef;
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        if(result != undefined && result != null)
        {
          this.GetLivedata();
        }
      });
    });
  }

//   tosslePerOne(all){
//     if (this.allSelected.selected) {
//      this.allSelected.deselect();
//      return false;
//  }
//    if(this.screentype.value.length==this.screentypelist.length)
//      this.allSelected.select();

//  }
  //  toggleAllSelection() {
  //    if (this.allSelected.selected) {
  //      this.screentype
  //        .patchValue([...this.screentypelist.map(item => item.value), 0]);
  //    } else {
  //      this.screentype.patchValue([]);
  //    }
  //  }

   applyBasicFilter()
  {
    this.GetLivedata();
  }

  GetLivedata()
  {

    // console.log("this group", this.group_filter_value)
    let headers = new Headers({ 'Authorization' : this.authDetails.auth_token });
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });

    let PendingParamsObject = {
      company_id: this.authDetails.company_id,
      group_id: this.authDetails.group_id,
      role_id: this.authDetails.role_id
    }
    if(this.selectedstatus == 'Deleted'){
    PendingParamsObject["Completed"] = 2;
    }else{
    PendingParamsObject[this.selectedstatus] = 1;
    }
    // console.log("pendingparams", this.screens.length);
    if(this.screens.length > 0){
      this.group_filter_value = null;
      PendingParamsObject['group_filter'] = null;
      PendingParamsObject['screen_type'] = this.screens.join();
    }
// group_filter
    if(this.group_filter_value != undefined && this.screens.length == 0){
      // console.log("this group", this.group_filter_value)
      PendingParamsObject['group_filter'] = this.group_filter_value;
    }

    // console.log("pendingparams", PendingParamsObject)
    let body = JSON.stringify(PendingParamsObject);
    // console.log("body", body);
    this.sharedService.updateIsRequestOn(true);
    this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_GET_LIVEMEDIA, body, options).subscribe(
      (response) => {
        this.result = response.result;
        // console.log("this result FOr live", this.result);
        this.result.forEach(element => {
          element.sdt = new Date(element.start_datetime);
          element.media_url = JSON.parse(element.media_url);
          const branch_id = element.acorganization_id.split(',');
          const branch_name = element.Branch_name.split(',');
          var i = 0;
          let data = [];
          branch_id.forEach(element => {
            var branch = {
              id : element,
              name : branch_name[i]
            };
            data.push(branch);
            i++;
          });
          element.branchdetails = data;
          element.filterData = data;

          // console.log("element", element)

          let days = [];
          if(element.adtype == "Daywise")
          {
            element.timeslots.forEach(d => {
              var slocal = new Date('2014-05-15T'+d.stime+'Z');
              var elocal = new Date('2014-05-15T'+d.etime+'Z');

              let ddata = {
                  day : d.day,
                  start : slocal.toLocaleString(),
                  end : elocal.toLocaleString()
                }
                days.push(ddata);
            });
          }
          element.days = days;

        });
        this.sharedService.updateIsRequestOn(false);
      },
      (error) => {
        console.log(JSON.stringify(error));
        this.sharedService.updateIsRequestOn(false);
      }
    );
  }

  ISActive(data:any,media,i){
    // console.log("data", data)
    this.sharedService.dialogText = {
      title : 'Update Media Details',
      description :CommonUIStrings.UPDATE_DATA_TITLE,
      isInfoDialog : false,
      headerBgClass : CommonUIStrings.CONFIRM_POPUP_BG_CLASS,
      headerIcon : CommonUIStrings.CONFIRM_POPUP_ICON_CLASS
    };

    let dialogRef;
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // console.log("res", result)
      if(result != undefined && result != null)
      {
        if(result)
        {
          var activeda = +data;
          let body = new FormData();

          body.append('ctd_id', media.ctd_id);
          body.append('company_user_id', this.authDetails.cu_id);
          body.append('active', activeda.toString());
          body.append('branch_id', this.result[i].acorganization_id);
          body.append('branch_name', this.result[i].Branch_name);
          this.EDMedia(body, "update");

          // console.log("body", this.EDMedia(body))
        }
        else
        {
          this.result[i].active = !data;
        }
      }
    });
  }

  MoveArchive(media,i){
    this.sharedService.dialogText = {
      title : 'Archive Media',
      description :CommonUIStrings.DELETE_DATA_TITLE,
      isInfoDialog : false,
      headerBgClass : CommonUIStrings.CONFIRM_POPUP_BG_CLASS,
      headerIcon : CommonUIStrings.CONFIRM_POPUP_ICON_CLASS
    };

    let dialogRef;
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // console.log("result archive", result)
      if(result != undefined && result != null)
      {
        if(result)
        {
          let body = new FormData();

          body.append('ctd_id', media.ctd_id);
          body.append('company_user_id', this.authDetails.cu_id);
          body.append('branch_id', this.result[i].acorganization_id);
          body.append('branch_name', this.result[i].Branch_name);
          body.append('active', '0');
          body.append('archive', '2');
          body.append('deleted_by', this.userObject.cu_name);
          this.EDMedia(body, "delete");
        }
      }
    });
  }
  EDMedia(body, calledFrom)
  {
    // let description;
    if(calledFrom == 'delete'){
       this.description = CommonUIStrings.MEDIA_UPDATED_SUCCESS;
    }
    else{
       this.description = CommonUIStrings.MEDIA_DELETE_SUCCESS;
    }
    let headers = new Headers({
      'authorization': this.authDetails.auth_token
       });
    let options = new RequestOptions({ headers: headers});

    this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_UPDATE_MEDIA, body, options).subscribe(
      (response) => {
        // console.log("emedia", response)
        this.sharedService.dialogText = {
          title : CommonUIStrings.SUCCESS,
          description : this.description,
          isInfoDialog : true,
          headerBgClass : CommonUIStrings.CONFIRM_POPUP_BG_CLASS,
          headerIcon : CommonUIStrings.CONFIRM_POPUP_ICON_CLASS
        };

        let dialogRef;
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

        dialogRef.afterClosed().subscribe(result => {

          if(result != undefined && result != null)
          {
            this.GetLivedata()
          }
        });
      }, (error) => {
        // console.log(error);

        let dialogRef;
        this.sharedService.dialogText = {
        title : CommonUIStrings.FAILURE,
        description : error.message,
        isInfoDialog : true,
        headerBgClass : CommonUIStrings.REJECT_POPUP_BG_CLASS,
        headerIcon : CommonUIStrings.REJECT_POPUP_ICON_CLASS
      };

      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        // console.log(result);

        if(result != undefined && result != null)
        {
          this.GetLivedata();
        }
      });
      }
    );
  }

    //tree view
    GetGroupdataForFilteration()
    {
     let headers = new Headers({ 'Authorization' : this.authDetails.auth_token });
     headers.append('Content-Type', 'application/json');
     let options = new RequestOptions({ headers: headers });

     let groupParamsObject = {
       company_id: this.authDetails.company_id,
       group_id: this.authDetails.group_id,
       role_id: this.authDetails.role_id
     }
     let body = JSON.stringify(groupParamsObject);

     this.sharedService.updateIsRequestOn(true);
     this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_GET_GROUP, body, options).subscribe(
       (response) => {
         this.group_filter = response.result
        //  console.log(response.result, "GROUP DATA");
       },
       (error) => {
         console.log(JSON.stringify(error));
         this.sharedService.updateIsRequestOn(false);
       }
     );
    }


  //tree view
  GetGroupdata()
   {
    let headers = new Headers({ 'Authorization' : this.authDetails.auth_token });
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });

    let groupParamsObject = {
      company_id: this.authDetails.company_id,
      group_id: this.authDetails.group_id,
      role_id: this.authDetails.role_id
    }
    let body = JSON.stringify(groupParamsObject);

    this.sharedService.updateIsRequestOn(true);
    this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_GET_GROUP, body, options).subscribe(
      (response) => {
        this.group_filter = response.result
        console.log(response.result, "GROUP DATA");
        this.groups = this.converttree(response.result,this.parent);
        this.items = this.gettreeview(this.groups);
        this.sharedService.updateIsRequestOn(false);
      },
      (error) => {
        console.log(JSON.stringify(error));
        this.sharedService.updateIsRequestOn(false);
      }
    );
   }
// ADDED
   GetBrachDetailByCompany(){
    let headers = new Headers({
      'authorization': this.authDetails.auth_token
       });
    let options = new RequestOptions({ headers: headers});

    let BranchDetailsObject = {
      company_id : this.authDetails.company_id,
      group_id: this.authDetails.group_id,
      role_id: this.authDetails.role_id
    };
    let body = JSON.stringify(BranchDetailsObject);

    this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_GET_BRANCH_LIST, body, options).subscribe(
      (response) => {
        // console.log("branhc", response.result);
        this.branches_res = response.result;
        //remove in future.
        // this.branches[0].is_active=0;
        // console.log("branches",this.branches_res);
      }, (error) => {
        console.log(error)
      }
    );
  }

   GetBranchList(data:DownlineTreeviewItem[])
   {
    if(data.length == 0)
    {
      return;
    }
    const items = this.getgrouparray(data);
    let headers = new Headers({ 'Authorization' : this.authDetails.auth_token });
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });

    let groupParamsObject = {
      Group_ids: items.toString(),
      role_id : this.authDetails.role_id,
      group_id: this.authDetails.group_id,
      company_id: this.authDetails.company_id
    }
    let body = JSON.stringify(groupParamsObject);

    this.sharedService.updateIsRequestOn(true);
    this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_GET_BRANCH, body, options).subscribe(
      (response) => {
        // this.selectedbranch = response.result;
        console.log("branch", response.result);
        this.Branch = this.getbranchtreeview(response.result);
        this.sharedService.updateIsRequestOn(false);
      },
      (error) => {
        console.log(JSON.stringify(error));
        this.sharedService.updateIsRequestOn(false);
      }
    );
   }

   getgrouparray(data:DownlineTreeviewItem[])
   {
    //  console.log(data);
      const array = [];
      data.forEach(downlineItem => {
            array.push(downlineItem.item.value);
            let parent = downlineItem.parent;
            while (!isNil(parent)) {
              if(parent.item.checked)
                array.push(parent.item.value);
              parent = parent.parent;
            }
      });
      var myNewList =  Array.from(new Set(array));
      return myNewList;
   }

   getbranchtreeview(data)
   {
    const items: TreeviewItem[] = [];
    for (let i = 0; i < data.length; i++) {
        const value: any = data[i].branch_id;
        let checked = false;
        this.selectedforupdate.branchdetails.forEach(element => {
          if(element.id == data[i].branch_id)
          {
            checked = true;
            return false;
          }
        });

        const item = new TreeviewItem({ text: data[i].branch_name + ' ' + data[i].branch_code + ' ' + data[i].address, value: value, checked: checked });
        items.push(item);
    }
    return items;
   }

   gettreeview(data)
   {
    const items: TreeviewItem[] = [];
    for (let i = 0; i < data.length; i++) {
        const value: any = data[i].gd_id;
        const checked = true;
        let child:any;
        if(data[i].children)
        {
          child = this.gettreechild(data[i].children)
        }
        const item = new TreeviewItem({ text: data[i].gd_name + ' ' + data[i].timezone + ' ' + data[i].screen_type_name, value: value, checked: checked,children: child });
        items.push(item);
    }
    // console.log(items);
    return items;
   }

   gettreechild(data)
   {
    const items: TreeviewItem[] = [];
    for (let i = 0; i < data.length; i++) {
        const value: any = data[i].gd_id;
        const checked = true;
        let child:any;
        if(data[i].children)
        {
          child = this.gettreechild(data[i].children)
        }
        const item = new TreeviewItem({ text: data[i].gd_name + ' ' + data[i].timezone + ' ' + data[i].screen_type_name, value: value, checked: checked,children: child });
        items.push(item);
    }
    return items;
   }


   converttree(data,parent)
   {
    var out = []
    for(var i in data) {
      if(data[i].parent == parent) {
          var children = this.converttree(data, data[i].gd_id)
          if(children.length) {
              data[i].children = children
            }
            out.push(data[i])
        }
    }
    return out

    // //   console.log(root);
   }
   //attach file
   attachFile(ev, stype){
     this.modalChange = true;
    //  this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.Media_details.h_media);
    if (ev.target.files && ev.target.files[0] && this.validateFileType(ev.target.files[0].type)) {
      this.isValid = true;
      this.isError = false;
      this.imagevalidcheck = false;
      this.stype = stype;
      var reader = new FileReader();
      reader.readAsDataURL(ev.target.files[0]);
      var file_type = ev.target.files[0].type.split('/');
      this.type = file_type[0].toUpperCase();
      var mediasize,file,videon;
      if(stype == 'h')
      {
        this.Media_details.h_media = ev.target.files[0];
        this.Media_details.h_extension = this.Media_details.h_media.type;
        this.Media_details.h_type = file_type[0].toUpperCase();
        this.selectedforupdate.media_url[0].extension = this.Media_details.h_media.type;
        this.selectedforupdate.media_url[0].type = file_type[0].toUpperCase();
        mediasize = this.Media_details.h_media.size;
        file = document.getElementById('horizontalex');
        videon = 'videoh'
      }
      else
      {
        this.Media_details.v_media = ev.target.files[0];
        this.Media_details.v_extension = this.Media_details.v_media.type;
        this.Media_details.v_type = file_type[0].toUpperCase();
        this.selectedforupdate.media_url[1].extension = this.Media_details.v_media.type;
        this.selectedforupdate.media_url[1].type = file_type[0].toUpperCase();
        mediasize = this.Media_details.v_media.size;
        file = document.getElementById('vfile');
        videon = 'videov'
      }

      if(this.type == 'IMAGE') {
        this.sharedService.updateIsRequestOn(true);
        if(stype == 'h')
          this.hmaxval = 30;
        else
          this.vmaxval = 30;
          if(mediasize > 5242880)
          {
            if(stype == 'h')
              this.selectedforupdate.media_url[0].media_url = ''
            else
              this.selectedforupdate.media_url[1].media_url = ''
            file.value = '';
            this.isError = true;
            this.imagevalidcheck = true;
            this.uploaderrmsg = "File size is too large <br/> Image should be less than 5 MB";
            this.sharedService.updateIsRequestOn(false);
          }
          else
          {
            reader.onload  = (e:any)  => {
              if(stype == 'h')
                this.selectedforupdate.media_url[0].media_url = reader.result
              else
                this.selectedforupdate.media_url[1].media_url = reader.result
              // $('#__'+videon).html("<img style='max-width: 100%; max-height: 100%' src=" + reader.result + " />");
              this.sharedService.updateIsRequestOn(false);
            };
          }
      }
      if(this.type == 'VIDEO') {
        let file_ext;
        if(stype == 'h')
          file_ext = this.Media_details.h_extension;
        else
          file_ext = this.Media_details.v_extension;

        let instance:any = this;
        this.sharedService.updateIsRequestOn(true);
        if(mediasize > 20971520)
        {
              if(stype == 'h')
                this.selectedforupdate.media_url[0].media_url = ''
              else
                this.selectedforupdate.media_url[1].media_url = ''
              file.value = '';
              this.isError = true;
              this.imagevalidcheck = true;
              this.uploaderrmsg = "File size is too large <br/> Video should be less than 20 MB";
              if(stype == 'h')
                this.Media_details.h_type = ''
              else
                this.Media_details.v_type = ''
              this.sharedService.updateIsRequestOn(false);
        }
        else
        {
          reader.onload = (e) => {
            if(stype == 'h')
            {
              this.hmaxval = 60;
              this.selectedforupdate.media_url[0].media_url = reader.result
            }
            else
            {
              this.vmaxval = 60;
              this.selectedforupdate.media_url[1].media_url = reader.result
            }
            // $('#__'+videon).html("<video id='"+videon+"' controls style='max-width: 100%; max-height: 100%'><source src=" + reader.result + " type=" + file_ext +" ></video>");
            setTimeout(() => {
              let videoTag:any;
              if(stype == 'h')
                videoTag = document.getElementById("videoh");
              else
                videoTag = document.getElementById("videov");

              var video = $('#'+videon);
              // console.log('videoTag: ', videoTag);
              // console.log("document.getElementsByTagName: ", videoTag.duration);
              // if(!((video[0].videoWidth <= 1080 && video[0].videoHeight <= 1920) || (video[0].videoWidth <= 1920 && video[0].videoHeight <= 1080)))
              // {
              //       if(stype == 'h')
              //       {
              //         this.selectedforupdate.media_url[0].media_url = ''
              //         this.Media_details.h_type = ''
              //       }
              //       else
              //       {
              //         this.Media_details.v_type = ''
              //         this.selectedforupdate.media_url[1].media_url = ''
              //       }
              //       file.value = '';
              //       this.isError = true;
              //       this.imagevalidcheck = true;
              //       this.uploaderrmsg = "<b>Please check your video dimentions</b><br/>Allowed Maximum height: 1920 px or 1080 px<br/>Allowed Maximum width: 1080 px or 1920 px";
              // }
              let duration:number = parseInt(videoTag.duration.toString());
              if(stype == 'h')
              {
                this.selectedforupdate.media_url[0].duration = duration
                this.Media_details.h_duration = duration;
              }
              else
              {
                this.selectedforupdate.media_url[1].duration = duration
                this.Media_details.v_duration = duration;
              }


              if(duration > 60)
              {
                if(stype == 'h')
                {
                  this.selectedforupdate.media_url[0].media_url = ''
                  this.Media_details.h_type = ''
                }
                else
                {
                  this.Media_details.v_type = ''
                  this.selectedforupdate.media_url[1].media_url = ''
                }
                file.value = '';
                this.isError = true;
                this.imagevalidcheck = true;
                this.uploaderrmsg = "Video duration should be less than 40 sec.";
              }

              this.sharedService.updateIsRequestOn(false);
            }, 4000);
          };
        }
      }
    } else {
      // $('#_file').html("");
      if(stype == 'h')
        this.selectedforupdate.media_url[0].media_url = ''
      else
        this.selectedforupdate.media_url[1].media_url = ''
      file.value = '';
      this.isError = true;
      this.isValid = false;
    }
    // console.log("SLOT OBJ : ",this.Media_details);
  }
  validateFileType(type) {
    for(let i=0; i< CommonURL.FILE_TYPES.length; i++ ) {
      if(type == CommonURL.FILE_TYPES[i].type){
        this.isValidFile = true;
        return true
      }
    }
    this.isValidFile = false;
    return false;
  }



  validateFields(){
    for(let i = 0;i < this.requiredFields.length; i++)
    {
      if(this.Media_details[this.requiredFields[i]] == "" && !this.Media_details[this.requiredFields[i]].replace(/\s/g, '').length)
          return this.requiredFields[i];
    }

    return true;
  }

  split_tvu_id;
  yuhuu= [];
  ace = [];
  calci;
  showStatus(status, slotnumber){
    // console.log("status", slotnumber);

    this.util.setLocalstorageKey(CommonURL.SLOT_NUMBER, slotnumber);
    this.router.navigate([]).then( result => { window.open('/log-status', '_blank')})
  }

  closeModal(){
    this.modalChange = false;
    this.modalService.dismissAll();
  }

  statusss(slot){
    console.log("slot", slot);
  }

  ImageToggle(elem){
    // this.image_.nativeElement.requestFullscreen();
    elem.children[0].requestFullscreen();
  }

}
