import { Component, OnDestroy, OnInit } from '@angular/core';
import {MatDialog,MatDialogConfig, MatDialogRef} from '@angular/material';
import { Router, NavigationEnd } from '@angular/router';
import {RequestOptions, Headers} from "@angular/http";
import {UtilService} from "../../util/utility.services";
import {SharedService} from "../../util/shared-service.services";
import {CommonURL} from "../../commons/commons";
import {CommonUIStrings} from "../../commons/commonUIStrings";
import {CompanyBranchOnBoardObject} from "../../commons/classes/company_branch_add_object";
import {CompanyBranchUpdateObject} from "../../commons/classes/company_branch_update_object";
import {DialogResultCustomDialog} from "../../dialogs/custom-dialog/custom-dialog-component";
import { TreeviewItem, TreeviewConfig} from 'ngx-treeview';

@Component({
  selector: 'app-add-company-branch',
  templateUrl: './add-company-branch.component.html',
  styleUrls: ['./add-company-branch.component.scss']
})
export class AddCompanyBranchComponent implements OnInit, OnDestroy {
  constructor(private router: Router, private util: UtilService, private sharedService : SharedService, public dialog: MatDialog) {
  }

  userObject:any = {};
  authDetails:any = {};
  edit:boolean = false;

  TVScreentype = CommonURL.SCREEN_TYPE;
  Timezone;
  parent = 0;
  groups:any;
  items : TreeviewItem[];
  value: number;
  editUserValue;

  config = TreeviewConfig.create({
    hasFilter: true,
    hasCollapseExpand: true
  });

  company_Branch_details:any = {
    branch_name : "",
    branch_code : null,
    address : "",
    pincode : "",
    city : "",
    state : "",
    country : "",
    latitude : "",
    longitude : "",
    timezone_id : "",
    screen_type_id : "",
    group_id : "",
    company_id : 1,
    is_active : 1
  }

  requiredFields:any = ["group_id","screen_type_id","timezone_id",'branch_name', 'branch_code', 'address', 'pincode', 'city', 'state', 'country'];

  ngOnInit() {
    console.log("HI")
    // window.location.replace('/add-company-branch');
    // window.location.reload();
    this.userObject = JSON.parse(this.util.getLocalStorageKey(CommonURL.LOCAL_STORAGE_USER_KEY));

    if(this.userObject == null || this.userObject == undefined)
      this.router.navigate(['/login']);
    else
    {
      this.authDetails = {
        auth_token : this.userObject.token,
        company_id : this.userObject.company_id,
        cu_id : this.userObject.cu_id,
        role_id : this.userObject.role_id
      };
      if(this.sharedService.branchObject != null)
      {
          this.company_Branch_details = { ...this.sharedService.branchObject }
          this.value = this.company_Branch_details.group_id;
          this.edit = true;
          delete this.sharedService.branchObject
      }

      this.GetTimezondata();
      this.GetGroupdata();
    }
  }


  GetGroupdata()
   {
    let headers = new Headers({ 'Authorization' : this.authDetails.auth_token });
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });

    let groupParamsObject = {
      company_id: this.authDetails.company_id,
      role_id : this.authDetails.role_id
    }
    let body = JSON.stringify(groupParamsObject);

    this.sharedService.updateIsRequestOn(true);
    this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_GET_GROUP_FOR_SA, body, options).subscribe(
      (response) => {
        // console.log("this", response);
        this.groups = this.converttree(response.result,this.parent);
        this.items = this.gettreeview(this.groups);
        // console.log(this.items);
        this.sharedService.updateIsRequestOn(false);
      },
      (error) => {
        console.log(JSON.stringify(error));
        this.sharedService.updateIsRequestOn(false);
      }
    );
   }

   converttree(data,parent)
   {
    var out = []
    for(var i in data) {
      if(data[i].parent == parent) {
          var children = this.converttree(data, data[i].gd_id)
          if(children.length) {
              data[i].children = children
            }
            out.push(data[i])
        }
    }
    return out

    //   console.log(root);
   }

   gettreeview(data)
   {
    const items: TreeviewItem[] = [];
    for (let i = 0; i < data.length; i++) {
        const value: any = data[i].gd_id;
        let child:any;
        if(data[i].children)
        {
          child = this.gettreechild(data[i].children)
        }
        const item = new TreeviewItem({ text: data[i].gd_name + ' ' + data[i].timezone + ' ' + data[i].screen_type_name, value: value,children: child });
        items.push(item);
    }
    // console.log(items);
    return items;
   }

   gettreechild(data)
   {
    const items: TreeviewItem[] = [];
    for (let i = 0; i < data.length; i++) {
        const value: any = data[i].gd_id;
        let child:any;
        if(data[i].children)
        {
          child = this.gettreechild(data[i].children)
        }
        const item = new TreeviewItem({ text: data[i].gd_name + ' ' + data[i].timezone + ' ' + data[i].screen_type_name, value: value,children: child });
        items.push(item);
    }
    return items;
   }

  GetTimezondata()
  {
    let headers = new Headers({ 'Authorization' : this.authDetails.auth_token });
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });

    let groupParamsObject = {
      company_id: this.authDetails.company_id
    }
    let body = JSON.stringify(groupParamsObject);

    this.sharedService.updateIsRequestOn(true);
    this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_GET_TIMEZONELIST, body, options).subscribe(
      (response) => {

        // console.log(response.result);
        this.Timezone = response.result;
        this.sharedService.updateIsRequestOn(false);
      },
      (error) => {
        console.log(JSON.stringify(error));
        this.sharedService.updateIsRequestOn(false);
      }
    );
  }

SaveCompanyBranch()
{

    this.company_Branch_details.group_id = this.value;
    if(!this.edit)
      this.company_Branch_details.company_id = this.authDetails.company_id;
    else
      this.company_Branch_details.lastchange_by = this.authDetails.cu_id;

    let validatedData = this.validateFields();

    if(validatedData != "" && validatedData != true)
    {
      let dialogRef;
      this.sharedService.updateIsRequestOn(false);
      this.sharedService.dialogText = {
        title : CommonUIStrings.FAILURE,
        description : this.validateFields() + ' is required',
        isInfoDialog : true,
        headerBgClass : CommonUIStrings.REJECT_POPUP_BG_CLASS,
        headerIcon : CommonUIStrings.REJECT_POPUP_ICON_CLASS
      };

      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        // console.log(result);

        if(result != undefined && result != null)
        {

        }
      });
      return false;
    }

  let paramsObject;
  let callurl;
  if(!this.edit){
     paramsObject = CompanyBranchOnBoardObject.create(this.company_Branch_details);
     callurl = CommonURL.URL_ADD_COMPANY_BRANCH;
  }
  else
  {
    paramsObject = CompanyBranchUpdateObject.create(this.company_Branch_details);
    callurl = CommonURL.URL_UPDATE_BRANCH;
  }

  let headers = new Headers({ 'Authorization' : this.userObject.token });
  let options = new RequestOptions({ headers: headers });


  this.sharedService.updateIsRequestOn(true);
  this.util.callPostApi(CommonURL.BASE_URL + callurl, paramsObject.formData, options).subscribe(
    (response) => {

      let datamsg;
      if(this.edit)
        datamsg = CommonUIStrings.COMPANY_BRANCH_UPDATE_SUCCESS;
      else
        datamsg = CommonUIStrings.COMPANY_BRANCH_ADD_SUCCESS;

      this.sharedService.dialogText = {
        title : CommonUIStrings.SUCCESS,
        description : datamsg,
        isInfoDialog : true,
        headerBgClass : CommonUIStrings.SUCCESS_POPUP_BG_CLASS,
        headerIcon : CommonUIStrings.SUCCESS_POPUP_ICON_CLASS
      };

      let dialogRef;

      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        // console.log(result);

        if(result != undefined && result != null)
        {
          this.router.navigate(['branch-list']);
        }
      });

      // console.log(response);
      this.sharedService.updateIsRequestOn(false);

    }, (error) => {
      console.log(JSON.stringify(error));
      this.sharedService.updateIsRequestOn(false);
      this.sharedService.dialogText = {
        title : CommonUIStrings.FAILURE,
        description :error.message,
        isInfoDialog : true,
        headerBgClass : CommonUIStrings.REJECT_POPUP_BG_CLASS,
        headerIcon : CommonUIStrings.REJECT_POPUP_ICON_CLASS
      };

      let dialogRef;
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        // console.log(result);

        if(result != undefined && result != null)
        {

        }
      });
    });
}

  validateFields(){
    for(let i = 0;i < this.requiredFields.length; i++)
    {
      if(this.company_Branch_details[this.requiredFields[i]] == "" && !this.company_Branch_details[this.requiredFields[i]].replace(/\s/g, '').length)
          return this.requiredFields[i];
    }

    return true;
  }

  ngOnDestroy(){
    // this.company_Branch_details = {
    //   branch_name : "",
    //   branch_code : null,
    //   address : "",
    //   pincode : "",
    //   city : "",
    //   state : "",
    //   country : "",
    //   latitude : "",
    //   longitude : "",
    //   timezone_id : "",
    //   screen_type_id : "",
    //   group_id : "",
    //   company_id : 1,
    //   is_active : 1
    // }

    this.company_Branch_details.branch_name = "";
    this.company_Branch_details.branch_code = "";
    this.company_Branch_details.address = "";
    this.company_Branch_details.pincode = "";
    this.company_Branch_details.city = "";
    this.company_Branch_details.state = "";
    this.company_Branch_details.country = "";
    this.company_Branch_details.latitude = "";
    this.company_Branch_details.longitude = "";
    this.company_Branch_details.timezone_id = "";
    this.company_Branch_details.timezone_id = "";
    this.company_Branch_details.group_id = "";
    // this.company_Branch_details.company_id = 1;
    // this.company_Branch_details.company_id = 1;
  }
}
