import { ViewChild,Component, OnInit } from '@angular/core';
import {MatDialog,MatDialogConfig, MatDialogRef} from '@angular/material';
import { FormControl,FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbTimeStruct, NgbDateParserFormatter, NgbDate, NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import {RequestOptions, Headers} from "@angular/http";
import {UtilService} from "../../util/utility.services";
import {SharedService} from "../../util/shared-service.services";
import {CommonURL} from "../../commons/commons";
import {CommonUIStrings} from "../../commons/commonUIStrings";
import {DialogResultCustomDialog} from "../../dialogs/custom-dialog/custom-dialog-component";
import { TreeviewItem, TreeviewConfig, DownlineTreeviewItem, TreeviewEventParser, OrderDownlineTreeviewEventParser, } from 'ngx-treeview';
import { isNil, remove, reverse } from 'lodash';
import {AddMediaObject} from "../../commons/classes/add_media_object";
import { DomSanitizer } from '@angular/platform-browser';
declare var $: any;
import * as moment from 'moment';
import * as _ from 'lodash';
import { NgbDateCustomParserFormatter } from 'src/app/commons/dateformat';

@Component({
  selector: 'app-html-link',
  templateUrl: './html-link.component.html',
  styleUrls: ['./html-link.component.scss'],
  providers : [
    { provide: TreeviewEventParser, useClass: OrderDownlineTreeviewEventParser },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ]
})
export class HtmlLinkComponent implements OnInit {


public now;
currentTime = new Date().getMinutes();
currentHour = new Date().getHours();
result;
groups_n: TreeviewItem[] = [];
items : TreeviewItem[] = [];

currentTimeThirty = new Date().getMinutes();
  constructor(
    public sanitizer: DomSanitizer,
    private router: Router,
    private util: UtilService,
    private _formBuilder: FormBuilder,
    private sharedService : SharedService,
    public dialog: MatDialog,
    private calendar: NgbCalendar) {
      // for setting current start date while selecting date & not allowing to select the date before today's
      this.StartDate = calendar.getToday();
      this.mindate = calendar.getToday();

      // setInterval(() => {
      //   this.StartTime = { hour: new Date().getHours(), minute: new Date().getMinutes() }
      // }, 1);
    }

  ngOnInit() {

    this.userObject = JSON.parse(this.util.getLocalStorageKey(CommonURL.LOCAL_STORAGE_USER_KEY));

    if(this.userObject == null || this.userObject == undefined)
      this.router.navigate(['/login']);
    else
    {
      this.authDetails = {
        branch_id : this.userObject.branch_id,
        auth_token : this.userObject.token,
        company_id : this.userObject.company_id,
        cu_id : this.userObject.cu_id,
        role_id: this.userObject.role_id,
        group_id: this.userObject.assigned_group,
      };

      // this.loadAPI = new Promise((resolve) => {
      //   this.url.forEach(element => {
      //     this.util.loadScript("assets/js/"+element+".js");
      //   });
      // });
        this.firstFormGroup = this._formBuilder.group({
           firstCtrl: ['', Validators.required]
        });
        this.secondFormGroup = this._formBuilder.group({
           secondCtrl: ['', Validators.required]
        });
        if(this.authDetails.role_id == 1 || this.authDetails.role_id == 2 || this.authDetails.role_id == 3){
          this.GetAllGroupdataForSA();
        }
        else{
          this.GetGroupdata();
        }
    }
   }

  expiryDateOptions:any;
  loadAPI: Promise<any>;
  isLinear = false;
  StartDate: any;
  // :  NgbDateStruct = undefined
  EndDate: any;
  StartTime: any= {hour: this.currentHour, minute: this.currentTime, second: 0};
  EndTime: any = {hour: this.currentHour, minute: this.currentTime, second: 0};
  durationVertical = 30;
  durationHorizontal = 30;
  view: boolean = true;
  screenType:number = 1;
  options:boolean = true;
  mindate:NgbDateStruct;
  maxdate:any;
  mintime:any;
  maxtime:any;
  url;
  groups:any;
  Branch;
  timeError:boolean = false;
  userObject:any = {};
  authDetails:any = {};
  selectedGroups;

  parent = 0;
  selectedbranch:any = [];

  Media_details:any = {
    branch_id : "",
    branch_name : "",
    company_id : "",
    slot : "Pending",
    screen_type : "HTML",
    h_media : "",
    h_duration : 30,
    h_extension : "",
    h_type : "",
    v_media : "",
    v_duration : 30,
    v_extension : "",
    v_type : "",
    title : "",
    is_title_show : 0,
    start_datetime : "",
    end_datetime : "",
    adtype : "Fulltime",
    days : "",
    dstime : "",
    detime : "",
    change : "New HTML LINK",
    company_user_id : 1,
    do : 1,
    tv_screen_typee : ""
  };

  requiredFields:any = ['branch_id', 'branch_name', 'title', 'start_datetime', 'end_datetime'];
  toppings = new FormControl();
  // timeToChange(date, type){
  //   if(type == 'start') {
  // //     console.log("date: ", date);
  //     let stime = Object.assign({}, date);
  //     this.slot_object.etime = moment(stime).add(30, 'minutes');
  //     if(this.slot_object.etime && this.slot_object.etime < date) {
  //       //this.Media_details.cont_startdate = null;
  //       this.maxDateError = true;
  //     } else {
  //       this.maxDateError = false;
  //     }
  //     this.slot_object.stime = date;

  //   }
  //   if(type == 'end') {
  //     if(this.slot_object.stime && this.slot_object.stime > date) {
  //       //this.Media_details.cont_enddate = null;
  //       this.minDateError = true;
  //     } else {
  //       this.minDateError = false;
  //     }
  //     this.slot_object.etime = date;
  //   }
  // }.0000000000

   firstFormGroup: FormGroup;
   secondFormGroup: FormGroup;
   thirdFormGroup: FormGroup;
   fourthFormGroup: FormGroup;
   endtime:any = {};
   isTimeValidate: boolean = false;


   schedulerTime($event,index,value){
    const values = $event

    if(this.day[index] == value){

      // console.log("dayy",this.day);
      this.timeValidationForEnd(values);
    }

  }

    getTime($event , string){
      // console.log("strings", string);
    const value = $event;
    // console.log("value", value);
    if (!value) {
      this.endtime.valid = true;
      return null;
    }
    // console.log(this.EndDate);
    if(this.EndDate == undefined || this.EndDate == null){
      this.timeError = true;
    }else{
    this.timeError = false;
    }
    let StartDateDay = this.StartDate.day;
    let EndDateDay =   this.EndDate.day;

    let startDateMonth = this.StartDate.month;
    let endDateMonth = this.EndDate.month;

    let startDateYear = this.StartDate.year;
    let endDateYear = this.EndDate.year;

    if(StartDateDay === EndDateDay && startDateMonth == endDateMonth  && startDateYear == endDateYear){

    // console.log("in");
    if(string == 'start'){
    this.endtime.valid = false;
    this.timeValidationForStart(value);
    }
    if(string == 'end'){
      this.endtime.valid = false;
      this.timeValidationForEnd(value);
    }
   }

    this.endtime.valid = true;
    return null;
  }

  schedularTimeValidation(day,j,i){
    let startTimeHour = this.dstime['S'+day][j].hour;
    let startTimeMinute = this.dstime['S'+day][j].minute;
    let endTimeHour = this.detime['E'+day][j].hour;
    let endTimeMinute = this.detime['E'+day][j].minute;
    if(startTimeHour >= endTimeHour && startTimeMinute >= endTimeMinute){
      this.isTimeValidate = true;
      this.daysdefault[i].Times[j].valid = false;
    }
    else{
      this.isTimeValidate = false;
      this.daysdefault[i].Times[j].valid = true;
    }
  }

  timeValidationForEnd(endTime){
    let startTimeHour =  this.StartTime.hour;
    let startTimeMinute =  this.StartTime.minute;
    // console.log("start time hour", startTimeHour);
    // console.log("start time min", startTimeMinute);
    let endTimeHour = endTime.hour;
    let endTimeMinute = endTime.minute;
    // console.log("end time hour", endTimeHour);
    // console.log("end time min", endTimeMinute);
    if(!!(startTimeHour >= endTimeHour && startTimeMinute >= endTimeMinute)){
      // console.log("aa",(startTimeHour >= endTimeHour && startTimeMinute >= endTimeMinute));
      this.isTimeValidate = true;
      // return startTime < endTime;
    }
    else{
      this.isTimeValidate = false;
    }
  }

  timeValidationForStart(startTime){
    let startTimeHour =  startTime.hour;
    let startTimeMinute =  startTime.minute;
    // console.log("start time hour", startTimeHour);
    // console.log("start time min", startTimeMinute);
    let endTimeHour = this.EndTime.hour;
    let endTimeMinute = this.EndTime.minute;
    // console.log("end time hour", endTimeHour);
    // console.log("end time min", endTimeMinute);
    if(!!(startTimeHour >= endTimeHour && startTimeMinute >= endTimeMinute)){
      this.isTimeValidate = true;
      // return startTime < endTime;
    }
    else{
      this.isTimeValidate = false;
    }
  }

  GetGroupdata()
  {
   let headers = new Headers({ 'Authorization' : this.authDetails.auth_token });
   headers.append('Content-Type', 'application/json');
   let options = new RequestOptions({ headers: headers });

   let groupParamsObject = {
     company_id: this.authDetails.company_id,
     group_id: this.authDetails.group_id,
     role_id: this.authDetails.role_id
   }
   let body = JSON.stringify(groupParamsObject);

   this.sharedService.updateIsRequestOn(true);
   this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_GET_PARENT_GROUP, body, options).subscribe((resp) => {
     // console.log("resp", resp)
       let resp_new = resp.result;

       this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_GET_GROUP, body, options).subscribe(
         (response) => {
           // console.log("response", response.result);
           for(let i = 0; i<resp_new.length; i++){
             let b = this.converttree(response.result,resp_new[i].parent);
             b.forEach((ele) => {
               this.groups_n.push(ele)
             })
             // console.log("this groups",this.groups);
           }
           const arr = Array.from(new Set(this.groups_n));
             let a = this.gettreeview(arr)
             // this.allgroups.push(a[0]);
             a.forEach((element) => {
               this.items.push(element);
             })
           this.result = response.result;
           // console.log(" view group respose !!", response);

           this.sharedService.updateIsRequestOn(false);

         },
         (error) => {
           console.log(JSON.stringify(error));
           this.sharedService.updateIsRequestOn(false);
         }
       );
     },
     (error) => {
       console.log(JSON.stringify(error));
       this.sharedService.updateIsRequestOn(false);
     }
   );
  }

  GetAllGroupdataForSA()
 {
  let headers = new Headers({ 'Authorization' : this.authDetails.auth_token });
  headers.append('Content-Type', 'application/json');
  let options = new RequestOptions({ headers: headers });

  let groupParamsObject = {
    company_id: this.authDetails.company_id
  }
  let body = JSON.stringify(groupParamsObject);

  this.sharedService.updateIsRequestOn(true);
  this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_GET_GROUP_FOR_SA, body, options).subscribe(
    (response) => {
     // console.log("RESS", response)
      this.result = response.result;
      this.groups = this.converttree(response.result,this.parent);
      this.items = this.gettreeview(this.groups);
      this.sharedService.updateIsRequestOn(false);
    },
    (error) => {
      console.log(JSON.stringify(error));
      this.sharedService.updateIsRequestOn(false);
    }
  );
 }

  checkchanged($event,i,value){
  const values = $event
  // console.log('values', values);
  if($event.target.checked)
  {
    this.day[i] = value;
  }
  else
  {
    this.day.splice(i, 1);
  }
  // console.log(this.day);
}

  values: number[];

  config = TreeviewConfig.create({
    hasAllCheckBox: true,
    hasFilter: true,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    maxHeight: 400
  });

  isError : boolean = false;
  isValid :boolean = false;
  isValidFile :boolean = true;

  buttonClasses = [
    'btn-outline-primary',
    'btn-outline-secondary',
    'btn-outline-success',
    'btn-outline-danger',
    'btn-outline-warning',
    'btn-outline-info',
    'btn-outline-light',
    'btn-outline-dark'
  ];
  buttonClass = this.buttonClasses[5];

  daysdefault = [
    {
      Name : "Mon",
      value : "Monday",
      Times : [
        {
          s : 'sMo1',
          e : 'eMo1',
          valid: true
        }
      ]
    },
    {
      Name : "Tue",
      value : "Tuesday",
      Times : [
        {
          s : 'sTu1',
          e : 'eTu1',
          valid: true
        }
      ]
    },
    {
      Name : "Wed",
      value : "Wednesday",
      Times : [
        {
          s : 'sWe1',
          e : 'eWe1',
          valid: true
        }
      ]
    },
    {
      Name : "Thu",
      value : "Thursday",
      Times : [
        {
          s : 'sTh1',
          e : 'eTh1',
          valid: true
        }
      ]
    },
    {
      Name : "Fri",
      value : "Friday",
      Times : [
        {
          s : 'sFr1',
          e : 'eFr1',
          valid: true
        }
      ]
    },
    {
      Name : "Sat",
      value : "Saturday",
      Times : [
        {
          s : 'sSa1',
          e : 'eSa1',
          valid: true
        }
      ]
    },
    {
      Name : "Sun",
      value : "Sunday",
      Times : [
        {
          s : 'sSu1',
          e : 'eSu1',
          valid: true
        }
      ]
    }
  ];

  daycheck = {
    Mon:false,
    Tue:false,
    Wed:false,
    Thu:false,
    Fri:false,
    Sat:false,
    Sun:false
  };


  day = [];
  dstime = {
    SMonday : [this.StartTime],
    STuesday : [this.StartTime],
    SWednesday : [this.StartTime],
    SThursday : [this.StartTime],
    SFriday : [this.StartTime],
    SSaturday : [this.StartTime],
    SSunday : [this.StartTime]
  };
  detime = {
    EMonday : [this.EndTime],
    ETuesday : [this.EndTime],
    EWednesday : [this.EndTime],
    EThursday : [this.EndTime],
    EFriday : [this.EndTime],
    ESaturday : [this.EndTime],
    ESunday : [this.EndTime]
  };
  dstimes;
  detimes;

  DeleteSlot(days,i) {
    var index = this.daysdefault.findIndex(x => x.Name === days.Name);
    this.daysdefault[index].Times.splice(i, 1);
  }

   AddSlot(days,i)
   {
    if (days.Times.length <= 4) {
      let times = {
        s : 's'+days.Name[0]+days.Name[1]+i,
        e : 'e'+days.Name[0]+days.Name[1]+i,
        valid: true
      };
      var index = this.daysdefault.findIndex(x => x.Name === days.Name);
      this.daysdefault[index].Times.push(times);
    }
   }



   GetBranchList(data:DownlineTreeviewItem[])
   {
    if(data.length == 0)
    {
      return;
    }
    const items = this.getgrouparray(data);
    let headers = new Headers({ 'Authorization' : this.authDetails.auth_token });
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });
    this.selectedGroups = items.toString();
    let groupParamsObject = {
      Group_ids: items.toString(),
      role_id : this.authDetails.role_id,
      group_id: this.authDetails.group_id,
      company_id: this.authDetails.company_id
    }
    let body = JSON.stringify(groupParamsObject);

    this.sharedService.updateIsRequestOn(true);
    this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_GET_BRANCH, body, options).subscribe(
      (response) => {

        this.Branch = this.getbranchtreeview(response.result);
        this.sharedService.updateIsRequestOn(false);
      },
      (error) => {
        console.log(JSON.stringify(error));
        this.sharedService.updateIsRequestOn(false);
      }
    );
   }

   getgrouparray(data:DownlineTreeviewItem[])
   {
    //  console.log(data);
      const array = [];
      data.forEach(downlineItem => {
            array.push(downlineItem.item.value);
            let parent = downlineItem.parent;
            while (!isNil(parent)) {
              if(parent.item.checked)
                array.push(parent.item.value);
              parent = parent.parent;
            }
      });
      var myNewList =  Array.from(new Set(array));
      return myNewList;
   }

   getbranchtreeview(data)
   {
    const items: TreeviewItem[] = [];
    for (let i = 0; i < data.length; i++) {
        const value: any = data[i].branch_id;
        const checked = false;
        // + ' ' + data[i].branch_code + ' ' + data[i].address
        const item = new TreeviewItem({ text: data[i].branch_name , value: value, checked: checked });
        items.push(item);
    }
    return items;
   }

   gettreeview(data)
   {
    const items: TreeviewItem[] = [];
    for (let i = 0; i < data.length; i++) {
        const value: any = data[i].gd_id;
        const checked = false;
        let child:any;
        if(data[i].children)
        {
          child = this.gettreeview(data[i].children)
        }
        const item = new TreeviewItem({ text: data[i].gd_name + ' ' + data[i].timezone + ' ' + data[i].screen_type_name, value: value, checked: checked,children: child });
        items.push(item);
    }
    // console.log(items);
    return items;
   }

   gettreechild(data)
   {
    const items: TreeviewItem[] = [];
    for (let i = 0; i < data.length; i++) {
        const value: any = data[i].gd_id;
        const checked = false;
        let child:any;
        if(data[i].children)
        {
          child = this.gettreechild(data[i].children)
        }
        const item = new TreeviewItem({ text: data[i].gd_name + ' ' + data[i].timezone + ' ' + data[i].screen_type_name, value: value, checked: checked,children: child });
        items.push(item);
    }
    return items;
   }


   converttree(data,parent)
   {
    var out = []
    for(var i in data) {
      if(data[i].parent == parent) {
          var children = this.converttree(data, data[i].gd_id)
          if(children.length) {
              data[i].children = children
            }
            out.push(data[i])
        }
    }
    return out

    // //   console.log(root);
   }

   defaultTime = {hour: 13, minute: 30};
   meridianTime = {hour: 13, minute: 30};
   meridianTimee = {hour: 13, minute: 30};
   meridian = true;
   meridiann = true;

   SecondsTime: NgbTimeStruct = {hour: 13, minute: 30, second: 30};
   seconds = true;

   customTime: NgbTimeStruct = {hour: 13, minute: 30, second: 0};
   hourStep = 1;
   minuteStep = 15;
   secondStep = 30;

   toggleSeconds() {
       this.seconds = !this.seconds;
   }

   toggleMeridian() {
       this.meridian = !this.meridian;
   }

   toggleMeridiann() {
       this.meridiann = !this.meridiann;
   }
   minDateError :boolean = false;
   maxDateError :boolean = false;

dateChange(date, type) {
  if(type == 'contract_start_date') {
    // console.log("date: ", date);
    let startDate = Object.assign({}, date);
    this.Media_details.cont_enddate = moment(startDate).add(30, 'minutes');
    if(this.Media_details.cont_enddate && this.Media_details.cont_enddate <= date) {
      //this.Media_details.cont_startdate = null;
      this.maxDateError = true;
    } else {
      this.maxDateError = false;
    }
    this.Media_details.cont_startdate = date;

  }
  if(type == 'contract_end_date') {
    if(this.Media_details.cont_startdate && this.Media_details.cont_startdate >= date) {
      //this.Media_details.cont_enddate = null;
      this.minDateError = true;
    } else {
      this.minDateError = false;
    }
    this.Media_details.cont_enddate = date;
  }
}

groupid_from_api = [];
ex;
   GetGroupdataAll()
   {
    let headers = new Headers({ 'Authorization' : this.authDetails.auth_token });
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });

    let groupParamsObject = {
      company_id: this.authDetails.company_id,
      // group_id: this.authDetails.group_id
    }
    let body = JSON.stringify(groupParamsObject);

    this.sharedService.updateIsRequestOn(true);
    this.util.callPostApi(CommonURL.BASE_URL + 'Groups/get/all', body, options).subscribe(
      (response) => {
        var result = response.result
        // this.groupid_from_api = response.result
        // console.log("in group func", this.groupid_from_api)
        // var ex;

        result.forEach(element => {
          // console.log("inside element", element);

          this.groupid_from_api.push(element.gd_id);

          // console.log("in", this.groupid_from_api);
        });
      },
      (error) => {
        console.log(JSON.stringify(error));
        this.sharedService.updateIsRequestOn(false);
      }
    );
  }
addmedia(){
  var group_id_string = this.groupid_from_api.toString()
  if(this.screenType == 1)
  {
    this.Media_details.v_media = "";
    this.Media_details.tv_screen_typee = "H"
  }
  else if(this.screenType == 2)
  {
    this.Media_details.h_media = "";
    this.Media_details.tv_screen_typee = "V"
  }
  // else {
  //   this.Media_details.tv_screen_typee = "B"
  // }

  let branch_id = []; let branch_name = [];
  this.selectedbranch.forEach(element => {
    branch_id.push(element.item.value)
    branch_name.push(element.item.text)
  });
  this.Media_details.branch_id = branch_id.toString();
  this.Media_details.branch_name = branch_name.toString();
  this.Media_details.company_user_id = this.authDetails.cu_id;
  this.Media_details.company_id = this.authDetails.company_id;

  this.Media_details.group_id = this.selectedGroups;
  this.Media_details.start_datetime = this.dateConvert(Object.assign(this.StartDate, this.StartTime));
  this.Media_details.end_datetime = this.dateConvert(Object.assign(this.EndDate, this.EndTime));


  this.Media_details.company_user = this.userObject.cu_name;
  this.Media_details.company_user_email = this.userObject.cu_emailid;

  let validatedData = this.validateFields();

    if(validatedData != "" && validatedData != true)
    {
        let dialogRef;
        this.sharedService.updateIsRequestOn(false);
        this.sharedService.dialogText = {
          title : CommonUIStrings.FAILURE,
          description : this.validateFields() + ' is required',
          isInfoDialog : true,
          headerBgClass : CommonUIStrings.REJECT_POPUP_BG_CLASS,
          headerIcon : CommonUIStrings.REJECT_POPUP_ICON_CLASS
        };

        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
          // console.log(result);

          if(result != undefined && result != null)
          {

          }
        });
        return false;
    }

    if(this.Media_details.adtype != "Fulltime")
    {
      var dstimes = _.cloneDeep(this.dstime);
      var detimes = _.cloneDeep(this.detime);
      this.getdaystime(dstimes,detimes);
      var filtered = this.day.filter(function (el) {
        return el != null;
      });

      this.Media_details.days = JSON.stringify(filtered);
      this.Media_details.dstime = JSON.stringify(dstimes);
      this.Media_details.detime = JSON.stringify(detimes);
    }

  let headers = new Headers({ 'Authorization' : this.userObject.token });
  let options = new RequestOptions({ headers: headers });
  let paramsObject = AddMediaObject.create(this.Media_details);

  // console.log(this.Media_details);

  this.sharedService.updateIsRequestOn(true);
  this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_ADD_PENDING_MEDIA, paramsObject.formData, options).subscribe(
    (response) => {

      this.sharedService.dialogText = {
        title : CommonUIStrings.SUCCESS,
        description : CommonUIStrings.MEDIA_ADD_SUCCESS,
        isInfoDialog : true,
        headerBgClass : CommonUIStrings.SUCCESS_POPUP_BG_CLASS,
        headerIcon : CommonUIStrings.SUCCESS_POPUP_ICON_CLASS
      };

      let dialogRef;

      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        // console.log(result);

        if(result != undefined && result != null)
        {
          this.router.navigate([CommonURL.URL_ROUTE_ALL_PUBLISHER]);
        }
      });

      // console.log(response);
      this.sharedService.updateIsRequestOn(false);

    }, (error) => {
      console.log(JSON.stringify(error));
      this.sharedService.updateIsRequestOn(false);
      this.sharedService.dialogText = {
        title : CommonUIStrings.FAILURE,
        description :error.message,
        isInfoDialog : true,
        headerBgClass : CommonUIStrings.REJECT_POPUP_BG_CLASS,
        headerIcon : CommonUIStrings.REJECT_POPUP_ICON_CLASS
      };

      let dialogRef;
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        // console.log(result);

        if(result != undefined && result != null)
        {

        }
      });
    });
}

validateFields(){
  for(let i = 0;i < this.requiredFields.length; i++)
  {
    if(this.Media_details[this.requiredFields[i]] == "" && !this.Media_details[this.requiredFields[i]].replace(/\s/g, '').length)
        return this.requiredFields[i];
  }

  return true;
}

getdaystime(dstimes,detimes)
{
  for (let key in dstimes) {
    const keys = key.slice(1);
    if(this.day.indexOf(keys) == -1)
    {
      delete dstimes[key];
    }
  }

  for (let key in dstimes) {
    for(let keys in dstimes[key])
    {
      var local = new Date();
      local.setHours(dstimes[key][keys].hour);
      local.setMinutes(dstimes[key][keys].minute);
      local.setSeconds(dstimes[key][keys].second);
      var utcdatetime = local.toISOString().substring(11, 19);
      dstimes[key][keys] = utcdatetime
    }
  }

  for (let key in detimes) {
    const keys = key.slice(1);
    if(this.day.indexOf(keys) == -1)
    {
      delete detimes[key];
    }
  }

  for (let key in detimes) {
    for(let keys in detimes[key])
    {
      var local1 = new Date();
      local1.setHours(detimes[key][keys].hour);
      local1.setMinutes(detimes[key][keys].minute);
      local1.setSeconds(detimes[key][keys].second);
      var utcdatetime1 = local1.toISOString().substring(11, 19);
      detimes[key][keys] = utcdatetime1;
    }
  }
}

dateClick(type){

}

titleForm(form){

}
htmlForm(form){}

htmlFormVertical(from){

}
HtmlBoth(form){

}
categoryForm(form){}

onFilterChange(value: string) {
  // console.log(this.values);
  // console.log('filter:', value);
}



  attachFile(){
    if((this.Media_details.h_media.indexOf("http://") == -1) && (this.Media_details.h_media.indexOf("https://") == -1))
    {
      this.isError = true;
      this.isValid = false;
      this.url = '';
      return;
    }
    // let url = this.sanitizer.bypassSecurityTrustResourceUrl(this.Media_details.h_media)
      // $('.__media').html('<iframe style="height:100%;width:100%;" class="embed-responsive-item embed-responsive-link" [src]="'+url+'"></iframe>');
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.Media_details.h_media);
      this.Media_details.h_extension = this.Media_details.h_type = this.Media_details.v_extension = this.Media_details.v_type =  "LINK";
      this.Media_details.v_media = this.Media_details.h_media;
      this.isError = false;
      this.isValid = true;
      // console.log(this.Media_details);
  }

  dateConvert(date_string) {
    if(date_string) {
      if(typeof date_string == 'string') {
        return date_string
      } else {
        var datetime = date_string.year +'-'+ date_string.month +'-'+ date_string.day +' '+date_string.hour +':'+ date_string.minute +':'+date_string.second;
        var local = new Date(datetime);
        var utcdatetime = local.toISOString();
        return utcdatetime;
      }
    } else {
      return null;
    }
  }



}
