import { ViewChild,Component, OnInit } from '@angular/core';
import {MatDialog,MatDialogConfig, MatDialogRef} from '@angular/material';
import { FormControl,FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbTimeStruct, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import {RequestOptions, Headers} from "@angular/http";
import {UtilService} from "../../util/utility.services";
import {SharedService} from "../../util/shared-service.services";
import {CommonURL} from "../../commons/commons";
import {CommonUIStrings} from "../../commons/commonUIStrings";
import {DialogResultCustomDialog} from "../../dialogs/custom-dialog/custom-dialog-component";
import { TreeviewItem, TreeviewConfig, DownlineTreeviewItem, TreeviewEventParser, OrderDownlineTreeviewEventParser, } from 'ngx-treeview';
import { isNil, remove, reverse } from 'lodash';
import {AddMediaObject} from "../../commons/classes/add_media_object";
import { DomSanitizer } from '@angular/platform-browser';
declare var $: any;
import * as moment from 'moment';
import * as _ from 'lodash';
import { NgbDateCustomParserFormatter } from 'src/app/commons/dateformat';

@Component({
  selector: 'app-publisher',
  templateUrl: './publisher.component.html',
  styleUrls: ['./publisher.component.scss'],
  providers : [
    { provide: TreeviewEventParser, useClass: OrderDownlineTreeviewEventParser },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ]
})
export class PublisherComponent implements OnInit {

  type;
  timeError:boolean = false;
  stype = 'h';
  isLinear = false;
  expiryDateOptions:any;
  loadAPI: Promise<any>;
  StartDate: any;
  EndDate: any;
  currentTime = new Date().getMinutes();
  currentHour = new Date().getHours();
  StartTime: any= {hour: this.currentHour, minute: this.currentTime, second: 0};
  EndTime: any = {hour: this.currentHour, minute: this.currentTime, second: 0};
  endtime:any = {};
  isTimeValidate: boolean = false;
  durationVertical = 30;
  durationHorizontal = 30;
  view: boolean = true;
  screenType:number = 1;
  options:boolean = true;
  mindate:any;
  maxdate:any;
  mintime:any;
  maxtime:any;
  url;
  groups:any;
  Branch;
  userObject:any = {};
  authDetails:any = {};
  imagevalidcheck;
  hmaxval;
  vmaxval;
  uploaderrmsg;
  parent = 0;
  selectedbranch:any = [];
  selectedGroups;
  result;
  groups_n: TreeviewItem[] = [];
  items : TreeviewItem[] = [];
  values: number[];

  Media_details:any = {
    branch_id : "",
    branch_name : "",
    company_id : "",
    slot : "Pending",
    screen_type : "LPUBLISHER",
    h_media : "",
    h_duration : 30,
    h_extension : "",
    h_type : "",
    v_media : "",
    v_duration : 30,
    v_extension : "",
    v_type : "",
    title : "",
    is_title_show : 0,
    start_datetime : "",
    end_datetime : "",
    adtype : "Fulltime",
    days : "",
    dstime : "",
    detime : "",
    change : "New Local Publisher",
    company_user_id : 1,
    do : 1,
    tv_screen_typee : ""
  };

  requiredFields:any = ['branch_id', 'branch_name', 'title', 'start_datetime', 'end_datetime'];


   firstFormGroup: FormGroup;
   secondFormGroup: FormGroup;
   thirdFormGroup: FormGroup;
   fourthFormGroup: FormGroup;
   today;
   constructor(private calendar: NgbCalendar,public sanitizer: DomSanitizer,private router: Router,private util: UtilService,private _formBuilder: FormBuilder, private sharedService : SharedService, public dialog: MatDialog) {
    this.today = this.calendar.getToday()
    this.StartDate = calendar.getToday();
    this.mindate = calendar.getToday();
   }


  config = TreeviewConfig.create({
    hasAllCheckBox: true,
    hasFilter: true,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    maxHeight: 400
  });

  isError : boolean = false;
  isValid :boolean = false;
  isValidFile :boolean = true;

  buttonClasses = [
    'btn-outline-primary',
    'btn-outline-secondary',
    'btn-outline-success',
    'btn-outline-danger',
    'btn-outline-warning',
    'btn-outline-info',
    'btn-outline-light',
    'btn-outline-dark'
  ];
  buttonClass = this.buttonClasses[5];
  daysdefault = [
    {
      Name : "Mon",
      value : "Monday",
      Times : [
        {
          s : 'sMo1',
          e : 'eMo1',
          valid: true
        }
      ]
    },
    {
      Name : "Tue",
      value : "Tuesday",
      Times : [
        {
          s : 'sTu1',
          e : 'eTu1',
          valid: true
        }
      ]
    },
    {
      Name : "Wed",
      value : "Wednesday",
      Times : [
        {
          s : 'sWe1',
          e : 'eWe1',
          valid: true
        }
      ]
    },
    {
      Name : "Thu",
      value : "Thursday",
      Times : [
        {
          s : 'sTh1',
          e : 'eTh1',
          valid: true
        }
      ]
    },
    {
      Name : "Fri",
      value : "Friday",
      Times : [
        {
          s : 'sFr1',
          e : 'eFr1',
          valid: true
        }
      ]
    },
    {
      Name : "Sat",
      value : "Saturday",
      Times : [
        {
          s : 'sSa1',
          e : 'eSa1',
          valid: true
        }
      ]
    },
    {
      Name : "Sun",
      value : "Sunday",
      Times : [
        {
          s : 'sSu1',
          e : 'eSu1',
          valid: true
        }
      ]
    }
  ];
  daycheck = {
    Mon:false,
    Tue:false,
    Wed:false,
    Thu:false,
    Fri:false,
    Sat:false,
    Sun:false
  };
  day = [];
  dstime = {
    SMonday : [],
    STuesday : [],
    SWednesday : [],
    SThursday : [],
    SFriday : [],
    SSaturday : [],
    SSunday : []
  };
  detime = {
    EMonday : [],
    ETuesday : [],
    EWednesday : [],
    EThursday : [],
    EFriday : [],
    ESaturday : [],
    ESunday : []
  };
  dstimes;
  detimes;

   ngOnInit() {
    this.userObject = JSON.parse(this.util.getLocalStorageKey(CommonURL.LOCAL_STORAGE_USER_KEY));

    if(this.userObject == null || this.userObject == undefined)
      this.router.navigate(['/login']);
    else
    {
      this.authDetails = {
        branch_id : this.userObject.branch_id,
        auth_token : this.userObject.token,
        company_id : this.userObject.company_id,
        cu_id : this.userObject.cu_id,
        role_id: this.userObject.role_id,
        group_id: this.userObject.assigned_group,
      };

      // this.loadAPI = new Promise((resolve) => {
      //   this.url.forEach(element => {
      //     this.util.loadScript("assets/js/"+element+".js");
      //   });
      // });
        this.firstFormGroup = this._formBuilder.group({
           firstCtrl: ['', Validators.required]
        });
        this.secondFormGroup = this._formBuilder.group({
           secondCtrl: ['', Validators.required]
        });
        if(this.authDetails.role_id == 1 || this.authDetails.role_id == 2 || this.authDetails.role_id == 3){
          this.GetAllGroupdataForSA();
        }
        else{
          this.GetGroupdata();
        }
    }
   }


   schedularTimeValidation(day,j,i){
    let startTimeHour = this.dstime['S'+day][j].hour;
    let startTimeMinute = this.dstime['S'+day][j].minute;
    let endTimeHour = this.detime['E'+day][j].hour;
    let endTimeMinute = this.detime['E'+day][j].minute;
    if(startTimeHour >= endTimeHour && startTimeMinute >= endTimeMinute){
      this.isTimeValidate = true;
      this.daysdefault[i].Times[j].valid = false;
    }
    else{
      this.isTimeValidate = false;
      this.daysdefault[i].Times[j].valid = true;
    }
  }
  getTime($event , string){
    // console.log("strings", string);
  const value = $event;
  // console.log("value", value);
  if (!value) {
    this.endtime.valid = true;
    return null;
  }
  // console.log(this.EndDate);
  if(this.EndDate == undefined || this.EndDate == null){
    this.timeError = true;
  }else{
  this.timeError = false;
  }
  let StartDateDay = this.StartDate.day;
  let EndDateDay =   this.EndDate.day;

  let startDateMonth = this.StartDate.month;
  let endDateMonth = this.EndDate.month;

  let startDateYear = this.StartDate.year;
  let endDateYear = this.EndDate.year;

  if(StartDateDay === EndDateDay && startDateMonth == endDateMonth  && startDateYear == endDateYear){

  // console.log("in");
  if(string == 'start'){
  this.endtime.valid = false;
  this.timeValidationForStart(value);
  }
  if(string == 'end'){
    this.endtime.valid = false;
    this.timeValidationForEnd(value);
  }
 }

  this.endtime.valid = true;
  return null;
}



timeValidationForEnd(endTime){
  let startTimeHour =  this.StartTime.hour;
  let startTimeMinute =  this.StartTime.minute;
  // console.log("start time hour", startTimeHour);
  // console.log("start time min", startTimeMinute);
  let endTimeHour = endTime.hour;
  let endTimeMinute = endTime.minute;
  // console.log("end time hour", endTimeHour);
  // console.log("end time min", endTimeMinute);
  if(!!(startTimeHour >= endTimeHour && startTimeMinute >= endTimeMinute)){
    // console.log("aa",(startTimeHour >= endTimeHour && startTimeMinute >= endTimeMinute));
    this.isTimeValidate = true;
    // return startTime < endTime;
  }
  else{
    this.isTimeValidate = false;
  }
}

timeValidationForStart(startTime){
  let startTimeHour =  startTime.hour;
  let startTimeMinute =  startTime.minute;
  // console.log("start time hour", startTimeHour);
  // console.log("start time min", startTimeMinute);
  let endTimeHour = this.EndTime.hour;
  let endTimeMinute = this.EndTime.minute;
  // console.log("end time hour", endTimeHour);
  // console.log("end time min", endTimeMinute);
  if(!!(startTimeHour >= endTimeHour && startTimeMinute >= endTimeMinute)){
    this.isTimeValidate = true;
    // return startTime < endTime;
  }
  else{
    this.isTimeValidate = false;
  }
}

  DeleteSlot(days,i) {
    var index = this.daysdefault.findIndex(x => x.Name === days.Name);
    this.daysdefault[index].Times.splice(i, 1);
  }

   AddSlot(days,i)
   {
    if (days.Times.length <= 4) {
      let times = {
        s : 's'+days.Name[0]+days.Name[1]+i,
        e : 'e'+days.Name[0]+days.Name[1]+i,
        valid: true,
      };
      var index = this.daysdefault.findIndex(x => x.Name === days.Name);
      this.daysdefault[index].Times.push(times);
    }
   }
   GetGroupdata()
   {
    let headers = new Headers({ 'Authorization' : this.authDetails.auth_token });
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });

    let groupParamsObject = {
      company_id: this.authDetails.company_id,
      group_id: this.authDetails.group_id,
      role_id: this.authDetails.role_id
    }
    let body = JSON.stringify(groupParamsObject);

    this.sharedService.updateIsRequestOn(true);
    this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_GET_PARENT_GROUP, body, options).subscribe((resp) => {
      // console.log("resp", resp)
        let resp_new = resp.result;

        this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_GET_GROUP, body, options).subscribe(
          (response) => {
            // console.log("response", response.result);
            for(let i = 0; i<resp_new.length; i++){
              let b = this.converttree(response.result,resp_new[i].parent);
              b.forEach((ele) => {
                this.groups_n.push(ele)
              })
              // console.log("this groups",this.groups);
            }
            const arr = Array.from(new Set(this.groups_n));
              let a = this.gettreeview(arr)
              // this.allgroups.push(a[0]);
              a.forEach((element) => {
                this.items.push(element);
              })
            this.result = response.result;
            // console.log(" view group respose !!", response);

            this.sharedService.updateIsRequestOn(false);

          },
          (error) => {
            console.log(JSON.stringify(error));
            this.sharedService.updateIsRequestOn(false);
          }
        );
      },
      (error) => {
        console.log(JSON.stringify(error));
        this.sharedService.updateIsRequestOn(false);
      }
    );
   }

   GetAllGroupdataForSA()
  {
   let headers = new Headers({ 'Authorization' : this.authDetails.auth_token });
   headers.append('Content-Type', 'application/json');
   let options = new RequestOptions({ headers: headers });

   let groupParamsObject = {
     company_id: this.authDetails.company_id
   }
   let body = JSON.stringify(groupParamsObject);

   this.sharedService.updateIsRequestOn(true);
   this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_GET_GROUP_FOR_SA, body, options).subscribe(
     (response) => {
      // console.log("RESS", response)
       this.result = response.result;
       this.groups = this.converttree(response.result,this.parent);
       this.items = this.gettreeview(this.groups);
       this.sharedService.updateIsRequestOn(false);
     },
     (error) => {
       console.log(JSON.stringify(error));
       this.sharedService.updateIsRequestOn(false);
     }
   );
  }
   GetBranchList(data:DownlineTreeviewItem[])
   {

    // console.log("dataa", data);
    if(data.length == 0)
    {
      return;
    }
    const items = this.getgrouparray(data);
    let headers = new Headers({ 'Authorization' : this.authDetails.auth_token });
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });
    this.selectedGroups = items.toString();
    // console.log("thiss is gourp", this.selectedGroups);
    let groupParamsObject = {
      Group_ids: items.toString(),
      role_id : this.authDetails.role_id,
      group_id: this.authDetails.group_id,
      company_id: this.authDetails.company_id
    }
    // console.log("inside this auth", groupParamsObject);
    let body = JSON.stringify(groupParamsObject);

    this.sharedService.updateIsRequestOn(true);
    this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_GET_BRANCH, body, options).subscribe(
      (response) => {

        this.Branch = this.getbranchtreeview(response.result);
        this.sharedService.updateIsRequestOn(false);
      },
      (error) => {
        console.log(JSON.stringify(error));
        this.sharedService.updateIsRequestOn(false);
      }
    );
   }

   getgrouparray(data:DownlineTreeviewItem[])
   {
    //  console.log(data);
      const array = [];
      data.forEach(downlineItem => {
            array.push(downlineItem.item.value);
            let parent = downlineItem.parent;
            while (!isNil(parent)) {
              if(parent.item.checked)
                array.push(parent.item.value);
              parent = parent.parent;
            }
      });
      var myNewList =  Array.from(new Set(array));
      return myNewList;
   }

   getbranchtreeview(data)
   {
    //  console.log("branch inside", data);
    const items: TreeviewItem[] = [];
    for (let i = 0; i < data.length; i++) {
        const value: any = data[i].branch_id;
        const checked = false;
        // HERE  + ' ' + data[i].branch_code + ' ' + data[i].address
        const item = new TreeviewItem({ text: data[i].branch_name, value: value, checked: checked });
        items.push(item);
    }
    return items;
   }
   gettreeview(data)
   {
    const items: TreeviewItem[] = [];
    for (let i = 0; i < data.length; i++) {
        const value: any = data[i].gd_id;
        const checked = false;
        let child:any;
        if(data[i].children)
        {
          child = this.gettreeview(data[i].children)
        }
        const item = new TreeviewItem({ text: data[i].gd_name + ' ' + data[i].timezone + ' ' + data[i].screen_type_name, value: value, checked: checked,children: child });
        items.push(item);
        // items.push(data[i].gd_id);
    }
    // console.log(items);
    return items;
   }

   gettreechild(data)
   {
    const items: TreeviewItem[] = [];
    for (let i = 0; i < data.length; i++) {
        const value: any = data[i].gd_id;
        const checked = false;
        let child:any;
        if(data[i].children)
        {
          child = this.gettreechild(data[i].children)
        }
        const item = new TreeviewItem({ text: data[i].gd_name + ' ' + data[i].timezone + ' ' + data[i].screen_type_name, value: value, checked: checked,children: child });
        items.push(item);
    }
    return items;
   }


   converttree(data,parent)
   {
    var out = []
    for(var i in data) {
      if(data[i].parent == parent) {
          var children = this.converttree(data, data[i].gd_id)
          if(children.length) {
              data[i].children = children
            }
            out.push(data[i])
        }
    }
    return out

    // //   console.log(root);
   }

   defaultTime = {hour: 13, minute: 30};
   meridianTime = {hour: 13, minute: 30};
   meridianTimee = {hour: 13, minute: 30};
   meridian = true;
   meridiann = true;

   SecondsTime: NgbTimeStruct = {hour: 13, minute: 30, second: 30};
   seconds = true;

   customTime: NgbTimeStruct = {hour: 13, minute: 30, second: 0};
   hourStep = 1;
   minuteStep = 15;
   secondStep = 30;

   toggleSeconds() {
       this.seconds = !this.seconds;
   }

   toggleMeridian() {
       this.meridian = !this.meridian;
   }

   toggleMeridiann() {
       this.meridiann = !this.meridiann;
   }
   minDateError :boolean = false;
   maxDateError :boolean = false;

dateChange(date, type) {
  if(type == 'contract_start_date') {
    // console.log("date: ", date);
    let startDate = Object.assign({}, date);
    this.Media_details.cont_enddate = moment(startDate).add(30, 'minutes');
    if(this.Media_details.cont_enddate && this.Media_details.cont_enddate <= date) {
      //this.Media_details.cont_startdate = null;
      this.maxDateError = true;
    } else {
      this.maxDateError = false;
    }
    this.Media_details.cont_startdate = date;

  }
  if(type == 'contract_end_date') {
    if(this.Media_details.cont_startdate && this.Media_details.cont_startdate >= date) {
      //this.Media_details.cont_enddate = null;
      this.minDateError = true;
    } else {
      this.minDateError = false;
    }
    this.Media_details.cont_enddate = date;
  }
}

groupid_from_api = [];
ex;
   GetGroupdataAll()
   {
    let headers = new Headers({ 'Authorization' : this.authDetails.auth_token });
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });

    let groupParamsObject = {
      company_id: this.authDetails.company_id,
      // group_id: this.authDetails.group_id
    }
    let body = JSON.stringify(groupParamsObject);

    this.sharedService.updateIsRequestOn(true);
    this.util.callPostApi(CommonURL.BASE_URL + 'Groups/get/all', body, options).subscribe(
      (response) => {
        var result = response.result
        // this.groupid_from_api = response.result
        // console.log("in group func", this.groupid_from_api)
        // var ex;

        result.forEach(element => {
          // console.log("inside element", element);

          this.groupid_from_api.push(element.gd_id);

          // console.log("in", this.groupid_from_api);
        });
      },
      (error) => {
        console.log(JSON.stringify(error));
        this.sharedService.updateIsRequestOn(false);
      }
    );
  }

addmedia()
{
  // return console.log("vats", this.selectedGroups)
  var group_id_string = this.groupid_from_api.toString()
  if(this.screenType == 1)
  {
    this.Media_details.v_media = "";
    this.Media_details.tv_screen_typee = "H"
  }
  else if(this.screenType == 2)
  {
    this.Media_details.h_media = "";
    this.Media_details.tv_screen_typee = "V"
  }

  let branch_id = []; let branch_name = [];
  this.selectedbranch.forEach(element => {
    // console.log("element", element);
    branch_id.push(element.item.value)
    branch_name.push(element.item.text);
    // console.log("ele", element.item.text);
  });
  this.Media_details.branch_id = branch_id.toString();
  this.Media_details.branch_name = branch_name.toString();
  this.Media_details.company_user_id = this.authDetails.cu_id;
  this.Media_details.company_id = this.authDetails.company_id;
  this.Media_details.group_id = this.selectedGroups;
  this.Media_details.start_datetime = this.dateConvert(Object.assign(this.StartDate, this.StartTime));
  this.Media_details.end_datetime = this.dateConvert(Object.assign(this.EndDate, this.EndTime));

  this.Media_details.company_user = this.userObject.cu_name;
  this.Media_details.company_user_email = this.userObject.cu_emailid;

  let validatedData = this.validateFields();

    if(validatedData != "" && validatedData != true)
    {
        let dialogRef;
        this.sharedService.updateIsRequestOn(false);
        this.sharedService.dialogText = {
          title : CommonUIStrings.FAILURE,
          description : this.validateFields() + ' is required',
          isInfoDialog : true,
          headerBgClass : CommonUIStrings.REJECT_POPUP_BG_CLASS,
          headerIcon : CommonUIStrings.REJECT_POPUP_ICON_CLASS
        };

        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
          // console.log(result);

          if(result != undefined && result != null)
          {

          }
        });
        return false;
    }

    if(this.Media_details.adtype != "Fulltime")
    {
      var dstimes = _.cloneDeep(this.dstime);
      var detimes = _.cloneDeep(this.detime);
      this.getdaystime(dstimes,detimes);
      var filtered = this.day.filter(function (el) {
        return el != null;
      });

      this.Media_details.days = JSON.stringify(filtered);
      this.Media_details.dstime = JSON.stringify(dstimes);
      this.Media_details.detime = JSON.stringify(detimes);
    }





  let headers = new Headers({ 'Authorization' : this.userObject.token });
  let options = new RequestOptions({ headers: headers });
  let paramsObject = AddMediaObject.create(this.Media_details);

  // console.log(this.Media_details);

  this.sharedService.updateIsRequestOn(true);
  this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_ADD_PENDING_MEDIA,
    paramsObject.formData, options).subscribe(
    (response) => {

      this.sharedService.dialogText = {
        title : CommonUIStrings.SUCCESS,
        description : CommonUIStrings.MEDIA_ADD_SUCCESS,
        isInfoDialog : true,
        headerBgClass : CommonUIStrings.SUCCESS_POPUP_BG_CLASS,
        headerIcon : CommonUIStrings.SUCCESS_POPUP_ICON_CLASS
      };

      let dialogRef;

      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        // console.log(result);

        if(result != undefined && result != null)
        {
          this.router.navigate(['/active-box-report']);
        }
      });

      // console.log(response);
      this.sharedService.updateIsRequestOn(false);

    }, (error) => {
      console.log(JSON.stringify(error));
      this.sharedService.updateIsRequestOn(false);
      this.sharedService.dialogText = {
        title : CommonUIStrings.FAILURE,
        description :error.message,
        isInfoDialog : true,
        headerBgClass : CommonUIStrings.REJECT_POPUP_BG_CLASS,
        headerIcon : CommonUIStrings.REJECT_POPUP_ICON_CLASS
      };

      let dialogRef;
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        // console.log(result);

        if(result != undefined && result != null)
        {

        }
      });
    });
}

validateFields(){
  for(let i = 0;i < this.requiredFields.length; i++)
  {
    if(this.Media_details[this.requiredFields[i]] == "" && !this.Media_details[this.requiredFields[i]].replace(/\s/g, '').length)
        return this.requiredFields[i];
  }

  return true;
}

getdaystime(dstimes,detimes)
{
  for (let key in dstimes) {
    const keys = key.slice(1);
    if(this.day.indexOf(keys) == -1)
    {
      delete dstimes[key];
    }
  }

  for (let key in dstimes) {
    for(let keys in dstimes[key])
    {
      var local = new Date();
      local.setHours(dstimes[key][keys].hour);
      local.setMinutes(dstimes[key][keys].minute);
      local.setSeconds(dstimes[key][keys].second);
      var utcdatetime = local.toISOString().substring(11, 19);
      dstimes[key][keys] = utcdatetime
    }
  }

  for (let key in detimes) {
    const keys = key.slice(1);
    if(this.day.indexOf(keys) == -1)
    {
      delete detimes[key];
    }
  }

  for (let key in detimes) {
    for(let keys in detimes[key])
    {
      var local1 = new Date();
      local1.setHours(detimes[key][keys].hour);
      local1.setMinutes(detimes[key][keys].minute);
      local1.setSeconds(detimes[key][keys].second);
      var utcdatetime1 = local1.toISOString().substring(11, 19);
      detimes[key][keys] = utcdatetime1;
    }
  }
}

dateClick(type){

}

titleForm(form){

}
htmlForm(form){}

htmlFormVertical(from){

}
HtmlBoth(form){

}
categoryForm(form){}

onFilterChange(value: string) {
  // console.log(this.values);
  // console.log('filter:', value);
}


attachFile(ev, stype){
  if (ev.target.files && ev.target.files[0] && this.validateFileType(ev.target.files[0].type)) {
    this.isValid = true;
    this.isError = false;
    this.imagevalidcheck = false;
    this.stype = stype;
    var reader = new FileReader();
    reader.readAsDataURL(ev.target.files[0]);
    var file_type = ev.target.files[0].type.split('/');
    this.type = file_type[0].toUpperCase();
    var mediasize,file,videon;
    if(stype == 'h')
    {
      this.Media_details.h_media = ev.target.files[0];
      this.Media_details.h_extension = this.Media_details.h_media.type;
      this.Media_details.h_type = file_type[0].toUpperCase();
      mediasize = this.Media_details.h_media.size;
      file = document.getElementById('hfile');
      videon = 'videoh'
    }
    else
    {
      this.Media_details.v_media = ev.target.files[0];
      this.Media_details.v_extension = this.Media_details.v_media.type;
      this.Media_details.v_type = file_type[0].toUpperCase();
      mediasize = this.Media_details.v_media.size;
      file = document.getElementById('vfile');
      videon = 'videov'
    }

    if(this.type == 'IMAGE') {
      this.sharedService.updateIsRequestOn(true);
      if(stype == 'h')
        this.hmaxval = 30;
      else
        this.vmaxval = 30;
        if(mediasize > 5242880)
        {
          $('#__'+videon).html("");
          file.value = '';
          this.isError = true;
          this.imagevalidcheck = true;
          this.uploaderrmsg = "<br> File size is too large <br/> Image should be less than 5 MB";
          this.sharedService.updateIsRequestOn(false);
        }
        else
        {
          reader.onload  = (e:any)  => {
            $('#__'+videon).html("<img style='max-width: 100%; max-height: 100%' src=" + reader.result + " />");
            this.sharedService.updateIsRequestOn(false);
          };
        }
    }
    if(this.type == 'VIDEO') {
      let file_ext;
      if(stype == 'h')
        file_ext = this.Media_details.h_extension;
      else
        file_ext = this.Media_details.v_extension;

      let instance:any = this;
      this.sharedService.updateIsRequestOn(true);

        /*** *
         * Setting Media Size Which cannot be more than 150Mb
         * 209715200 - size for 200mb file size
        */
      if(mediasize > 157286400)
      {
            $('#__'+videon).html("");
            file.value = '';
            this.isError = true;
            this.imagevalidcheck = true;
            this.uploaderrmsg = "File size is too large <br/> Video should be less than 150 MB";
            if(stype == 'h')
              this.Media_details.h_type = ''
            else
              this.Media_details.v_type = ''
            this.sharedService.updateIsRequestOn(false);
      }
      else
      {
        reader.onload = (e) => {
          if(stype == 'h')
            this.hmaxval = 6000000000;
          else
            this.vmaxval = 6000000000;
          $('#__'+videon).html("<video id='"+videon+"' controls style='max-width: 100%; max-height: 100%'><source src=" + reader.result + " type=" + file_ext +" ></video>");
          setTimeout(() => {
            let videoTag:any;
            if(stype == 'h')
              videoTag = document.getElementById("videoh");
            else
              videoTag = document.getElementById("videov");

            var video = $('#'+videon);
            // console.log('videoTag: ', videoTag);
            // console.log("document.getElementsByTagName: ", videoTag.duration);
            // if(!((video[0].videoWidth <= 1080 && video[0].videoHeight <= 1920) || (video[0].videoWidth <= 1920 && video[0].videoHeight <= 1080)))
            // {
            //       if(stype == 'h')
            //         this.Media_details.h_type = ''
            //       else
            //         this.Media_details.v_type = ''
            //       $('#__'+videon).html("");
            //       file.value = '';
            //       this.isError = true;
            //       this.imagevalidcheck = true;
            //       this.uploaderrmsg = "<b>Please check your video dimentions</b><br/>Allowed Maximum height: 1920 px or 1080 px<br/>Allowed Maximum width: 1080 px or 1920 px";
            // }
            let duration:number = parseInt(videoTag.duration.toString());
            if(stype == 'h')
              this.Media_details.h_duration = duration;
            else
              this.Media_details.v_duration = duration;

            if(duration > 6000000000)
            {
              if(stype == 'h')
                this.Media_details.h_type = ''
              else
                this.Media_details.v_type = ''
              $('#__'+videon).html("");
              file.value = '';
              this.isError = true;
              this.imagevalidcheck = true;
              this.uploaderrmsg = "Video duration should be less than 40 sec.";
            }

            this.sharedService.updateIsRequestOn(false);
          }, 10000);
        };
      }
    }
  } else {
    // $('#_file').html("");
    $('#__'+videon).html("");
    file.value = '';
    this.isError = true;
    this.isValid = false;
  }
  // console.log("SLOT OBJ : ",this.Media_details);
}


validateFileType(type) {
  for(let i=0; i< CommonURL.FILE_TYPES.length; i++ ) {
    if(type == CommonURL.FILE_TYPES[i].type){
      this.isValidFile = true;
      return true
    }
  }
  this.isValidFile = false;
  return false;
}
dateConvert(date_string) {
  if(date_string) {
    if(typeof date_string == 'string') {
      return date_string
    } else {
      var datetime = date_string.year +'-'+ date_string.month +'-'+ date_string.day +' '+date_string.hour +':'+ date_string.minute +':'+date_string.second;
      var local = new Date(datetime);
      var utcdatetime = local.toISOString();
      return utcdatetime;
    }
  } else {
    return null;
  }
}
  checkchanged($event,i,value)
  {
    if($event.target.checked)
    {
      this.day[i] = value;
    }
    else
    {
      this.day.splice(i, 1);
    }
    // console.log(this.day);
  }

}
