import { Component, OnInit } from '@angular/core';
import {MatDialog,MatDialogConfig, MatDialogRef} from '@angular/material';
import { Router } from '@angular/router';
import {RequestOptions, Headers} from "@angular/http";
import {UtilService} from "../../util/utility.services";
import {SharedService} from "../../util/shared-service.services";
import {CommonURL} from "../../commons/commons";
import {CommonUIStrings} from "../../commons/commonUIStrings";
import {CompanyGroupOnBoardObject} from "../../commons/classes/company_group_add_object";
import {DialogResultCustomDialog} from "../../dialogs/custom-dialog/custom-dialog-component";
import { TreeviewItem, TreeviewConfig} from 'ngx-treeview';

@Component({
  selector: 'app-add-group',
  templateUrl: './add-group.component.html',
  styleUrls: ['./add-group.component.scss']
})
export class AddGroupComponent implements OnInit {

  constructor(private router: Router, private util: UtilService, private sharedService : SharedService, public dialog: MatDialog) { }

  userObject:any = {};
  authDetails:any = {};

  TVScreentype = CommonURL.SCREEN_TYPE;
  Timezone;
  parent = 0;
  groups:any;

  items: TreeviewItem[];
  config = TreeviewConfig.create({
    hasFilter: true,
    hasCollapseExpand: true
  });
  company_Group_details:any = {
    gd_name : "",
    timezone_id : "",
    screen_type_id : "",
    parent : 0,
    company_id : 1,
    is_active : 1
  }

  requiredFields:any = ["gd_name","screen_type_id","timezone_id"];


  ngOnInit() {

    this.userObject = JSON.parse(this.util.getLocalStorageKey(CommonURL.LOCAL_STORAGE_USER_KEY));

    if(this.userObject == null || this.userObject == undefined)
      this.router.navigate(['/login']);
    else
    {
      this.authDetails = {
        auth_token : this.userObject.token,
        company_id : this.userObject.company_id,
        cu_id : this.userObject.cu_id,
        group_id: this.userObject.assigned_group,
        role_id: this.userObject.role_id
      };
      this.GetTimezondata();
      this.GetGroupdata();
    }

  }

  GetGroupdata()
   {
    let headers = new Headers({ 'Authorization' : this.authDetails.auth_token });
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });

    let groupParamsObject = {
      company_id: this.authDetails.company_id,
      group_id: this.authDetails.group_id,
      role_id: this.authDetails.role_id
    }
    let body = JSON.stringify(groupParamsObject);

    this.sharedService.updateIsRequestOn(true);
    this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_GET_GROUP, body, options).subscribe(
      (response) => {

        var parentgd = {
          gd_id : 0,
          gd_name : 'Parent',
          timezone : 'Any',
          screen_type_name : 'Any'
        };

        response.result.unshift(parentgd);
        // console.log(response.result);

        this.groups = response.result;
        this.items = this.gettreeview(this.groups);
        this.sharedService.updateIsRequestOn(false);
      },
      (error) => {
        console.log(JSON.stringify(error));
        this.sharedService.updateIsRequestOn(false);
      }
    );
   }

   gettreeview(data)
   {
    const items: TreeviewItem[] = [];
    for (let i = 0; i < data.length; i++) {
        const value: any = data[i].gd_id;
        const item = new TreeviewItem({ text: data[i].gd_name + ' ' + data[i].timezone + ' ' + data[i].screen_type_name, value: value });
        items.push(item);
    }
    // console.log(items);
    return items;
   }

  categoryForm(categor){

  }

  GetTimezondata()
  {
    let headers = new Headers({ 'Authorization' : this.authDetails.auth_token });
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });

    let groupParamsObject = {
      company_id: this.authDetails.company_id
    }
    let body = JSON.stringify(groupParamsObject);

    this.sharedService.updateIsRequestOn(true);
    this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_GET_TIMEZONELIST, body, options).subscribe(
      (response) => {

        // console.log(response.result);
        this.Timezone = response.result;
        this.sharedService.updateIsRequestOn(false);
      },
      (error) => {
        console.log(JSON.stringify(error));
        this.sharedService.updateIsRequestOn(false);
      }
    );
  }

  AddGroup()
{

    this.company_Group_details.company_id = this.authDetails.company_id;

    let validatedData = this.validateFields();

    if(validatedData != "" && validatedData != true)
    {
      let dialogRef;
      this.sharedService.updateIsRequestOn(false);
      this.sharedService.dialogText = {
        title : CommonUIStrings.FAILURE,
        description : this.validateFields() + ' is required',
        isInfoDialog : true,
        headerBgClass : CommonUIStrings.REJECT_POPUP_BG_CLASS,
        headerIcon : CommonUIStrings.REJECT_POPUP_ICON_CLASS
      };

      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        // console.log(result);

        if(result != undefined && result != null)
        {

        }
      });
      return false;
    }

  // if(!this.isEdit){
  //   this.company_details.cont_startdate = this.dateConvert(this.company_details.cont_startdate);
  //   this.company_details.cont_enddate = this.dateConvert(this.company_details.cont_enddate);
  // }

  let headers = new Headers({ 'Authorization' : this.userObject.token });
  let options = new RequestOptions({ headers: headers });
  let paramsObject = CompanyGroupOnBoardObject.create(this.company_Group_details);

  this.sharedService.updateIsRequestOn(true);
  this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_ADD_COMPANY_GROUP, paramsObject.formData, options).subscribe(
    (response) => {

      this.sharedService.dialogText = {
        title : CommonUIStrings.SUCCESS,
        description : CommonUIStrings.COMPANY_GROUP_ADD_SUCCESS,
        isInfoDialog : true,
        headerBgClass : CommonUIStrings.SUCCESS_POPUP_BG_CLASS,
        headerIcon : CommonUIStrings.SUCCESS_POPUP_ICON_CLASS
      };

      let dialogRef;

      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        // console.log(result);

        if(result != undefined && result != null)
        {
          this.router.navigate(['/view-group']);
        }
      });

      this.sharedService.updateIsRequestOn(false);

    }, (error) => {
      console.log(JSON.stringify(error));
      this.sharedService.updateIsRequestOn(false);
      this.sharedService.dialogText = {
        title : CommonUIStrings.FAILURE,
        description :error.message,
        isInfoDialog : true,
        headerBgClass : CommonUIStrings.REJECT_POPUP_BG_CLASS,
        headerIcon : CommonUIStrings.REJECT_POPUP_ICON_CLASS
      };

      let dialogRef;
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        // console.log(result);

        if(result != undefined && result != null)
        {

        }
      });
    });
}

  validateFields(){
    for(let i = 0;i < this.requiredFields.length; i++)
    {
      if(this.company_Group_details[this.requiredFields[i]] == "" && !this.company_Group_details[this.requiredFields[i]].replace(/\s/g, '').length)
          return this.requiredFields[i];
    }

    return true;
  }
}
