export class AddMediaObject {

    formData:any;

    static create(media_details)
    {
      return new AddMediaObject(media_details);
    }

    constructor(media_details)
    {
      // console.log(media_details,"add obj media");
      this.formData = new FormData();
      this.formData.append('branch_id', media_details.branch_id);
      this.formData.append('branch_name', media_details.branch_name);
      this.formData.append('company_id', media_details.company_id);
      this.formData.append('slot', media_details.slot);
      this.formData.append('screen_type', media_details.screen_type);
      this.formData.append('group_id', media_details.group_id)
      // if(media_details.h_media !="" && media_details.v_media !=""){
      //   this.formData.append('tv_screen_typee', media_details.tv_screen_typee);
      // }
      //  && media_details.v_media == ""
      if(media_details.h_media != "")
      {
        this.formData.append('h_media', media_details.h_media);
        this.formData.append('h_duration', media_details.h_duration);
        this.formData.append('h_extension', media_details.h_extension);
        this.formData.append('h_type', media_details.h_type);
        // this.formData.append('tv_screen_typee', 'H');
        // this.formData.append('tv_screen_typee', media_details.tv_screen_typee);
      // this.formData.append('tv_screen_typee', media_details.tv_screen_typee.tv_h);
      }
      // &&  media_details.h_media == ""
      if(media_details.v_media != "")
      {
        this.formData.append('v_media', media_details.v_media);
        this.formData.append('v_duration', media_details.v_duration);
        this.formData.append('v_extension', media_details.v_extension);
        this.formData.append('v_type', media_details.v_type);
        // this.formData.append('tv_screen_typee', 'V')
        // this.formData.append('tv_screen_typee', media_details.tv_screen_typee);
      //  this.formData.append('tv_screen_typee', media_details.tv_screen_typee.tv_v);
      }
      this.formData.append('tv_screen_typee', media_details.tv_screen_typee);
      // this.formData.append('tv_screen_typee_h', media_details.tv_screen_typee.tv_h);
      // this.formData.append('tv_screen_typee_v', media_details.tv_screen_typee.tv_v);
      this.formData.append('title', media_details.title);

      var activeda = +media_details.is_title_show;

      this.formData.append('is_title_show', activeda.toString());

      this.formData.append('start_datetime', media_details.start_datetime);

      this.formData.append('end_datetime', media_details.end_datetime);

      this.formData.append('adtype', media_details.adtype);

      if(media_details.adtype != "Fulltime")
      {
        this.formData.append('days', media_details.days);
        this.formData.append('dstime', media_details.dstime);
        this.formData.append('detime', media_details.detime);
      }

      this.formData.append('change', media_details.change);

      this.formData.append('company_user_id', media_details.company_user_id);

      this.formData.append('do', media_details.do);

      this.formData.append('company_user', media_details.company_user);

      this.formData.append('company_user_email', media_details.company_user_email)

      // new Response(this.formData).text().then(console.log)

    }
  }
