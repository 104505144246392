import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RequestOptions, Headers } from "@angular/http";
import {CommonURL} from "../../../commons/commons";
import {UtilService} from "../../../util/utility.services";
import {SelectAccountService} from "../../../util/select-account.services";
import * as CryptoJS from 'crypto-js';
import {SharedService} from "../../../util/shared-service.services";
import {Observable} from 'rxjs/Rx';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
    isActive: boolean;
    collapsed: boolean;
    showMenu: string;
    pushRightClass: string;

    @Output() collapsedEvent = new EventEmitter<boolean>();

    constructor (private translate: TranslateService,private router: Router , private util: UtilService, private selectAccountService : SelectAccountService, private sharedService: SharedService){
        this.version = CommonURL.VERSION;
        this.router.events.subscribe(val => {
            if (
                val instanceof NavigationEnd &&
                window.innerWidth <= 992 &&
                this.isToggled()
            ) {
                this.toggleSidebar();
            }
        });
    }

    userObject:any;

    sideFeatures:any;
    version:number;
    tv_status:number = 0;

    ownerRole:boolean = false;
    isUserMerchant:boolean = false;

    venueList:any = [];

    ngOnInit() {
        this.userObject = JSON.parse(this.util.getLocalStorageKey(CommonURL.LOCAL_STORAGE_USER_KEY));

        if (this.userObject == null || this.userObject == undefined)
        this.router.navigate(['/login']);

        this.ownerRole = (this.userObject.group == "OWNER");
        // console.log("this.userObject.is_owner: ", this.ownerRole);
        this.isUserMerchant = !(CommonURL.TAG_ROLE_IDS.includes(this.userObject.role_id));


        let tempListSide = [];
        // console.log(this.userObject, "u")
        // console.log("this.userObject.is_owner: ", this.userObject);
        if(this.userObject.is_owner)
        {
            tempListSide = CommonURL.TAGCX_ORGANIZATION_FEATURES;
            // console.log("tempListSide owner", tempListSide)
        }
        else
        {
            let featureList = CommonURL.FEATURES_LIST;
            for (let localFeat in featureList)
            {
                for(let feat in this.userObject.features)
                {
                if(this.userObject.features[feat].type == 'UI')
                {
                    if(featureList[localFeat].checkpoint == this.userObject.features[feat].name)
                    {
                    tempListSide.push(featureList[localFeat]);
                    }
                }
                }
            }
        }

        // console.log("templist side", tempListSide)

        this.sideFeatures = this.addChildrenToNode(tempListSide);
        // console.log("side", this.sideFeatures);

        let userList = this.util.getLocalStorageKey(CommonURL.LOCAL_STORAGE_USER_LIST);
        if(userList != null)
        {
        let a = CryptoJS.AES.decrypt(this.util.getLocalStorageKey(CommonURL.LOCAL_STORAGE_USER_LIST), CommonURL.SECRET_KEY);
        let list = JSON.parse(atob(a.toString(CryptoJS.enc.Utf8)));
        this.venueList  = list.accountList;
        }
        this.isActive = false;
        this.collapsed = false;
        this.showMenu = '';
        this.pushRightClass = 'push-right';
    }

    addChildrenToNode(ListSide){
        let templistside = []

        ListSide.forEach(function(e){
            if(e.parent == "")
            {
                templistside.push(e);
            }
            else
            {
                let index = templistside.indexOf(templistside.filter(function(item) {
                    return item.name == e.parent
                })[0]);

                if (index == -1) {
                    let parent = {
                        name: e.parent,
                        parentIcon: e.parentIcon,
                        child : []
                    };
                    templistside.push(parent);
                    let index = templistside.indexOf(templistside.filter(function(item) {
                        return item.name == e.parent
                    })[0]);
                    templistside[index].child.push(e);
                }
                else
                {
                    templistside[index].child.push(e);
                }
            }
        });
        return templistside;
    };



    eventCalled() {
        this.isActive = !this.isActive;
    }

    addExpandClass(element: any) {
        if (element === this.showMenu) {
            this.showMenu = '0';
        } else {
            this.showMenu = element;
        }
    }

    toggleCollapsed() {
        this.collapsed = !this.collapsed;
        this.collapsedEvent.emit(this.collapsed);
    }

    isToggled(): boolean {
        const dom: Element = document.querySelector('body');
        return dom.classList.contains(this.pushRightClass);
    }

    toggleSidebar() {
        const dom: any = document.querySelector('body');
        dom.classList.toggle(this.pushRightClass);
    }

    rltAndLtr() {
        const dom: any = document.querySelector('body');
        dom.classList.toggle('rtl');
    }

    changeLang(language: string) {
        this.translate.use(language);
    }

    onLoggedout() {
        localStorage.removeItem('isLoggedin');
    }

    switchAccount(){

        let a = CryptoJS.AES.decrypt(this.util.getLocalStorageKey(CommonURL.LOCAL_STORAGE_USER_LIST), CommonURL.SECRET_KEY);
        let list = JSON.parse(atob(a.toString(CryptoJS.enc.Utf8)));
        this.selectAccountService.companyList = list.accountList;
        this.router.navigate(['/select-account']);
        // console.log(list);
      }
}
