export class CompanyUserUpdateObject {

    formData:any;
  
    static create(User_det)
    {
  
      return new CompanyUserUpdateObject(User_det);
    }
  
  
  
  
    constructor(User_det)
    {
  console.log("while forming the object value is ",User_det)
      this.formData = new FormData();
  
      this.formData.append('cu_id', User_det.cu_id);

      if(User_det.contact_emailid != null || User_det.contact_emailid != undefined)
        this.formData.append('contact_emailid', User_det.contact_emailid);

      this.formData.append('contact_name', User_det.contact_name);

      this.formData.append('contact_number', User_det.contact_number);
      this.formData.append('is_mfa_enabled', User_det.is_mfa_enabled);
      this.formData.append('login_method', User_det.login_method);

      if(User_det.role_id == 4 || User_det.role_id == 5)
      {
        if(User_det.is_approval_req != null || User_det.is_approval_req != undefined)
            this.formData.append('is_approval_req', User_det.is_approval_req);

        if(User_det.assigned_group != null || User_det.assigned_group != undefined)
            this.formData.append('assigned_group', User_det.assigned_group);
      }
        
      this.formData.append('added_by', User_det.added_by);
  
    }
  }
  