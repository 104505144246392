import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { routerTransition } from '../router.animations';
import {SelectAccountService} from "../util/select-account.services";
import {UtilService} from "../util/utility.services";
import {CommonURL} from "../commons/commons";
import { LoginObject } from '../commons/classes/login-object';
import {RequestOptions, Headers} from "@angular/http";
import {SharedService} from "../util/shared-service.services";
import { Subscription } from 'rxjs';
import * as QRCode from 'qrcode'
import { CommonUIStrings } from '../commons/commonUIStrings';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { DialogResultCustomDialog } from '../dialogs/custom-dialog/custom-dialog-component';
import * as Msal from "msal";

// declare var $: any;

@Component({

    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    animations: [routerTransition()],
})
export class LoginComponent implements OnInit,OnDestroy {

     public isIframe: boolean;
    private subscription: Subscription;
    showMFA:boolean = false;
    showQrCode:boolean = false;
    private mfa_token:number ;
    public qrSource ;
    disable_token_inp =false;
    tokenMFATry:number =0;
    previousMfaToken:number;

    url = "https://graph.microsoft.com/v1.0/me";
    constructor (private router: Router, private util: UtilService,
      private activeRouter:ActivatedRoute,
       private selectAccountService : SelectAccountService,
       private sharedService : SharedService, public dialog: MatDialog,
      ){
    //  This is to avoid reload during acquireTokenSilent() because of hidden iframe
     this.isIframe = window !== window.parent && !window.opener;
    }

    userObject:any = {};


     msalConfig = {
      auth: {
          clientId: 'be4022a1-2df5-482d-88c9-9a7f024dfe27',
          redirectUri: "https://telecx.tagcx.com/login"
          // redirectUri: "http://localhost:4200/login"
      },
       navigateToLoginRequestUrl: false ,
  };

   msalInstance = new Msal.UserAgentApplication(this.msalConfig);

    ngOnInit() {
    let googleCode = this.activeRouter.snapshot.queryParams.code ;
      if(googleCode){
         this.initialiseGoogleLogin(googleCode);
         this.activeRouter.snapshot.queryParams.code  = ""
      }else{
        this.userObject = JSON.parse(this.util.getLocalStorageKey(CommonURL.LOCAL_STORAGE_USER_KEY));
        if(this.userObject != null && this.userObject != undefined)
        {
            let redirectURLs = CommonURL.redirectURL;
            for(let i = 0; i < redirectURLs.length; i++)
            {
                if(redirectURLs[i].role_id == this.userObject.role_id)
                {
                this.router.navigate([redirectURLs[i].url]);
                this.sharedService.updateIsRequestOn(false);
                break;
                }
            }
        }
      }


      this.msalInstance.handleRedirectCallback((error, response) => {
        // handle redirect response or error
        console.log("finally handling redirect callback")
        });

    }

    email:any = {};
  password:any = {};

  credentials:any = {};

  inCorrectCredentials:boolean = false;

  result:any = {};

  login(form: NgForm):boolean {
    // console.log(this.email);
    // console.log(form);
    if(!form.valid)
    {
      let credObj = form.form.getRawValue();
      if(credObj.email == '' || credObj.email == undefined)
      {
        // console.log("HERE");
        this.email.valid = false;
        this.email.pristine = false;
      }
      if(credObj.password == '' || credObj.password == undefined)
      {
        this.password.valid = false;
        this.password.pristine = false;
      }
      return false;
    }

    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });

    let body = JSON.stringify(this.credentials);

    this.sharedService.updateIsRequestOn(true);
    this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_LOGIN, body, headers).subscribe(
      (response) => {
        // console.log(response, "login res") ;
        this.result = response.result;


        let qrCode ;
        if(response.result.is_mfa_enabled && response.result.mfa_qrCode){
        QRCode.toDataURL(response.result.mfa_qrCode, function(err, data_url) {
            qrCode =  data_url;
          });
          this.qrSource = qrCode;
          this.showMFA = true;
          this.showQrCode=true;
        }else if(response.result.is_mfa_enabled){
            this.showMFA = true;
        }else{
          this.util.setLocalstorageKey(CommonURL.LOCAL_STORAGE_USER_KEY, JSON.stringify(response.result));
          this.util.setLocalstorageKey(CommonURL.LOCAL_STORAGE_SESSION_TIMESTAMP, Date.now());

        let redirectURLs = CommonURL.redirectURL;
        for(let i = 0; i < redirectURLs.length; i++)
        {
          if(redirectURLs[i].role_id == this.result.role_id)
          {
            this.router.navigate([redirectURLs[i].url]);
            this.sharedService.updateIsRequestOn(false);
            break;
          }
        }
        }
      }, (error) => {
        console.log(error)
        if(error.status_code == 401)
        {
          this.result = error;
          this.inCorrectCredentials = true;
          // console.log(this.inCorrectCredentials);
        }
        if(error.status_code == CommonURL.MULTIPLE_COMPANY_CODE)
        {
          if(error.result != null)
          {
            let companyList = [];
            for(let i = 0; i < error.result.length; i++)
            {
              let tempObject = LoginObject.create(this.credentials.email, this.credentials.password, error.result[i].company_id, error.result[i].company_name,error.result[i].company_logo, error.result[i].company_address);
              companyList.push(tempObject);
            }
            this.selectAccountService.companyList = companyList;
            this.router.navigate(['/select-account']);
            // this.router.navigate(['/dashboard']);
          }else{
            this.result = error;
            this.inCorrectCredentials = true;
          }
        }

        this.sharedService.updateIsRequestOn(false);
      }
    );
  }


  loginWithGoogle(){
    this.util.callCustomGetApi(CommonURL.BASE_URL+CommonURL.URL_GOOGLE_LOGIN).subscribe(response=>{
      window.location = response["url"] ;
    })
  }
  initialiseGoogleLogin(code){

    this.util.callPostApi(CommonURL.BASE_URL+CommonURL.URL_CALLBACK_GOOGLE_LOGIN,{code:code}).subscribe(
      (response) => {
        this.result = response.result;

        let qrCode ;
        if(response.result.is_mfa_enabled && response.result.mfa_qrCode){
        QRCode.toDataURL(response.result.mfa_qrCode, function(err, data_url) {
            qrCode =  data_url;
          });
          this.qrSource = qrCode;
          this.showMFA = true;
          this.showQrCode=true;
        }else if(response.result.is_mfa_enabled){
            this.showMFA = true;
        }else{
        this.util.setLocalstorageKey(CommonURL.LOCAL_STORAGE_USER_KEY, JSON.stringify(response.result));
        this.util.setLocalstorageKey(CommonURL.LOCAL_STORAGE_SESSION_TIMESTAMP, Date.now());
        let redirectURLs = CommonURL.redirectURL;
        for(let i = 0; i < redirectURLs.length; i++)
        {
          if(redirectURLs[i].role_id == this.result.role_id)
          {
            this.router.navigate([redirectURLs[i].url]);
            this.sharedService.updateIsRequestOn(false);
            break;
          }
        }
        }




      }, (error) => {
        console.log(error)
        if(error.status_code == 401)
        {
          this.result = error;
          this.inCorrectCredentials = true;
          // console.log(this.inCorrectCredentials);
        }
        if(error.status_code == CommonURL.MULTIPLE_COMPANY_CODE)
        {
          if(error.result != null)
          {
            let companyList = [];
            for(let i = 0; i < error.result.length; i++)
            {
              let tempObject = LoginObject.create(this.credentials.email, this.credentials.password, error.result[i].company_id, error.result[i].company_name,error.result[i].company_logo, error.result[i].company_address);
              companyList.push(tempObject);
            }
            this.selectAccountService.companyList = companyList;
            this.router.navigate(['/select-account']);
            // this.router.navigate(['/dashboard']);
          }else{
            this.result = error;
            this.inCorrectCredentials = true;
          }
        }

        this.sharedService.updateIsRequestOn(false);
      }
    )
}

  loginWithMicroSoft(){

    var loginRequest = {
      scopes: ["user.read"] // optional Array<string>
  };

   this.msalInstance.loginPopup(loginRequest)
       .then(response => {
           // handle response
           if (this.msalInstance.getAccount()) {
            var tokenRequest = loginRequest;
            this.msalInstance.acquireTokenSilent(tokenRequest)
                .then(response => {
                    // get access token from response
                    // response.accessToken
                    var bearer = "Bearer " + response.accessToken;

                    let headers = new Headers({ 'Authorization' : bearer });
                    headers.append('Content-Type', 'application/json');
                    let options = new RequestOptions({ headers: headers });



                    var graphEndpoint = "https://graph.microsoft.com/v1.0/me";
                    this.util.callGetWithHeaderApi(graphEndpoint,options).subscribe(data=>{
                      let parsedData = JSON.parse(data["_body"]) ;
                      // console.log("finally the user data ",parsedData)
                      this.getMicrosoftUserProfile(parsedData);

                    })
                })
                .catch(err => {
                    // could also check if err instance of InteractionRequiredAuthError if you can import the class.
                    if (err.name === "InteractionRequiredAuthError") {
                        return this.msalInstance.acquireTokenPopup(tokenRequest)
                            .then(response => {
                                // get access token from response
                                // response.accessToken
                            })
                            .catch(err => {
                                // handle error
                            });
                    }
                });
        } else {
            // user is not logged in, you will need to log them in to acquire a token
        }
       })
       .catch(err => {
           // handle error
       });


    const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

  }

  getMicrosoftUserProfile(data){
    let email = "";
    data["mail"]?email = data["mail"]:email=data["userPrincipalName"];
    this.util.callPostApi(CommonURL.BASE_URL+CommonURL.URL_MICROSOFT_LOGIN,{email:email}).subscribe(
      (response) => {
        this.result = response.result;

        let qrCode ;
        if(response.result.is_mfa_enabled && response.result.mfa_qrCode){
        QRCode.toDataURL(response.result.mfa_qrCode, function(err, data_url) {
            qrCode =  data_url;
          });
          this.qrSource = qrCode;
          this.showMFA = true;
          this.showQrCode=true;
        }else if(response.result.is_mfa_enabled){
            this.showMFA = true;
        }else{
        this.util.setLocalstorageKey(CommonURL.LOCAL_STORAGE_USER_KEY, JSON.stringify(response.result));
        this.util.setLocalstorageKey(CommonURL.LOCAL_STORAGE_SESSION_TIMESTAMP, Date.now());
        let redirectURLs = CommonURL.redirectURL;
        for(let i = 0; i < redirectURLs.length; i++)
        {
          if(redirectURLs[i].role_id == this.result.role_id)
          {
            this.router.navigate([redirectURLs[i].url]);
            this.sharedService.updateIsRequestOn(false);
            break;
          }
        }
        }


      }, (error) => {
        console.log(error)
        if(error.status_code == 401)
        {
          this.result = error;
          this.inCorrectCredentials = true;
          // console.log(this.inCorrectCredentials);
        }
        if(error.status_code == CommonURL.MULTIPLE_COMPANY_CODE)
        {
          if(error.result != null)
          {
            let companyList = [];
            for(let i = 0; i < error.result.length; i++)
            {
              let tempObject = LoginObject.create(this.credentials.email, this.credentials.password, error.result[i].company_id, error.result[i].company_name,error.result[i].company_logo, error.result[i].company_address);
              companyList.push(tempObject);
            }
            this.selectAccountService.companyList = companyList;
            this.router.navigate(['/select-account']);
            // this.router.navigate(['/dashboard']);
          }else{
            this.result = error;
            this.inCorrectCredentials = true;
          }
        }

        this.sharedService.updateIsRequestOn(false);
      }
    )
  }

  goToForgot(){
    this.router.navigate(['/forgot-password']);
  }

  ngOnDestroy() {

  }

  listenMfaToken(){
    if(this.mfa_token && this.mfa_token.toString().length==6 && this.previousMfaToken !== this.mfa_token){
      this.sharedService.updateIsRequestOn(true);
      this.disable_token_inp = true;
      this.previousMfaToken = this.mfa_token;
      this.util.callPostApi(CommonURL.BASE_URL+CommonURL.URL_CHECK_MFA,{email:this.result.cu_emailid,
      token:this.mfa_token, wasQrcodeShown:this.showQrCode}).subscribe(response=>{
          if(response.result.makeLogin){
            this.util.setLocalstorageKey(CommonURL.LOCAL_STORAGE_USER_KEY, JSON.stringify(response.result));
            this.util.setLocalstorageKey(CommonURL.LOCAL_STORAGE_SESSION_TIMESTAMP, Date.now());

            let redirectURLs = CommonURL.redirectURL;
              for(let i = 0; i < redirectURLs.length; i++)
              {
                if(redirectURLs[i].role_id == this.result.role_id)
                {
                  this.router.navigate([redirectURLs[i].url]);
                  this.sharedService.updateIsRequestOn(false);
                  break;
                }
              }
          }
      },error=>{
        if(this.tokenMFATry <2){
          this.sharedService.dialogText = {
            title : 'Token not valid',
            description :"Your token is not valid, please try again",
            isInfoDialog : true,
            headerBgClass : CommonUIStrings.USER_DELETED_SUCCESS,
            headerIcon : CommonUIStrings.USER_DELETED_SUCCESS
          };

          let dialogRef;
          const dialogConfig = new MatDialogConfig();

          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);
          dialogRef.afterClosed().subscribe(result => {
            this.disable_token_inp = false;
            this.tokenMFATry++;
          });
        }else{
          this.sharedService.dialogText = {
            title : 'Token not valid',
            description :"Your have reached max number of attempts, please try again later",
            isInfoDialog : true,
            headerBgClass : CommonUIStrings.USER_DELETED_SUCCESS,
            headerIcon : CommonUIStrings.USER_DELETED_SUCCESS
          };

          let dialogRef;
          const dialogConfig = new MatDialogConfig();

          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);
          dialogRef.afterClosed().subscribe(result => {
            this.disable_token_inp = false;
            location.reload();
          });
        }

      })
    }
  }

}
