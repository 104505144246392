import { Pipe, PipeTransform } from '@angular/core';
declare var $:any;


@Pipe({ name: 'branchListFilter' })
export class branchListFilter implements PipeTransform {
  transform(branchList, searchString) {
    if (branchList == null) {
      return null;
    }
    if(searchString != undefined){
      searchString = searchString.toLowerCase().trim();
    }
    return branchList.filter(venue => {
      let cond1:any;
      let cond2:any;
      let cond3:any;

      if(venue['branch_name'] != undefined)
        cond1 = new RegExp(searchString).test(venue['branch_name'].toLowerCase());

      if(venue['branch_code'] != undefined)
        cond2 = new RegExp(searchString).test(venue['branch_code'].toLowerCase());

      if(venue['address'] != undefined)
        cond3 = new RegExp(searchString).test(venue['address'].toLowerCase());

      return cond1 || cond2 || cond3;
      }
    );
  }
}
