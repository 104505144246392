import { Component, OnInit, enableProdMode } from '@angular/core';
import {SharedService} from "../util/shared-service.services";

enableProdMode()
@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

    collapedSideBar: boolean;
    isRequestOn:boolean = false;
    _subscription:any;

    constructor (private sharedService : SharedService){
        this._subscription = this.sharedService.isRequestOn$.subscribe((value) => {
          this.isRequestOn = value;
        });
      }

    ngOnInit() {}

    receiveCollapsed($event) {
        this.collapedSideBar = $event;
    }

    ngOnDestroy() {
        this._subscription.unsubscribe();
    }
}
