import { Component, OnInit, ViewEncapsulation, QueryList, ViewChildren, ElementRef } from '@angular/core';
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {MatDialog,MatDialogConfig, MatDialogRef} from '@angular/material';
import { Router } from '@angular/router';
import {RequestOptions, Headers} from "@angular/http";
import {UtilService} from "../../util/utility.services";
import {SharedService} from "../../util/shared-service.services";
import {CommonURL} from "../../commons/commons";
import {CommonUIStrings} from "../../commons/commonUIStrings";
import {DialogResultCustomDialog} from "../../dialogs/custom-dialog/custom-dialog-component";
import { DomSanitizer } from '@angular/platform-browser';

export interface Data {
  name: string;
  id: number;
}

@Component({
  selector: 'app-approve-panel',
  templateUrl: './approve-panel.component.html',
  styleUrls: ['./approve-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ApprovePanelComponent implements OnInit {

rejectReason = {
  rejectComment: '',
  ctd_id: '',
  status_bit : 2
};
filterData;
result;
filterselect:any = ["Pending","Approved","Rejected"]
filterselected = "Pending";


@ViewChildren('popContent') items: QueryList<ElementRef>;

  constructor(public sanitizer: DomSanitizer,
    private router: Router,
    private util: UtilService,
    private sharedService : SharedService,
    public dialog: MatDialog,
    private modalService: NgbModal,config: NgbModalConfig)
     {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  private modalRef: NgbModalRef;
  userObject:any = {};
  authDetails:any = {};



  open(content,ctd_id,status_bit) {
    // console.log("role", ctd_id, ctd_id)
    this.rejectReason.ctd_id = ctd_id;
    this.modalRef = this.modalService.open(content);

    // this.modalRef.close();
  }

  ngOnInit() {
    this.userObject = JSON.parse(this.util.getLocalStorageKey(CommonURL.LOCAL_STORAGE_USER_KEY));

    if(this.userObject == null || this.userObject == undefined)
      this.router.navigate(['/login']);
    else
    {
      this.authDetails = {
        branch_id : this.userObject.branch_id,
        auth_token : this.userObject.token,
        company_id : this.userObject.company_id,
        cu_id : this.userObject.cu_id,
        role_id: this.userObject.role_id,
        group_id: this.userObject.assigned_group,
      };
        this.GetPendingdata();
    }
  }

  GetPendingdata()
  {
    let headers = new Headers({ 'Authorization' : this.authDetails.auth_token });
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });

    let PendingParamsObject = {
      company_id: this.authDetails.company_id,
      role_id: this.authDetails.role_id,
      group_id: this.authDetails.group_id
    }
    PendingParamsObject[this.filterselected] = 1;
    let body = JSON.stringify(PendingParamsObject);

    this.sharedService.updateIsRequestOn(true);
    this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_GET_PENDMEDIA, body, options).subscribe(
      (response) => {
        console.log(response.result,"RES");
        this.result = response.result;
        this.result.forEach(element => {
          element.media_url = JSON.parse(element.media_url);
          const branch_id = element.branch_id.split(',');
          const branch_name = element.branch_name.split(',');
          var i = 0;
          let data: Data[] = [];
          branch_id.forEach(element => {
            var branch = {
              id : element,
              name : branch_name[i]
            };
            data.push(branch);
            i++;
          });
          element.branchdetails = data;
          element.filterData = data;

          let days = [];
          if(element.adtype == "Daywise")
          {
            if(element.do == 2)
            {
              days = element.timeslots;
            }
            else
            {
              let day = JSON.parse(element.days);
              let dstime = JSON.parse(element.dstime);
              let detime = JSON.parse(element.detime);

              day.forEach(d => {
                let j = 0 ;
                dstime['S'+d].forEach(dstime => {
                  let ddata = {
                    day : d,
                    stime : dstime,
                    etime : detime['E'+d][j]
                  }
                  days.push(ddata);
                  j++;
                });
              });
            }
          }
          element.days = days;
        });

        this.sharedService.updateIsRequestOn(false);
      },
      (error) => {
        console.log(JSON.stringify(error));
        this.sharedService.updateIsRequestOn(false);
      }
    );
  }

  search(term: string,i) {
    if(term == null) {
      this.result[i].filterData = this.result[i].branchdetails;
    } else {
      this.result[i].filterData = this.result[i].branchdetails.filter(x =>
         x.name.trim().toLowerCase().includes(term.trim().toLowerCase())
      );
    }
  }


  openWindowCustomClass(content) {
    this.modalService.open(content, { size: 'sm' });
  }

  ProcessPending(pa_id,status)
  {
  let headers = new Headers({ 'Authorization' : this.userObject.token });
  let options = new RequestOptions({ headers: headers });
  let PendingParamsObject = {
    id: pa_id,
    status : status
  }
  if(status == 1){
    PendingParamsObject['accept_by'] = this.userObject.cu_name;
    PendingParamsObject['reject_reason'] = 'Approved';
  }
  else{
    PendingParamsObject['rejected_by'] = this.userObject.cu_name;
    PendingParamsObject['reject_reason'] = this.rejectReason.rejectComment;
  }
  let body = JSON.stringify(PendingParamsObject);
  // return console.log("body",body);
  this.sharedService.updateIsRequestOn(true);
  this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_GET_PENDMEDIAPROC, body, options).subscribe(
    (response) => {

      var msg;
      if(status == 1)
        msg = CommonUIStrings.MEDIA_APPROVED_SUCCESS;
      else
        msg = CommonUIStrings.MEDIA_REJECTED_SUCCESS;

      this.sharedService.dialogText = {
        title : CommonUIStrings.SUCCESS,
        description : msg,
        isInfoDialog : true,
        headerBgClass : CommonUIStrings.SUCCESS_POPUP_BG_CLASS,
        headerIcon : CommonUIStrings.SUCCESS_POPUP_ICON_CLASS
      };

      let dialogRef;

      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

      dialogRef.afterClosed().subscribe(result => {

        if(result != undefined && result != null)
        {
          this.GetPendingdata();
        }
      });
      this.sharedService.updateIsRequestOn(false);

    }, (error) => {
      console.log(JSON.stringify(error));
      this.sharedService.updateIsRequestOn(false);
      this.sharedService.dialogText = {
        title : CommonUIStrings.FAILURE,
        description :error.message,
        isInfoDialog : true,
        headerBgClass : CommonUIStrings.REJECT_POPUP_BG_CLASS,
        headerIcon : CommonUIStrings.REJECT_POPUP_ICON_CLASS
      };

      let dialogRef;
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

      dialogRef.afterClosed().subscribe(result => {

        if(result != undefined && result != null)
        {

        }
      });
    });
  }

  applyBasicFilter()
  {

    this.GetPendingdata();
  }

  ImageToggle(elem){
    // this.image_.nativeElement.requestFullscreen();
    elem.children[0].requestFullscreen();
  }
  rejectMedia(){
    // console.log("hi", this.rejectReason);
    this.ProcessPending(this.rejectReason.ctd_id, this.rejectReason.status_bit)
    this.rejectReason.rejectComment = '';
    this.modalRef.close();
  }

  cancelrejectMedia(){
    this.rejectReason.rejectComment = '';
    this.rejectReason.ctd_id = ''
    this.modalRef.close();
  }
}
