import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { jqxTreeComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxtree';
import { TreeModel, NodeEvent, NodeMenuItemAction } from 'ng2-tree';
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {MatDialog,MatDialogConfig, MatDialogRef} from '@angular/material';
import { Router } from '@angular/router';
import {RequestOptions, Headers} from "@angular/http";
import {UtilService} from "../../util/utility.services";
import {SharedService} from "../../util/shared-service.services";
import {CommonURL} from "../../commons/commons";
import {CommonUIStrings} from "../../commons/commonUIStrings";
import {CompanyGroupOnBoardObject} from "../../commons/classes/company_group_add_object";
import {DialogResultCustomDialog} from "../../dialogs/custom-dialog/custom-dialog-component";
import { TreeviewItem, TreeviewConfig} from 'ngx-treeview';
import { Tree } from '@angular/router/src/utils/tree';
import { ReturnStatement } from '@angular/compiler';

declare var $; // declared a var just for modal

@Component({
  selector: 'app-view-group',
  templateUrl: './view-group.component.html',
  styleUrls: ['./view-group.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ViewGroupComponent implements OnInit {

  constructor(private router: Router, private util: UtilService, private sharedService : SharedService,
     public dialog: MatDialog,
     private modalService: NgbModal,
     configure: NgbModalConfig) {
    configure.backdrop = 'static';
    configure.keyboard = false;
  }

  private modalRef: NgbModalRef;
  userObject:any = {};
  authDetails:any = {};

  TVScreentype = CommonURL.SCREEN_TYPE;
  Timezone;
  parent = 0;
  result;
  groups:TreeviewItem[] = [];
  upgroups:any;
  allgroups: TreeviewItem[] = [];
  @ViewChild('content') content: ElementRef;

  items: TreeviewItem[];
  config = TreeviewConfig.create({
    hasFilter: true,
    hasCollapseExpand: true,
    hasAllCheckBox: false
  });
  company_Group_details:any = {
    gd_name : "",
    timezone_id : "",
    screen_type_id : "",
    parent : 0,
    is_active : 1
  }

  configs = {
    hasAllCheckBox: false,
    hasFilter: false,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight: "auto"
 }

  requiredFields:any = ["gd_name","screen_type_id","timezone_id"];

  ngOnInit() {
    this.userObject = JSON.parse(this.util.getLocalStorageKey(CommonURL.LOCAL_STORAGE_USER_KEY));

    if(this.userObject == null || this.userObject == undefined)
      this.router.navigate(['/login']);
    else
    {
      this.authDetails = {
        auth_token : this.userObject.token,
        company_id : this.userObject.company_id,
        cu_id : this.userObject.cu_id,
        group_id: this.userObject.assigned_group,
        role_id: this.userObject.role_id
      };
      if(this.authDetails.role_id == 1 || this.authDetails.role_id == 2 || this.authDetails.role_id == 3){
        this.GetAllGroupdataForSA();
      }
      else{
        this.GetAllGroupdata();
      }
    }
  }


  GetGroupdata()
   {
    let headers = new Headers({ 'Authorization' : this.authDetails.auth_token });
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });

    let groupParamsObject = {
      company_id: this.authDetails.company_id,
      group_id: this.authDetails.group_id,
      role_id: this.authDetails.role_id
    }
    let body = JSON.stringify(groupParamsObject);

    this.sharedService.updateIsRequestOn(true);
    this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_GET_GROUP, body, options).subscribe(
      (response) => {
        console.log("repsonse", response);
        var parentgd = {
          gd_id : 0,
          gd_name : 'Parent',
          timezone : 'Any',
          screen_type_name : 'Any'
        };

        response.result.unshift(parentgd);
        // console.log(response.result);

        this.upgroups = response.result;
        this.items = this.gettreeview(this.upgroups);
        this.sharedService.updateIsRequestOn(false);
      },
      (error) => {
        console.log(JSON.stringify(error));
        this.sharedService.updateIsRequestOn(false);
      }
    );
   }

   gettreeview(data)
   {
    //  console.log("in this data", data)
    const items: TreeviewItem[] = [];
    for (let i = 0; i < data.length; i++) {
        const value: any = data[i].gd_id; 
        const item = new TreeviewItem({ text: data[i].gd_name + ' ' + data[i].timezone + ' ' + data[i].screen_type_name, value: value });
        items.push(item);
    }
    // console.log(items);
    return items;
   }


   GetTimezondata()
  {
    let headers = new Headers({ 'Authorization' : this.authDetails.auth_token });
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });

    let groupParamsObject = {
      company_id: this.authDetails.company_id
    }
    let body = JSON.stringify(groupParamsObject);

    this.sharedService.updateIsRequestOn(true);
    this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_GET_TIMEZONELIST, body, options).subscribe(
      (response) => {

        // console.log(response.result);
        this.Timezone = response.result;
        this.sharedService.updateIsRequestOn(false);
      },
      (error) => {
        console.log(JSON.stringify(error));
        this.sharedService.updateIsRequestOn(false);
      }
    );
  }

  GetAllGroupdataForSA()
  {
   let headers = new Headers({ 'Authorization' : this.authDetails.auth_token });
   headers.append('Content-Type', 'application/json');
   let options = new RequestOptions({ headers: headers });

   let groupParamsObject = {
     company_id: this.authDetails.company_id
   }
   let body = JSON.stringify(groupParamsObject);

   this.sharedService.updateIsRequestOn(true);
   this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_GET_GROUP_FOR_SA, body, options).subscribe(
     (response) => {
      // console.log("RESS", response)
       this.result = response.result;
       this.groups = this.converttree(response.result,this.parent);
       this.allgroups = this.gettreemodel(this.groups);
       this.sharedService.updateIsRequestOn(false);
     },
     (error) => {
       console.log(JSON.stringify(error));
       this.sharedService.updateIsRequestOn(false);
     }
   );
  }

  GetAllGroupdata()
   {
    let headers = new Headers({ 'Authorization' : this.authDetails.auth_token });
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });

    let groupParamsObject = {
      company_id: this.authDetails.company_id,
      group_id: this.authDetails.group_id,
      role_id: this.authDetails.role_id,
    }
    let body = JSON.stringify(groupParamsObject);

    this.sharedService.updateIsRequestOn(true);
    this.util.callPostApi(CommonURL.BASE_URL + 'get/group/parent', body, options).subscribe((resp) => {
      // console.log("resp", resp)
        let resp_new = resp.result;

        this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_GET_GROUP, body, options).subscribe(
          (response) => {
            // console.log("response", response.result);
            for(let i = 0; i<resp_new.length; i++){
              let b = this.converttree(response.result,resp_new[i].parent);
              b.forEach((ele) => {
                this.groups.push(ele)
              })
              // console.log("this groups",this.groups);
            }
            const arr = Array.from(new Set(this.groups));
              let a = this.gettreemodel(arr)
              // this.allgroups.push(a[0]);
              a.forEach((element) => {
                this.allgroups.push(element);
              })
            this.result = response.result;
            console.log(" view group respose !!", response);

            this.sharedService.updateIsRequestOn(false);

          },
          (error) => {
            console.log(JSON.stringify(error));
            this.sharedService.updateIsRequestOn(false);
          }
        );
      },(err)=> {console.log(err)});
   }
    gettreemodel(data)
   {
    //  console.log("DATAAA", data);
    const items: TreeviewItem[] = [];
    for (let i = 0; i < data.length; i++) {
        const value: any = data[i].gd_id;
        const parent: any = data[i].parent;
        const checked = true;
        let child:any;
        if(data[i].children)
        {
          child = this.gettreemodel(data[i].children)
        } 
        const item = new TreeviewItem(
          {text: data[i].gd_name + ' ' + data[i].timezone + ' ' + data[i].screen_type_name, value: value,
          checked: checked,children: child}
          );
         items.push(item);

    }
    return items;
   }

   converttree(data,parent)
   {
    var out = []
    for(var i in data) {
      if(data[i].parent == parent) {
        // console.log("i",i);
          var children = this.converttree(data, data[i].gd_id)
          if(children.length) {
              data[i].children = children
            }
            out.push(data[i])
        }
    }
    return out
   }

   isDelete(group){
    this.sharedService.dialogText = {
      title : 'Delete Group ?',
      description :CommonUIStrings.DELETE_DATA_TITLE,
      isInfoDialog : false,
      headerBgClass : CommonUIStrings.CONFIRM_POPUP_BG_CLASS,
      headerIcon : CommonUIStrings.CONFIRM_POPUP_ICON_CLASS
    };

    let dialogRef;
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

    dialogRef.afterClosed().subscribe(result => {

      if(result != undefined && result != null)
      {
        if(result)
        {
          this.deleteGroup(group);
        }
        else
        {

        }
      }
    });
  }


  deleteGroup(group){

    let headers = new Headers({
      'authorization': this.authDetails.auth_token
       });
    let options = new RequestOptions({ headers: headers});

    let groupDetailsObject = {
      gd_id : group.value
    };

    let body = JSON.stringify(groupDetailsObject);

    this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_DELETE_GROUP, body, options).subscribe(
      (response) => {
        this.sharedService.dialogText = {
          title : CommonUIStrings.SUCCESS,
          description :CommonUIStrings.COMPANY_GROUP_DELETED_SUCCESS,
          isInfoDialog : true,
          headerBgClass : CommonUIStrings.CONFIRM_POPUP_BG_CLASS,
          headerIcon : CommonUIStrings.CONFIRM_POPUP_ICON_CLASS
        };

        let dialogRef;
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

        dialogRef.afterClosed().subscribe(result => {

          if(result != undefined && result != null)
          {
            this.GetAllGroupdata()
          }
        });
      }, (error) => {
        console.log(error);

        let dialogRef;
        this.sharedService.dialogText = {
        title : CommonUIStrings.FAILURE,
        description : error.message,
        isInfoDialog : true,
        headerBgClass : CommonUIStrings.REJECT_POPUP_BG_CLASS,
        headerIcon : CommonUIStrings.REJECT_POPUP_ICON_CLASS
      };

      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        // console.log(result);

        if(result != undefined && result != null)
        {

        }
      });
      }
    );
  }

EditItem(item)
{
  let data = this.result.find(x => x.gd_id === item.value)
  // console.log("data",data)
  var {gd_id,gd_name,screen_type_id,timezone_id,parent,is_active} = data;
  this.company_Group_details = {gd_id,gd_name,screen_type_id,timezone_id,parent,is_active};
  this.company_Group_details.lastchange_by = this.authDetails.cu_id;
  this.GetTimezondata();
  this.GetGroupdata();
  // console.log(this.company_Group_details);
  this.modalRef = this.modalService.open(this.content, { size: 'sm' });
}

isUpdate(){
  this.modalRef.close();
  this.sharedService.dialogText = {
    title : 'Update child?',
    description :CommonUIStrings.UPDATE_CHILD_TITLE,
    isInfoDialog : false,
    headerBgClass : CommonUIStrings.CONFIRM_POPUP_BG_CLASS,
    headerIcon : CommonUIStrings.CONFIRM_POPUP_ICON_CLASS
  };

  let dialogRef;
  const dialogConfig = new MatDialogConfig();

  dialogConfig.disableClose = true;
  dialogConfig.autoFocus = true;
  dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

  dialogRef.afterClosed().subscribe(result => {

    if(result != undefined && result != null)
    {
      if(result)
      {
        this.company_Group_details.is_update_child = 1;
      }
      else
      {
        this.company_Group_details.is_update_child = 0;
      }
      this.updatemedia();
    }
  });
}


updatemedia()
  {
    let validatedData = this.validateFields();

    if(validatedData != "" && validatedData != true)
    {
        let dialogRef;
        this.sharedService.updateIsRequestOn(false);
        this.sharedService.dialogText = {
          title : CommonUIStrings.FAILURE,
          description : this.validateFields() + ' is required',
          isInfoDialog : true,
          headerBgClass : CommonUIStrings.REJECT_POPUP_BG_CLASS,
          headerIcon : CommonUIStrings.REJECT_POPUP_ICON_CLASS
        };

        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
          // console.log(result);

          if(result != undefined && result != null)
          {

          }
        });
        return false;
    }


  let headers = new Headers({ 'Authorization' : this.userObject.token });
  let options = new RequestOptions({ headers: headers });
  // let paramsObject = UpdateMediaObject.create(this.Media_details);
  let body = JSON.stringify(this.company_Group_details);

  // console.log('code',this.company_Group_details);
  // console.log('body',body);
  // return;
  this.sharedService.updateIsRequestOn(true);
  this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_UPDATE_GROUP, body, options).subscribe(
    (response) => {

      this.sharedService.dialogText = {
        title : CommonUIStrings.SUCCESS,
        description : CommonUIStrings.COMPANY_GROUP_UPDATE_SUCCESS,
        isInfoDialog : true,
        headerBgClass : CommonUIStrings.SUCCESS_POPUP_BG_CLASS,
        headerIcon : CommonUIStrings.SUCCESS_POPUP_ICON_CLASS
      };

      let dialogRef;

      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        // console.log(result);

        if(result != undefined && result != null)
        {
          this.GetAllGroupdata();
        }
      });

      // console.log(response);
      this.sharedService.updateIsRequestOn(false);

    }, (error) => {
      console.log(JSON.stringify(error));
      this.sharedService.updateIsRequestOn(false);
      this.sharedService.dialogText = {
        title : CommonUIStrings.FAILURE,
        description :error.message,
        isInfoDialog : true,
        headerBgClass : CommonUIStrings.REJECT_POPUP_BG_CLASS,
        headerIcon : CommonUIStrings.REJECT_POPUP_ICON_CLASS
      };

      let dialogRef;
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        if(result != undefined && result != null)
        {
          this.GetAllGroupdata();
        }
      });
    });
  }

  validateFields(){
    for(let i = 0;i < this.requiredFields.length; i++)
    {
      if(this.company_Group_details[this.requiredFields[i]] == "" && !this.company_Group_details[this.requiredFields[i]].replace(/\s/g, '').length)
          return this.requiredFields[i];
    }

    return true;
  }
}
