export class CompanyBranchUpdateObject {

    formData:any;
  
    static create(Branch_det)
    {
  
      return new CompanyBranchUpdateObject(Branch_det);
    }
  
  
  
  
    constructor(Branch_det)
    {
  
      this.formData = new FormData();
  
      this.formData.append('branch_id', Branch_det.branch_id);

      this.formData.append('branch_name', Branch_det.branch_name);

      this.formData.append('branch_code', Branch_det.branch_code);

      this.formData.append('address', Branch_det.address);

      this.formData.append('pincode', Branch_det.pincode);

      this.formData.append('city', Branch_det.city);

      this.formData.append('state', Branch_det.state);

      this.formData.append('country', Branch_det.country);

      this.formData.append('latitude', Branch_det.latitude);

      this.formData.append('longitude', Branch_det.longitude);

      this.formData.append('timezone_id', Branch_det.timezone_id);

      this.formData.append('screen_type_id', Branch_det.screen_type_id);

      this.formData.append('group_id', Branch_det.group_id);

      this.formData.append('lastchange_by', Branch_det.lastchange_by);
  
    }
  }
  