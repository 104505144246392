
export class CommonURL {

  static LOCAL_STORAGE_USER_KEY: string = 'tagcx_user';
  static LOCAL_STORAGE_USER_LIST: string = 'tagcx_user_list';
  static LOCAL_STORAGE_SESSION_TIMESTAMP: string = 'tagcx_session_timestamp';
  static SLOT_NUMBER: string = 'slotnumber';

  // static BASE_URL: string = 'https://preprod.tagloy.com/v2/';
  // static BASE_URL: string = 'https://cx.tagloy.com/v2/';
  static BASE_URL: string = 'https://telecx.tagcx.com/v2/';
  // static BASE_URL: string = 'http://localhost:4001/v2/';



  static VERSION:number = 0.1;

  static URL_LOGIN: string = 'login';
  static URL_GOOGLE_LOGIN: string = 'login/google';
  static URL_MICROSOFT_LOGIN: string = 'login/microsoft';
  static URL_CALLBACK_GOOGLE_LOGIN: string ='return/auth/google';
  static URL_CHECK_MFA: string = 'check/mfa';
  static URL_REKEY_MFA: string = 'rekey/mfa';
  static URL_VERIFY_EMAIL: string = 'verifyemail';
  static URL_FORGOT_PASSWORD: string = 'forgotpassword';
  static URL_UPDATE_PASSWORD: string = 'updatepassword';
  static URL_GET_COMPANYDETAILS = 'company/get';
  static URL_GET_COMPANYBRANCHDETAILS = 'Branch/get';
  static URL_GET_GROUP = 'Groups/get';
  static URL_GET_GROUP_FOR_SA = 'Groups/get/all'
  static URL_GET_PARENT_GROUP = 'get/group/parent'
  static URL_GET_RIBBON = 'ribbon/Get';
  static URL_GET_REPORT = 'Active/Report';
  static URL_ADD_RIBBON = 'ribbon/add';
  static URL_GET_TIMEZONELIST = 'Timezone/Get';
  static URL_GET_PENDMEDIA = 'pending/Get';
  static URL_GET_LIVEMEDIA = 'creative/Get';
  static URL_GET_PENDMEDIAPROC = 'pending/Process';
  static URL_GET_PEND_MEDIA_PROC_BY_MAIL = 'pending/mail/Process';
  static URL_GET_BRANCH = 'Branch/getbygroupids';
  static URL_GET_BRANCHBYCOM = 'Branch/getbycomid';
  static URL_ADD_PENDING_MEDIA = 'pending/create';
  static URL_GET_BRANCH_LIST = 'Branch/list';
  static URL_GET_COMPANY_LIST = 'company/list';
  static URL_UPDATE_MEDIA: string = 'tagmanage/update';
  static URL_UPDATE_COMPANY: string = 'company/update';
  static URL_UPDATE_BRANCH: string = 'branch/update';
  static URL_DELETE_BRANCH: string = 'branch/delete';
  static URL_DELETE_TVUSER: string = 'tvuser/delete';
  static URL_DELETE_COMPANY_USER: string = 'company/remove/user';
  static URL_UPDATE_TVUSER: string = 'tvuser/update';
  static URL_DELETE_GROUP: string = 'Group/delete';
  static URL_UPDATE_GROUP: string = 'Group/update';
  static URL_DEVICE_MONTHLY_REPORT: string = 'tv/monthly/info'

  static URL_NEW_FEED: string = 'feed/pending';
  static URL_NEW_FEED_COUNT: string = 'feed/pending/count';
  static URL_FEED_TRAIL: string = 'feed/pending/trail';
  static URL_HISTORICAL_FEED: string = 'feed/historical';
  static URL_PUBLISHED_FEED: string = 'feed/published';
  static URL_FAME_FEED: string = 'feed/fame';
  static URL_CELEBRATION_FEED: string = 'feed/celebrations';
  static URL_BOOKMARKED_FEED: string = 'feed/bookmarked';
  static URL_FEED_COMMENT: string = 'feed/comment';
  static URL_MYSQL_DATA : string = 'getDetails/status';
  static URL_MONGO_DATA: string = 'cx/getLogs/slot';


  static URL_REJECT_FEED: string = 'feed/reject';
  static URL_APPROVE_FEED: string = 'feed/approve';
  static URL_MARK_CELEBRATION_FEED: string = 'feed/markcelebration';
  static URL_MARK_BOOKMARK_FEED: string = 'feed/markbookmarked';

  static URL_BAN_USER_VENUE: string = 'venue/ban/user';
  static URL_CUSTOMER_VENUE: string = 'venue/customer';
  //static URL_BAN_USER_VENUE:string = 'venue/ban/user';


  static URL_NEW_SPOTLIGHT: string = 'spotlight/pending';
  static URL_HISTORICAL_SPOTLIGHT: string = 'spotlight/historical';
  static URL_PUBLISHED_SPOTLIGHT: string = 'spotlight/published';

  static URL_GET_TV_ENTRIES:string = 'tagtv';
  static URL_CREATE_TV_ENTRIES:string = 'tagtv/create';
  static URL_DELETE_TV_ENTRIES:string = 'tagtv/delete';

  static URL_GET_TAGMIN_ENTRIES:string = 'tagmin';
  static URL_CREATE_TAGMIN_ENTRIES:string = 'tagmin/create';
  static URL_DELETE_TAGMIN_ENTRIES:string = 'tagmin/delete';

  //vedants changes
  static URL_BLACKBOARD_UPDATE_DETAIL: string = 'venue/blackboard/update';
  static URL_BLACKBOARD_GET_DETAIL: string = 'venue/blackboard';
  static URL_SPOTLIGHT_DETAILS: string = 'spotlight/create';
  static URL_PERKS_UPDATE: string = 'perk/update';
  static URL_GET_PERKS_DETAILS: string = 'perks';
  static URL_GET_VENUE_CUSTOM_PERK: string = 'perk/custom';
  static URL_SEND_CUSTOM_PERK: string = 'venue/user/perk/send';
  static URL_GET_QUEUED_SPOTLIGHTS:string = 'spotlight/creator/queued';
  static URL_GET_REJECTED_SPOTLIGHTS:string = 'spotlight/creator/rejected';
  static URL_GET_SCHEDULED_SPOTLIGHTS:string = 'spotlight/creator/scheduled';
  static URL_GET_REQUESTED_SPOTLIGHTS:string = 'spotlight/requested';
  static URL_INITIATE_REQUEST:string = 'spotlight/request';
  static URL_REQUEST_FEED_TRAIL:string = 'feed/pending/trail';

  static URL_GET_INVENUE_CUSTOMERS_ALL = 'venue/customer/invenue';
  static URL_GET_INVENUE_CUSTOMERS_HISTORY = 'venue/customer/invenue';
  static URL_MARK_CUSTOMERS_FAVOURITE = 'venue/favourite/user';
  static URL_MARK_CUSTOMERS_BLOCK = 'venue/ban/user';
  static URL_GET_CUSTOMER_PERKS = 'customer/perks';


  static URL_GET_VENUE_LIST = 'venue/list';
  static URL_GET_VENUE_SETTINGS = 'venue/get';
  static URL_GET_VENUE_TV_STATUS = 'venue/tv-status';
  static URL_GET_COMPANY_USERS = 'company/roles';
  static URL_GET_TV_USERS = 'branch/users';
  static URL_GET_COMPANY_ASSIGN_ROLES = 'company/assign/user';
  static URL_UPDATE_COMPANY_USER = 'company/update/user';
  static URL_UPDATE_VENUE_SETTINGS = 'venue/update';
  static URL_ADD_COMPANY = 'company/add';
  static URL_ADD_COMPANY_BRANCH = 'branch/add';
  static URL_ADD_COMPANY_GROUP = 'Group/add';
  static URL_ADD_COMPANY_TVUSER = 'tvuser/add';
  static URL_VENUE_USER_ACCESS_REVOKE = 'venue/remove/user';
  static ADD_SOCIAL_MEDIA_MAPPING = 'venue/social_media/add';
  static REMOVE_SOCIAL_MEDIA_MAPPING = 'venue/social_media/remove';
  static GET_TWITTER_REQUEST_TOKEN = 'venue/social_media/twitter/request-token';
  static GET_TWITTER_ACCESS_TOKEN = 'venue/social_media/twitter/access-token';
  static UPDATE_SOCIAL_MEDIA_MAPPING = 'venue/social_media/update';

  static ADD_VENUE_NOTE_USER = 'venue/note/user';

  static GET_GROUP_COMPANY = 'venue/group/list';
  static GET_VENUE_PERK_REPORT = 'perk/report';
  static GET_VENUE_PERK_REPORT_EXCEL = 'perk/report/excel';


  static FB_BASE_URL = 'https://graph.facebook.com/';

  static URL_GET_FACEBOOK_BEARER_TOKEN = 'oauth/access_token';

  static INSTAGRAM_CLIENT_ID = '936a3f1ed6c44d0c973c5d1a319e7fad';
  //static INSTAGRAM_REDIRECT_URL = 'http://localhost/instaredirect/';
  //static INSTAGRAM_REDIRECT_URL = 'http://api.tagloy.com/instaredirect-dev/';
  //static INSTAGRAM_REDIRECT_URL = 'http://api.tagloy.com/instaredirect-staging/';
  //static INSTAGRAM_REDIRECT_URL = 'http://staging.tagloy.com/instaredirect-staging/';
  //static INSTAGRAM_REDIRECT_URL = 'https://preprod.tagloy.com/instaredirect-staging/';
  static INSTAGRAM_REDIRECT_URL = 'https://biz.tagloy.com/instaredirect-staging/';
  static INSTAGRAM_RESPONSE_CODE = 'code';
  static INSTAGRAM_AUTHORIZE_URL = 'https://api.instagram.com/oauth/authorize';
  static INSTAGRAM_PERMISSION_SCOPE = 'comments+public_content+basic';

  static TWITTER_CLIENT_KEY = 'dy0RpJKVnX2xD9nqJGvPhJzml';
  static TWITTER_CLIENT_SECRET = 'j6wrpeTmC8uTxI1aNYzGd3F3oMcQTYstSjxY4ZS2fZZe0j1OcT';
  static TWITTER_AUTHORIZE_URL = 'https://api.twitter.com/oauth/authenticate';
  static URL_ROUTE_ALL_PUBLISHER = '/active-box-report';

  static MULTIPLE_COMPANY_CODE: number = 400;

  static SCREEN_TYPE = [
    { screen_type_id: '1', screen_type_name: 'Horizontal' },
    { screen_type_id: '2', screen_type_name: 'Vertical' }
  ];

  static ROLE_USER: number = 1;
  static ROLE_ADMIN: number = 2;
  static ROLE_MODERATOR: number = 3;
  static ROLE_EDITOR: number = 4;


  static REJECTED_FEED = 1;
  static NEW_FEED = 2;
  static SCHEDULED_FEED = 3;

  static WALL_FAME = 4;
  static WALL_BOOKMARKED = 5;
  static WALL_CELEBRATIONS = 6;

  static STATUS_REJECTED_FEED = 'REJECTED';
  static STATUS_NEW_FEED = 'PENDING';
  static STATUS_SCHEDULED_FEED = 'APPROVED';

  static FEED_SOURCE_INSTAGRAM = 1;
  static FEED_SOURCE_TWITTER = 2;
  static FEED_SOURCE_FACEBOOK = 3;

  static REJECTED_SPOTLIGHT = 1;
  static NEW_SPOTLIGHT = 2;
  static SCHEDULED_SPOTLIGHT = 3;

  static TAGTV_IMAGES = 1;
  static TAGTV_VIDEOS = 2;

  static TAGMIN_IMAGES = 1;
  static TAGMIN_VIDEOS = 2;
  static TAGMIN_BACKGROUNDS = 3;

  static SPOTLIGHT_TAG_FLASH_OFFER = 'FLASH OFFER';
  static SPOTLIGHT_TAG_CREATIVE = 'CREATIVE';
  static SPOTLIGHT_TAG_EVENT = 'EVENT';
  static SPOTLIGHT_TAG_SPECIAL = 'SPECIAL';

  static EDITOR_ROLE_NAME = ['EDITOR_PRO', 'EDITOR_BASIC', 'TAG_EDITOR'];

  static URL_REJECT_SPOTLIGHT: string = 'spotlight/reject';
  static URL_APPROVE_SPOTLIGHT: string = 'spotlight/approve';


  static CARD_IMAGE_PLACEHOLDER: string = 'assets/icons/card-image-placeholder.png';
  static USER_IMAGE_PLACEHOLDER: string = 'assets/icons/user-profile-placeholder-36x36.png';

  static INVENUE_ALL = 1;
  static INVENUE_HISTORY = 2;


  static SECRET_KEY = "tagcx-sgja929ndjn9njknk-488ddakj";

  static CARD_OBJECT: Object = {
    isFeeds: false,
    isSpotlight: false,
    isRestaurantName: true,
    isMultiplePost: false,
    isEventTag: false,
    isBackgroundImage: true,
    isUserProfilePicture: false,
    isUserName: true,
    isUserFeeds: true,
    isUserIcons: true,
    userIcons: {
      isWallOfFame: false,
      isFavorite: false,
      isBookmarked: false
    },
    isUserSocialStatus: true,
    socialStatus: {
      instagram: {
        isPresent: true,
        isSource: false
      },
      twitter: {
        isPresent: true,
        isSource: false
      },
      facebook: {
        isPresent: true,
        isSource: false
      }
    },
    isUserDescription: true,
    isTimeUpdate: true,
    isScheduled: false,
    isNew: false,
    isRejected: false,
    isWall: false,
    isSpotlightName: false,
    isSpotlightDuration: false,
    isSpotlightDescription: false,
    spotlightSocial: {
      facebook: {
        isActive: false
      },
      twitter: {
        isActive: false
      },
      tagTV: {
        isActive: false
      }
    },
    isSpotlightTimeUpdate: false,
    isSpotlightTimeDuration: false,
    isSpotlightScheduled: false,
    isSpotlightNew: false,

    isWallBookmark: false,
    isWallCelebration: false,
    isWallFame: false
  };


  static CARD_INVENUE_OBJECT = {
    isUserFeeds: true,
    isUserIcons: true,
    isUserName: true,
    isUserProfilePicture: false,
    isUserSocialStatus: true,
    socialStatus: {
      facebook : {
        isPresent: true,
        isSource: false
      },
      instagram: {
        isPresent: true,
        isSource: false
      },
      twitter: {
        isPresent: true,
        isSource: true
      }
    },
    userIcons : {
      isBookmarked: false,
      isFavorite: false,
      isWallOfFame: false
    }
  };


  static CARD_OBJECT_USER: Object = {
    isUser: false,
    isUserProfilePicture: true,
    isUserName: true,
    isUserFeeds: true,
    isUserIcons: true,
    userIcons: {
      isWallOfFame: false,
      isFavorite: false,
      isBookmarked: false
    },
    isUserSocialStatus: true,
    socialStatus: {
      instagram: {
        isPresent: true,
        isSource: false
      },
      twitter: {
        isPresent: true,
        isSource: true
      },
      facebook: {
        isPresent: true,
        isSource: false
      }
    }
  };

  static SPOTLIGHT_EDITOR_USER_OBJECT = {
    "id": 956,
    "first_name": "Vedant",
    "last_name": null,
    "email": "vedant.kakade@iauro.com",
    "alternate_email": null,
    "is_email_verified": 1,
    "is_first_time": 1,
    "parent_id": null,
    "is_active": 1,
    "added_by": null,
    "twitter_id": null,
    "facebook_id": null,
    "instagram_id": null,
    "created_at": "2017-04-25T13:05:10.000Z",
    "updated_at": "2017-04-28T11:51:10.000Z",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6OTU2LCJvcmdhbml6YXRpb25fZ3JvdXAiOiJDVVNUT01FUiIsInJvbGVfaWQiOjMsImlhdCI6MTQ5Mzk2NDU1OSwiZXhwIjoxNDk0MDUwOTU5fQ.sgKRqH03gmNDXJuAl9MtqmTm2Nvv6Np3ZG3iV0qrwJo",
    "organization_id": 39,
    "role_id": 3,
    "organization_name": "IPL",
    "group": "CUSTOMER"
  }


  static TAG_TV_ENTRY_ADDED = "TAG TV entry added successfully!";
  static TAG_TV_ENTRY_DELETED = "TAG TV entry deleted successfully!";


  static FEATURES_LIST = [
    {
      id : 1,
      feature_id : 47,
      url : '/view-company',
      name : 'Settings',
      checkpoint : 'viewcompany',
      sideIconURL : '../../../../assets/icons/settings.png',
      parent : 'Company',
      parentIcon: '../../assets/icons/company.png'
    },
    {
      id : 3,
      feature_id : 48,
      url : '/add-group',
      name : 'Add',
      checkpoint : 'addgroup',
      sideIconURL : '../../../../assets/icons/Add Group-8.png',
      parent : 'Group',
      parentIcon: '../../assets/icons/group.png'
    },
    {
      id : 2,
      feature_id : 45,
      url : '/add-company-branch',
      name : 'Add Branch',
      checkpoint : 'addcompanybranch',
      sideIconURL : '../../../../assets/icons/branch.png',
      parent : 'Branch',
      parentIcon: '../../assets/icons/branch.png'
    },
    {
      id : 4,
      feature_id : 49,
      url : '/view-group',
      name : 'View',
      checkpoint : 'viewgroup',
      sideIconURL : '../../../../assets/icons/View Group-8 (1).png',
      parent : 'Group',
      parentIcon: '../../assets/icons/group.png'
    },
    {
      id : 5,
      feature_id : 50,
      url : '/html-link',
      name : 'Add Html',
      checkpoint : 'htmllink',
      sideIconURL : '../../../../assets/icons/HTML Link 1.png',
      parent : 'Publisher',
      parentIcon: '../../assets/icons/publisher.png'

    },
    {
      id : 12,
      feature_id : 58,
      url : '/branch-list',
      name : 'Branch List',
      checkpoint : 'branchlist',
      sideIconURL : '../../../../assets/icons/branch_list.png',
      parent : 'Group',
      parentIcon: '../../assets/icons/branch.png'
    },

    {
      id : 6,
      feature_id : 51,
      url : '/MainScreen',
      name : 'Add Mainscreen',
      checkpoint : 'Mainscreen',
      sideIconURL : '../../../../assets/icons/Screen.png',
      parent : 'Publisher',
      parentIcon: '../../assets/icons/publisher.png'
    },
    {
      id : 7,
      feature_id : 52,
      url : '/global-publisher',
      name : 'Add Global',
      checkpoint : 'globalpublisher',
      sideIconURL : '../../../../assets/icons/Global Publisher 1.png',
      parent : 'Publisher',
      parentIcon: '../../assets/icons/publisher.png'
    },
    {
      id : 8,
      feature_id : 53,
      url : '/regional-publisher',
      name : 'Add Regional',
      checkpoint : 'regionalpublisher',
      sideIconURL : '../../../../assets/icons/Regional Publisher 1.png',
      parent : 'Publisher',
      parentIcon: '../../assets/icons/publisher.png'
    },
    {
      id : 9,
      feature_id : 54,
      url : '/publisher',
      name : 'Add Local Publisher',
      checkpoint : 'Publisher',
      sideIconURL : '../../../../assets/icons/Local Publisher 1.png',
      parent : 'Publisher',
      parentIcon: '../../assets/icons/publisher.png'
    },
    {
      id : 10,
      feature_id : 55,
      url : '/events',
      name : 'Add Local Events',
      checkpoint : 'Events',
      sideIconURL : '../../../../assets/icons/Local Event 1.png',
      parent : 'Publisher',
      parentIcon: '../../assets/icons/publisher.png'
    },
    {
      id : 11,
      feature_id : 56,
      url : '/add-ribbon',
      name : 'Add Ribbon',
      checkpoint : 'addribbon',
      sideIconURL : '../../../../assets/icons/Add Ribbon.png',
      parent : ''
    },
    {
      id : 13,
      feature_id : 59,
      url : '/approve-panel',
      name : 'Approval Panel',
      checkpoint : 'approvePanel',
      sideIconURL : '../../../../assets/icons/Admin Approve.png',
      parent : ''
    },
    {
      id : 14,
      feature_id : 60,
      url : '/view-media',
      name : 'View Media',
      checkpoint : 'viewmedia',
      sideIconURL : '../../../../assets/icons/Media Black.png',
      parent : ''
    },
    {
      id : 15,
      feature_id : 61,
      url : '/active-box-report',
      name : 'Active Box Report',
      checkpoint : 'activeboxreport',
      sideIconURL : '../../../../assets/icons/Active Box Report Black.png',
      parent : ''
    },
  ];

  static TAGCX_ORGANIZATION_FEATURES = [
    {
      id : 1,
      feature_id : 44,
      url : '/add-company',
      name : 'Add Company',
      checkpoint : 'addcompany',
      sideIconURL : '../../../../assets/icons/ADD Company-8.png',
      parent : ''
    },
    {
      id : 2,
      feature_id : 47,
      url : '/view-company',
      name : 'Settings',
      checkpoint : 'viewcompany',
      sideIconURL : '../../../../assets/icons/settings.png',
      parent : ''
    },
    {
      id : 3,
      feature_id : 57,
      url : '/company-list',
      name : 'Company List',
      checkpoint : 'companylist',
      sideIconURL : '../../../../assets/icons/Company list.png',
      parent : ''
    }
  ];

  static redirectURL:any = [
    {
      role_id : 1,
      url : '/active-box-report'
    },
    {
      role_id : 2,
      url : '/active-box-report'
    },
    {
      role_id : 3,
      url : '/active-box-report'
    },
    {
      role_id : 4,
      url : '/active-box-report'
    },
    {
      role_id : 5,
      url : '/active-box-report'
    }
  ];



  static INSTAGRAM_ID:number = 1;
  static TWITTER_ID:number = 2;
  static FACEBOOK_ID:number = 3;

  static TAG_ROLE_IDS = [19];
  static TAGCX_OWNER_ID = 1;

  static FORM_CHARACTER_LIMITS = {
    request_spotlight_message : 100,
    user_venue_note : 100,
    user_perk_message : 50,
    perk_header : 20,
    perk_message : 40,
    black_board_heading : 34,
    black_board_content : 60,
    black_board_footer : 40,
    settings_tnc : 100,
    settings_welcome_message : 100,
    settings_feedback : 100,
    settings_birthday_message : 100,
    settings_social_media_message : 100
  };

  static PERK_REPORT_FILTER = [
    {
      id : 1,
      icon : '',
      value : {
        is_claimed : "1"
      },
      name : 'Claimed Perks'
    },
    {
      id : 2,
      icon : '',
      value : {
        is_claimed : "0"
      },
      name : 'Not Claimed Perk'
    }
  ];


  static CATEGORY_TYPE = [
    { value: '1', viewValue: 'FinTech' },
    { value: '2', viewValue: 'FoodTech' },
    { value: '3', viewValue: 'EduTech' },
    { value: '4', viewValue: 'InfoTech' }
  ];

  //static MANAGER_HOSTNAME = 'localhost';
  static MANAGER_HOSTNAME = 'manage.tagloy.com';
  static MANAGER_REDIRECT = '/moderator/tagmin';

  static SOCIAL_MESSAGE = [
    {
      id : 1,
      text : 'Thank you for posting. Hope you enjoy your time with us.'
    },
    {
      id : 2,
      text : 'Its great to have you with us. Cheers!'
    },
    {
      id : 3,
      text : 'Cheers! Have fun!'
    }
  ];

  static SOCIAL_MESSAGE_HEADERS = [
    {
      id : 1,
      text : 'Message 1'
    },
    {
      id : 2,
      text : 'Message 2'
    },
    {
      id : 3,
      text : 'Message 3'
    }
  ]

  static USER_STATUS_OBJECT = [
    {
      value: 0,
      displayValue: "Normal"
    },

    {
      value: 1,
      displayValue: "Frequenter"
    },
    {
      value: 2,
      displayValue: "VIP"
    },
    {
      value: 3,
      displayValue: "TagStar"
    },
    {
      value: 4,
      displayValue: "Restricted"
    }
  ];


  static CITY_LIST = [
    {
      id : -1,
      name : "None",
      state : "None",
      country : "None"
    },
    {
      id : 1,
      name : "Pune",
      state : "Maharashtra",
      country : "India"
    },
    {
      id : 2,
      name : "Mumbai",
      state : "Maharashtra",
      country : "India"
    },
    {
      id : 3,
      name : "Bangalore",
      state : "Karnataka",
      country : "India"
    }
  ];

  static STATE_LIST = [
    {
      id : 1,
      name : "Maharashtra",
      country : "India"
    },
    {
      id : 1,
      name : "Karnataka",
      country : "India"
    }
  ];


  static COUNTRY_LIST = [
    {
      id : 1,
      name : "India"
    },
  ];


  static LIMIT_USER_SELECTION_COUNT = 30;

  static PERK_OFFER_TYPES = [
    { value: 1, viewValue: 'Amount' },
    { value: 2, viewValue: 'Percent' },
    { value: 3, viewValue: 'Complimentary' }
  ];

  static SESSION_TIMEOUT:number = 24 * 60 * 60 * 1000;
  //static SESSION_TIMEOUT:number = 30 * 1000;

  static MONTH_ARRAY:any = [
    {
      value: 0,
      displayValue: 'NONE'
    },
    {
      value: 1,
      displayValue: 'JAN'
    },
    {
      value: 2,
      displayValue: 'FEB'
    },
    {
      value: 3,
      displayValue: 'MAR'
    },
    {
      value: 4,
      displayValue: 'APR'
    },
    {
      value: 5,
      displayValue: 'MAY'
    },
    {
      value: 6,
      displayValue: 'JUN'
    },
    {
      value: 7,
      displayValue: 'JUL'
    },
    {
      value: 8,
      displayValue: 'AUG'
    },
    {
      value: 9,
      displayValue: 'SEP'
    },
    {
      value: 10,
      displayValue: 'OCT'
    },
    {
      value: 11,
      displayValue: 'NOV'
    },
    {
      value: 12,
      displayValue: 'DEC'
    }
  ];


  static YEAR_ARRAY:any = [
    {
      value: 0,
      displayValue: "NONE"
    },
    {
      value: 2015,
      displayValue: 2015
    },
    {
      value: 2016,
      displayValue: 2016
    },
    {
      value: 2017,
      displayValue: 2017
    },
    {
      value: 2018,
      displayValue: 2018
    },
    {
      value: 2019,
      displayValue: 2019
    },
    {
      value: 2020,
      displayValue: 2020
    },
    {
      value: 2021,
      displayValue: 2021
    },
    {
      value: 2022,
      displayValue: 2022
    },
    {
      value: 2023,
      displayValue: 2023
    },
    {
      value: 2024,
      displayValue: 2024
    },
    {
      value: 2025,
      displayValue: 2025
    }
  ];

  static TAG_FEED_MODERATOR_ROLE_ID = 33;

  static FILE_TYPES = [
    {
      id : 1,
      type : 'video/mp4'
    },
    {
      id : 2,
      type : 'video/webm'
    },
    {
      id : 3,
      type : 'video/avi'
    },
    {
      id : 4,
      type : 'image/jpg'
    },
    {
      id : 5,
      type : 'image/jpeg'
    },
    {
      id : 6,
      type : 'image/png'
    },
  ]
}
