import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import {RequestOptions, Headers} from "@angular/http";
import { ActivatedRoute, Router } from '@angular/router';
import { SelectAccountService } from '../util/select-account.services';
import { SharedService } from '../util/shared-service.services';
import { MatDialog } from '@angular/material';
import { UtilService } from '../util/utility.services';
import {CommonURL} from "../commons/commons";

@Component({
  selector: 'app-mailfeed',
  templateUrl: './mailfeed.component.html',
  styleUrls: ['./mailfeed.component.scss']
})
export class MailfeedComponent implements OnInit {

  constructor(    private activeRouter:ActivatedRoute, private util: UtilService,
    private sharedService : SharedService, public dialog: MatDialog) { }

  ngOnInit() {
    // this.feedObject["cu_id"] =this.activeRouter.snapshot.queryParams.uid ;
    this.feedObject["id"] =this.activeRouter.snapshot.queryParams.pa_id ;
    this.feedObject["status"] =this.activeRouter.snapshot.queryParams.do ;
    let uname =this.activeRouter.snapshot.queryParams.uname
    this.feedObject["status"]==1?this.feedObject["accept_by"] =uname: this.feedObject["rejected_by"] =uname;
    this.feedObject["status"]==2? this.isReject = true: this.sendApproval(this.feedObject);
    console.log("obj ",this.feedObject);
  }

  reject_reason:string ;
  isReject:boolean = false;
  feedObject:AproveType={
    'id' : 0,
    'status' : 0, //1 or 2 
    'reject_reason': 'Approved'
  }

  message:string = "";
  submitReason(){
    this.reject_reason?this.feedObject["reject_reason"] = this.reject_reason:this.feedObject["reject_reason"] = "no reason was mentioned";
    this.sendApproval(this.feedObject);
  }

  sendApproval(data) {
    console.log("data feed data ",data )
    let headers = new Headers({ 'Content-Type': 'application/json' });
    // let options = new RequestOptions({ headers: headers });

    let body = JSON.stringify(data);

    this.sharedService.updateIsRequestOn(true);
    this.util.callCustomPostApi(CommonURL.BASE_URL + CommonURL.URL_GET_PEND_MEDIA_PROC_BY_MAIL, body, headers).subscribe(
      (response) => {
        console.log("this is the response",response)
            this.sharedService.updateIsRequestOn(false);
            this.message=JSON.parse( response["_body"])["message"] ;
      }, (error) => {
        console.log("this is the error",JSON.stringify(error));
        this.sharedService.updateIsRequestOn(false);
        this.message= error.message ;
      }
    );
  }
  
}


interface AproveType{
  'id' : number,
  'status' : number, //1 or 2 
  'accept_by'?: string,
  'rejected_by'?: string,
  'reject_reason': string
}

