import { Component, OnInit } from '@angular/core';
import { UtilService } from 'src/app/util/utility.services';
import {MatDialog,MatDialogConfig, MatDialogRef} from '@angular/material';
import { CommonURL } from 'src/app/commons/commons';
import {SharedService} from "../../util/shared-service.services";
import {CommonUIStrings} from "../../commons/commonUIStrings";
import { Router } from '@angular/router';
import { Headers, RequestOptions } from '@angular/http';
import {branchListFilter} from "../../commons/pipes/branchListFilter";
import {DialogResultCustomDialog} from "../../dialogs/custom-dialog/custom-dialog-component";

@Component({
  selector: 'app-branch-list',
  templateUrl: './branch-list.component.html',
  styleUrls: ['./branch-list.component.scss']
})
export class BranchListComponent implements OnInit {

  constructor( private util: UtilService, private sharedService : SharedService,private router: Router, public dialog: MatDialog) { }
  userObject:any = {};
  authDetails:any = {};
  branches = [];
  searchText:any;
  ngOnInit() {
    this.userObject = JSON.parse(this.util.getLocalStorageKey(CommonURL.LOCAL_STORAGE_USER_KEY));
    // console.log("d", this.userObject);
    if(this.userObject == null || this.userObject == undefined)
      this.router.navigate(['/login']);
    else
    {
      this.authDetails = {
        // branch_id : this.userObject.branch_id,
        auth_token : this.userObject.token,
        company_id : this.userObject.company_id,
        cu_id : this.userObject.cu_id,
        group_id : this.userObject.assigned_group,
        role_id: this.userObject.role_id
      };
      // console.log("auth", this.authDetails);
        this.GetBrachDetailByCompany();
    }
  }

  GetBrachDetailByCompany(){
    let headers = new Headers({
      'authorization': this.authDetails.auth_token
       });
    let options = new RequestOptions({ headers: headers});

    let BranchDetailsObject = {
      company_id : this.authDetails.company_id,
      group_id: this.authDetails.group_id,
      role_id: this.authDetails.role_id
    };
    let body = JSON.stringify(BranchDetailsObject);

    this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_GET_BRANCH_LIST, body, options).subscribe(
      (response) => {
        this.branches = response.result;
        //remove in future.
        // this.branches[0].is_active=0;
        // console.log("branches",this.branches);
      }, (error) => {
        console.log(error)
      }
    );
  }

  isActive(data:any,branch,i){
    this.sharedService.dialogText = {
      title : 'Update Branch Details',
      description :CommonUIStrings.UPDATE_DATA_TITLE,
      isInfoDialog : false,
      headerBgClass : CommonUIStrings.CONFIRM_POPUP_BG_CLASS,
      headerIcon : CommonUIStrings.CONFIRM_POPUP_ICON_CLASS
    };

    let dialogRef;
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

    dialogRef.afterClosed().subscribe(result => {

      if(result != undefined && result != null)
      {
        if(result)
        {
          this.UpdateBranch(data,branch);
        }
        else
        {
          this.branches[i].is_active = !data;
        }
      }
    });
  }

  UpdateBranch(data,branch)
  {
    // console.log(data, branch);
    let headers = new Headers({
      'authorization': this.authDetails.auth_token
       });
    let options = new RequestOptions({ headers: headers});

    let BranchDetailsObject = {
      branch_id : branch.branch_id,
      lastchange_by : this.authDetails.cu_id,
      is_active : +data
    };
    let body = JSON.stringify(BranchDetailsObject);

    this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_UPDATE_BRANCH, body, options).subscribe(
      (response) => {
        this.sharedService.dialogText = {
          title : CommonUIStrings.SUCCESS,
          description :CommonUIStrings.DATA_UPDATED_SUCCESS,
          isInfoDialog : true,
          headerBgClass : CommonUIStrings.CONFIRM_POPUP_BG_CLASS,
          headerIcon : CommonUIStrings.CONFIRM_POPUP_ICON_CLASS
        };

        let dialogRef;
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

        dialogRef.afterClosed().subscribe(result => {

          if(result != undefined && result != null)
          {
            this.GetBrachDetailByCompany()
          }
        });
      }, (error) => {
        console.log(error);

        let dialogRef;
        this.sharedService.dialogText = {
        title : CommonUIStrings.FAILURE,
        description : error.message,
        isInfoDialog : true,
        headerBgClass : CommonUIStrings.REJECT_POPUP_BG_CLASS,
        headerIcon : CommonUIStrings.REJECT_POPUP_ICON_CLASS
      };

      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        // console.log(result);

        if(result != undefined && result != null)
        {
          this.GetBrachDetailByCompany();
        }
      });
      }
    );
  }


  isDelete(branch,i){
    this.sharedService.dialogText = {
      title : 'Delete Branch ?',
      description :CommonUIStrings.DELETE_DATA_TITLE,
      isInfoDialog : false,
      headerBgClass : CommonUIStrings.CONFIRM_POPUP_BG_CLASS,
      headerIcon : CommonUIStrings.CONFIRM_POPUP_ICON_CLASS
    };

    let dialogRef;
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

    dialogRef.afterClosed().subscribe(result => {

      if(result != undefined && result != null)
      {
        if(result)
        {
          this.deleteBranch(branch);
        }
        else
        {

        }
      }
    });
  }


  deleteBranch(branch){
    let headers = new Headers({
      'authorization': this.authDetails.auth_token
       });
    let options = new RequestOptions({ headers: headers});

    let BranchDetailsObject = {
      branch_id : branch.branch_id
    };

    let body = JSON.stringify(BranchDetailsObject);

    this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_DELETE_BRANCH, body, options).subscribe(
      (response) => {
        this.sharedService.dialogText = {
          title : CommonUIStrings.SUCCESS,
          description :CommonUIStrings.DATA_UPDATED_SUCCESS,
          isInfoDialog : true,
          headerBgClass : CommonUIStrings.CONFIRM_POPUP_BG_CLASS,
          headerIcon : CommonUIStrings.CONFIRM_POPUP_ICON_CLASS
        };

        let dialogRef;
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

        dialogRef.afterClosed().subscribe(result => {

          if(result != undefined && result != null)
          {
            this.branches = response.result;
          }
        });
      }, (error) => {
        console.log(error);

        let dialogRef;
        this.sharedService.dialogText = {
        title : CommonUIStrings.FAILURE,
        description : error.message,
        isInfoDialog : true,
        headerBgClass : CommonUIStrings.REJECT_POPUP_BG_CLASS,
        headerIcon : CommonUIStrings.REJECT_POPUP_ICON_CLASS
      };

      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        // console.log(result);

        if(result != undefined && result != null)
        {

        }
      });
      }
    );
  }

  ViewBranch(element){
    this.sharedService.branchObject = element;
    this.router.navigate(["view-branch"]);
  }

}
