import { Component, OnInit, ChangeDetectionStrategy, ViewChild, TemplateRef, Input, ChangeDetectorRef } from '@angular/core';
import { CalendarView, CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent } from 'angular-calendar';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours
} from 'date-fns';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from 'src/app/util/utility.services';
import { CommonURL } from 'src/app/commons/commons';
import { RequestOptions, Headers } from '@angular/http';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA'
  },
  green: {
    primary: '#32cd32',
    secondary: '##32cd32'
  }
};
@Component({
  selector: 'app-device-monthly-report',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './device-monthly-report.component.html',
  styleUrls: ['./device-monthly-report.component.scss']
})
export class DeviceMonthlyReportComponent implements OnInit {
  tvu_id;
  userObject:any = {};
  authDetails:any = {};
  constructor(private modal: NgbModal, private route: ActivatedRoute, private util: UtilService, private router: Router,private cd: ChangeDetectorRef) {
    this.tvu_id = this.route.snapshot.paramMap.get('tvu_id');
   }

  ngOnInit() {
    this.userObject = JSON.parse(this.util.getLocalStorageKey(CommonURL.LOCAL_STORAGE_USER_KEY));

    if(this.userObject == null || this.userObject == undefined){
      this.router.navigate(['/login']);
    }
    else
    {
      this.authDetails = {
        auth_token : this.userObject.token,
        company_id : this.userObject.company_id
      };

      this.GetHourlyData();
      this.GetDeviceMonthlyReport();
    }
  }

  @ViewChild('modalContent') modalContent: TemplateRef<any>;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-fw fa-pencil"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      }
    },
    {
      label: '<i class="fa fa-fw fa-times"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter(iEvent => iEvent !== event);
        this.handleEvent('Deleted', event);
      }
    }
  ];

  refresh: Subject<any> = new Subject();
  events: CalendarEvent[];
  events_hour: CalendarEvent[];

  // events: CalendarEvent[] = [  {
  //     start: startOfDay(new Date()),
  //     end: addDays(new Date(), 1),
  //     title: 'Live Data',
  //     color: colors.green,
  //     actions: this.actions,
  //     allDay: true,
  //     resizable: {
  //       beforeStart: true,
  //       afterEnd: true
  //     },
  //     draggable: true
  //   },
  //   {
  //     start: addDays(new Date(),1),
  //     title: 'Offline Data',
  //     color: colors.blue,
  //     actions: this.actions
  //   },
  //   {
  //     start: subDays(new Date(), 1),
  //     // end: addDays(endOfMonth(new Date()), 3),
  //     title: 'Offline Data',
  //     color: colors.green,
  //     allDay: true
  //   },
  //   {
  //     start: subDays(new Date(), 2),
  //     // end: addHours(new Date(), 2),
  //     title: 'Live data',
  //     color: colors.yellow,
  //     actions: this.actions,
  //     resizable: {
  //       beforeStart: true,
  //       afterEnd: true
  //     },
  //     draggable: true
  //   },
  //   {
  //     start: new Date('2020-01-16'),
  //     // end: addHours(new Date(), 2),
  //     title: 'Live data',
  //     color: colors.yellow,
  //     actions: this.actions,
  //     resizable: {
  //       beforeStart: true,
  //       afterEnd: true
  //     },
  //     draggable: true
  //   },

  // ];

  activeDayIsOpen: boolean = true;

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    // console.log("in dayClicked", date);
    // console.log("in dayClicked", new Date());
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map(iEvent => {
      // console.log("ievent", iEvent);
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  eventTimesChangedHour({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent): void {
    // console.log("inside here..", event)
    this.events = this.events.map(iEvent => {
      // console.log("ievent", iEvent);
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    // console.log("heyy");
    this.modalData = { event, action };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: colors.red,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true
        }
      }
    ];
    // console.log("in add event", this.events);
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter(event => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  GetDeviceMonthlyReport()
  {
    // console.log("auth token", this.authDetails.auth_token);
    let headers = new Headers({ 'Authorization' : this.authDetails.auth_token });
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });

    let deviceMonthlyBodyObj = {
      tv_id : this.tvu_id
    }
    let body = JSON.stringify(deviceMonthlyBodyObj);

    this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_DEVICE_MONTHLY_REPORT, body, options).subscribe(
      (response) => {
        // console.log("response", response);
        let api_response = response.result;

        /* SART */
        this.events =  api_response
          .map((res) =>

          ( {start: new Date(res.date.replace("T00:00:00.000Z","")), title: 'Live Data', color: colors.green}))
      /* END */

        // api_response.forEach((res) => {
        //   let date_replaced =res.date.replace("T00:00:00.000Z","");
        //   let object =
        //     {
        //     start :  new Date(date_replaced),
        //     title : 'Defualt',
        //     color : colors.green,
        //     actions: this.actions,
        //   resizable: {
        //     beforeStart: true,
        //     afterEnd: true
        //   },
        //   draggable: true
        //     }

        //     this.events.push(object);

        //   console.log(this.events,"here")
        // });
        this.refresh.subscribe(data=>{
          this.setView(this.view);
          this.cd.detectChanges();
        })
        this.refresh.next();
        // this.cd.detectChanges();
      },
      (error) => {
        console.log(JSON.stringify(error));
      }
    );
  }

  GetHourlyData()
  {
    // console.log("auth token $$$$", this.authDetails.auth_token);
    let headers = new Headers({ 'Authorization' : this.authDetails.auth_token });
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });

    let deviceMonthlyBodyObj = {
      tv_id : this.tvu_id,
      branch_id: 2
    }
    let body = JSON.stringify(deviceMonthlyBodyObj);

    this.util.callPostApi("http://localhost:4001/v2/get-hourly-by-tvid", body, options).subscribe(
      (response) => {
       console.log("response", response);
        let api_response = response.result[0].hours;

        /* SART */
        this.events_hour =  api_response
          .map((res) =>
            ({start: new Date(res.start_hour), end: new Date(res.end_hour), title: 'Seen online in this current hour.', color: colors.green}))
      /* END */

        // api_response.forEach((res) => {
        //   let date_replaced =res.date.replace("T00:00:00.000Z","");
        //   let object =
        //     {
        //     start :  new Date(date_replaced),
        //     title : 'Defualt',
        //     color : colors.green,
        //     actions: this.actions,
        //   resizable: {
        //     beforeStart: true,
        //     afterEnd: true
        //   },
        //   draggable: true
        //     }

        //     this.events.push(object);

        //   console.log(this.events,"here")
        // });
        this.refresh.subscribe(data=>{
          this.setView(this.view);
          this.cd.detectChanges();
        })
        this.refresh.next();
        // this.cd.detectChanges();
      },
      (error) => {
        console.log("in error")
        console.log(JSON.stringify(error));
      }
    );
  }
}


