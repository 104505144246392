import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbCarouselModule, NgbAlertModule, NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TreeModule } from 'ng2-tree';

import { LayoutRoutingModule } from './layout-routing.module';
import { LayoutComponent } from './layout.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { HeaderComponent } from './components/header/header.component';
import { HtmlLinkComponent } from './html-link/html-link.component';
import { AddCompanyComponent } from './add-company/add-company.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { StatModule } from '../shared';
import {MatStepperModule, MatInputModule, MatButtonModule, MatIconModule, MatCardModule, MatToolbarModule, MatOptionModule, MatSelectModule, MatTableModule, MatSortModule, MatPaginatorModule, MatProgressSpinnerModule, MatRadioModule} from '@angular/material';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {
    TimelineComponent,
    NotificationComponent,
    ChatComponent
} from './dashboard/components';
import { GlobalPublisherComponent } from './global-publisher/global-publisher.component';
import { RegionalPublisherComponent } from './regional-publisher/regional-publisher.component';
import { PublisherComponent } from './publisher/publisher.component';
import { EventsComponent } from './events/events.component';
import { CompanyListComponent } from './company-list/company-list.component';
import { ApprovePanelComponent } from './approve-panel/approve-panel.component';
import { AddGroupComponent } from './add-group/add-group.component';
import { UpdateGroupComponent } from './update-group/update-group.component';
import { jqxTreeComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxtree';
import { ViewGroupComponent } from './view-group/view-group.component';
import { jqxCheckBoxComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxcheckbox';
import { jqxDropDownButtonComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxdropdownbutton';
import { AddCompanyBranchComponent } from './add-company-branch/add-company-branch.component';
import { ViewBranchComponent } from './view-branch/view-branch.component';
import { TreeviewModule } from 'ngx-treeview';
import { MainscreenComponent } from './mainscreen/mainscreen.component';
import { BranchListComponent } from './branch-list/branch-list.component';
import { jqxButtonComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxbuttons';
import { ViewCompanyComponent } from './view-company/view-company.component';
import { ViewMediaComponent } from './view-media/view-media.component';
import { AddRibbonComponent } from './add-ribbon/add-ribbon.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { ActiveBoxReportComponent } from './active-box-report/active-box-report.component';
import { DropdownTreeviewSelectComponent } from './add-company-branch/Dropdownselect/dropdown-treeview-select.component';
import {branchListFilter} from "../commons/pipes/branchListFilter";
import {CompanyListFilter} from "../commons/pipes/companyListFilter";
import {CompanyFilter} from "../commons/pipes/companyfilter";
import { NgxSpinnerModule } from 'ngx-spinner';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { DeviceMonthlyReportComponent } from './device-monthly-report/device-monthly-report.component';
import { FlatpickrModule } from 'angularx-flatpickr';
import { LogStatusComponent, NgbdSortableHeader } from './log-status/log-status.component';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        LayoutRoutingModule,
        TranslateModule,
        NgbDropdownModule,
        NgbCarouselModule,
        NgbAlertModule,
        StatModule,
        MatStepperModule, MatInputModule, MatButtonModule, MatOptionModule, MatSelectModule,MatRadioModule, MatTableModule, MatSortModule,MatPaginatorModule,MatSelectModule,
        NgbModule,
        MatIconModule,
        MatCardModule,
        MatToolbarModule,
        MatTableModule,
        TreeviewModule.forRoot(),
        ColorPickerModule,
        TreeModule,
        NgxSpinnerModule,
        MatProgressSpinnerModule,
        CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
        FlatpickrModule.forRoot(),
    ],
    declarations: [
        LayoutComponent,
        SidebarComponent,
        HeaderComponent,
        DashboardComponent,
        AddCompanyComponent,
        HtmlLinkComponent,
        TimelineComponent,
        NotificationComponent,
        ChatComponent,
        GlobalPublisherComponent,
        RegionalPublisherComponent,
        PublisherComponent,
        EventsComponent,
        CompanyListComponent,
        ApprovePanelComponent,
        AddGroupComponent,
        UpdateGroupComponent,
        jqxTreeComponent,
        jqxCheckBoxComponent,
        jqxDropDownButtonComponent,
        jqxButtonComponent,
        ViewGroupComponent,
        AddCompanyBranchComponent,
        ViewBranchComponent,
        MainscreenComponent,
        BranchListComponent,
        ViewCompanyComponent,
        ViewMediaComponent,
        AddRibbonComponent,
        ActiveBoxReportComponent,
        DropdownTreeviewSelectComponent,
        branchListFilter,
        CompanyListFilter,
        CompanyFilter,
        DeviceMonthlyReportComponent,
        LogStatusComponent,
        NgbdSortableHeader,

    ],
    exports : [CompanyListFilter, LogStatusComponent],
    bootstrap: [LogStatusComponent]
})
export class LayoutModule {}
