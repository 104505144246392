import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import {UtilService} from "../util/utility.services";
import {SelectAccountService} from "../util/select-account.services";
import {CommonURL} from "../commons/commons";
import { VerifyAccountObject } from '../commons/classes/verify-account-object';
import {RequestOptions, Headers} from "@angular/http";
import {SharedService} from "../util/shared-service.services";
import {CommonUIStrings} from "../commons/commonUIStrings";
import {DialogResultCustomDialog} from "../dialogs/custom-dialog/custom-dialog-component";
declare var $: any;

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit {

  constructor (private router: Router, private util: UtilService, private selectAccountService : SelectAccountService, private sharedService : SharedService, private activatedRoute: ActivatedRoute, public dialog: MatDialog){}

  userObject:any = {};
  credentials:any = {
    password : "",
    confirm_password : ""
  };

  verifyData:any = {};
  isPasswordValid:boolean = false;
  formData:any;

  inCorrectCredentials:any;
  ngOnInit():void {
    
    this.activatedRoute.params.subscribe((params: Params) => {
      this.verifyData = {
        token : params['token'],
        encrypted_user_id : params['identity']
      }
    });

    this.userObject = JSON.parse(this.util.getLocalStorageKey(CommonURL.LOCAL_STORAGE_USER_KEY));
  }

  result:any = {};

  setPassword(form: NgForm):void {

    // console.log(form);
    // console.log(this.credentials);
    // console.log("isPasswordValid: ", this.isPasswordValid);

    this.verifyData.password = this.credentials.password;

    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    let body = VerifyAccountObject.create(this.verifyData.encrypted_user_id, this.verifyData.token, this.verifyData.password);

    // console.log(body);

    this.formData = new FormData();

    this.formData.append('encrypted_user_id', this.verifyData.encrypted_user_id);
    this.formData.append('token', this.verifyData.token);
    this.formData.append('password', this.verifyData.password);

    let dialogRef;

    this.sharedService.updateIsRequestOn(true);
    this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_UPDATE_PASSWORD, this.formData, headers).subscribe(
      (response) => {
        // console.log(response);
        this.sharedService.dialogText = {
          title : CommonUIStrings.SUCCESS,
          description : response.message,
          isInfoDialog : true,
          headerBgClass : CommonUIStrings.SUCCESS_POPUP_BG_CLASS,
          headerIcon : CommonUIStrings.SUCCESS_POPUP_ICON_CLASS
        };

        dialogRef = this.dialog.open(DialogResultCustomDialog);

        dialogRef.afterClosed().subscribe(result => {
          // console.log(result);

          if(result != undefined && result != null)
          {
            this.router.navigate(['/login']);
          }
        });
      }, (error) => {
        this.sharedService.updateIsRequestOn(false);
        this.sharedService.dialogText = {
          title : CommonUIStrings.FAILURE,
          description : error.message,
          isInfoDialog : true,
          headerBgClass : CommonUIStrings.REJECT_POPUP_BG_CLASS,
          headerIcon : CommonUIStrings.REJECT_POPUP_ICON_CLASS
        };

        let dialogRef;

        dialogRef = this.dialog.open(DialogResultCustomDialog);

        dialogRef.afterClosed().subscribe(result => {
          // console.log(result);

          if(result != undefined && result != null)
          {

          }
        });
      }
    );

  }

  checkPassword(password){
    let passwordRegEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

    // console.log("password.value.match(passwordRegEx): ", password.search(passwordRegEx));

    if(password.search(passwordRegEx) == 0)
      this.isPasswordValid = true;
    else
      this.isPasswordValid = false;
  }
}
