import { CommonModule, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { LanguageTranslationModule } from './shared/modules/language-translation/language-translation.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {DialogResultCustomDialog} from "./dialogs/custom-dialog/custom-dialog-component";
import {MatDialogModule} from '@angular/material/dialog';
import { ColorPickerModule } from 'ngx-color-picker';
import {MatFormFieldModule, MatListModule} from '@angular/material';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared';
import {UtilService} from "./util/utility.services";
import {SelectAccountService} from "./util/select-account.services";
import {SharedService} from "./util/shared-service.services";

import { LoginComponent } from '../app/login/login.component';
import { ForgotPasswordComponent } from '../app/forgot-password/forgot-password.component';
import { VerifyAccountComponent } from './verify-account/verify-account.component';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { SelectAccountComponent } from './select-account/select-account.component';
import {orderBy} from "./commons/pipes/orderBy";
import {LayoutModule} from "./layout/layout.module";
import { ExcelService } from './services/excel';
import { PrivacyPolicyComponent } from './login/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './login/terms-of-use/terms-of-use.component';
import { MailfeedComponent } from './mailfeed/mailfeed.component';
import { VideocheckComponent } from './videocheck/videocheck.component';



@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        NoopAnimationsModule,
        HttpClientModule,
        LanguageTranslationModule,
        AppRoutingModule,
        FormsModule,
        HttpModule,
        MatDialogModule,
        MatFormFieldModule,
        MatListModule,
        LayoutModule,
        ColorPickerModule,
    ],
    declarations: [
        AppComponent,
        DialogResultCustomDialog,
        LoginComponent,
        ForgotPasswordComponent,
        VerifyAccountComponent,
        UpdatePasswordComponent,
        SelectAccountComponent,
        orderBy,
        PrivacyPolicyComponent,
        TermsOfUseComponent,
        MailfeedComponent,
        VideocheckComponent
    ],
    providers: [AuthGuard,UtilService,SelectAccountService,SharedService, ExcelService,
    
    {provide:LocationStrategy, useClass:PathLocationStrategy}],
    entryComponents : [DialogResultCustomDialog],
    bootstrap: [AppComponent]
})
export class AppModule {}
