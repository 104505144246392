import { Component, OnInit } from '@angular/core';
import {MatDialog,MatDialogConfig, MatDialogRef} from '@angular/material';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import {RequestOptions, Headers} from "@angular/http";
import {UtilService} from "../../util/utility.services";
import {SharedService} from "../../util/shared-service.services";
import {CommonURL} from "../../commons/commons";
import {CommonUIStrings} from "../../commons/commonUIStrings";
import {CompanyOnBoardObject} from "../../commons/classes/company-onboard-object";
import {DialogResultCustomDialog} from "../../dialogs/custom-dialog/custom-dialog-component";
declare var $: any;
import * as moment from 'moment';
import { NgbDateCustomParserFormatter } from 'src/app/commons/dateformat';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.scss'],
  providers : [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ]
})
export class AddCompanyComponent implements OnInit {

  constructor(private router: Router, private util: UtilService, private sharedService : SharedService, public dialog: MatDialog) {}

  userObject:any = {};
  authDetails:any = {};
  isLinear = false;
  contractStart: any;
  contractEnd: any;
  isValidFile :boolean = true;
  isError : boolean = false;
  isValid :boolean = false;
  minDateError :boolean = false;
  maxDateError :boolean = false;
  mindate:any;
  maxdate:any;
  StartDate: any;
  EndDate: any;
  currentTime = new Date().getMinutes();
  currentHour = new Date().getHours();
  StartTime: any= {hour: this.currentHour, minute: this.currentTime, second: 0};

  company_details:any = {
    com_name : "",
    com_logo : null,
    contact_name : "",
    contact_number : "",
    contact_emailid : "",
    cont_startdate : "",
    cont_enddate : "",
    com_address : "",
    GSTN : "",
    bank_ac_name : "",
    account_no : "",
    bank_name : "",
    bank_branch_name : "",
    ifsc_code : "",
    group : 'CUSTOMER'
  }
  edit;

  requiredFields:any = ['com_name', 'contact_name', 'contact_number', 'contact_emailid', 'com_address'];
  source_file:any;
  type;
  blank_company_details:any;

  ngOnInit() {
    this.userObject = JSON.parse(this.util.getLocalStorageKey(CommonURL.LOCAL_STORAGE_USER_KEY));

    if(this.userObject == null || this.userObject == undefined)
      this.router.navigate(['/login']);
    else
    {
      this.authDetails = {
        branch_id : this.userObject.branch_id,
        auth_token : this.userObject.token
      };

      if(this.sharedService.companyObject != null)
      {
          this.company_details = { ...this.sharedService.companyObject }
          let syear = moment.utc(this.company_details.cont_startdate).year();
          let smonth = moment.utc(this.company_details.cont_startdate).month();
          let sday = moment.utc(this.company_details.cont_startdate).day();
          this.company_details.cont_startdate = {year: syear,month: smonth,day:sday}
          let eyear = moment.utc(this.company_details.cont_enddate).year();
          let emonth = moment.utc(this.company_details.cont_enddate).month();
          let eday = moment.utc(this.company_details.cont_enddate).day();
          this.company_details.cont_enddate = {year: eyear,month: emonth,day:eday}
          this.edit = true;
          this.source_file = 'https://' + this.company_details.com_logo;
          this.type = 'IMAGE';
          delete this.sharedService.companyObject;
          // console.log(this.company_details);
      }

      if(this.userObject.role_id != 1)
      {
        this.router.navigate(['/view-company']);
      }
    }
  }
  expiryDateOptions: any;

  attachFile(ev){
  if (ev.target.files && ev.target.files[0] && this.validateFileType(ev.target.files[0].type)) {
    this.isValid = true;
    this.isError = false;
    var reader = new FileReader();
    reader.readAsDataURL(ev.target.files[0]);
    var file_type = ev.target.files[0].type.split('/');
    this.type = file_type[0].toUpperCase();
    if(this.type == 'IMAGE') {
      reader.onload  = (e:any)  => {
        this.company_details.com_logo = ev.target.files[0];
        this.source_file = reader.result;
        // $('#_file').html("<img style='max-width: 100%; max-height: 100%' src=" + reader.result + " />");
      };
    }
    else {
      this.company_details.com_logo = null;
      this.source_file = '';
      this.isError = true;
      this.isValid = false;
    }
  }
}

validateFileType(type) {
  for(let i=0; i< CommonURL.FILE_TYPES.length; i++ ) {
    if(type == CommonURL.FILE_TYPES[i].type){
      this.isValidFile = true;
      return true
    }
  }
  this.isValidFile = false;
  return false;
}

addCompanyName(companyNameForm){

}

contactDetails(contactDetailForm){

}

bankDetails(bankDetailForm){

}

dateChange(date, type) {
  // console.log('value',date);
  if(type == 'contract_start_date') {
    // console.log("date: ", date);
    let startDate = Object.assign({}, date);
    this.company_details.cont_enddate = moment(startDate).add(30, 'minutes');
    if(this.company_details.cont_enddate && this.company_details.cont_enddate <= date) {
      //this.company_details.cont_startdate = null;
      this.maxDateError = true;
    } else {
      this.maxDateError = false;
    }
    this.company_details.cont_startdate = date;

  }
  if(type == 'contract_end_date') {
    if(this.company_details.cont_startdate && this.company_details.cont_startdate >= date) {
      //this.company_details.cont_enddate = null;
      this.minDateError = true;
    } else {
      this.minDateError = false;
    }
    this.company_details.cont_enddate = date;
  }
}

SaveCompany(){

    let validatedData = this.validateFields();

    if(validatedData != "" && validatedData != true)
    {
      let dialogRef;
      this.sharedService.updateIsRequestOn(false);
      this.sharedService.dialogText = {
        title : CommonUIStrings.FAILURE,
        description : this.validateFields() + ' is required',
        isInfoDialog : true,
        headerBgClass : CommonUIStrings.REJECT_POPUP_BG_CLASS,
        headerIcon : CommonUIStrings.REJECT_POPUP_ICON_CLASS
      };

      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        // console.log(result);

        if(result != undefined && result != null)
        {

        }
      });
      return false;
    }
  if(this.isError || this.minDateError || this.maxDateError) {
    return false;
  }


  this.company_details.cont_startdate = this.dateConvert(Object.assign(this.company_details.cont_startdate, this.StartTime));;
  this.company_details.cont_enddate = this.dateConvert(Object.assign(this.company_details.cont_enddate, this.StartTime));;

  let callurl;
  if(this.edit)
  {
    callurl = CommonURL.URL_UPDATE_COMPANY;
    delete this.company_details.group;
  }
  else
    callurl = CommonURL.URL_ADD_COMPANY;

  let headers = new Headers({ 'Authorization' : this.userObject.token });
  let options = new RequestOptions({ headers: headers });
  let paramsObject = CompanyOnBoardObject.create(this.company_details);

  // console.log(this.company_details);

  this.sharedService.updateIsRequestOn(true);
  this.util.callPostApi(CommonURL.BASE_URL + callurl, paramsObject.formData, options).subscribe(
    (response) => {

      let successmsg;
      if(this.edit)
        successmsg = CommonUIStrings.COMPANY_UPDATE_SUCCESS;
      else
        successmsg = CommonUIStrings.COMPANY_ADD_SUCCESS;

      this.sharedService.dialogText = {
        title : CommonUIStrings.SUCCESS,
        description : successmsg,
        isInfoDialog : true,
        headerBgClass : CommonUIStrings.SUCCESS_POPUP_BG_CLASS,
        headerIcon : CommonUIStrings.SUCCESS_POPUP_ICON_CLASS
      };

      let dialogRef;

      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        // console.log(result);

        if(result != undefined && result != null)
        {
          this.router.navigate(['company-list']);
        }
      });

      // console.log(response);
      this.sharedService.updateIsRequestOn(false);

    }, (error) => {
      console.log(JSON.stringify(error));
      this.sharedService.updateIsRequestOn(false);
      this.sharedService.dialogText = {
        title : CommonUIStrings.FAILURE,
        description :error.message,
        isInfoDialog : true,
        headerBgClass : CommonUIStrings.REJECT_POPUP_BG_CLASS,
        headerIcon : CommonUIStrings.REJECT_POPUP_ICON_CLASS
      };

      let dialogRef;
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        // console.log(result);

        if(result != undefined && result != null)
        {

        }
      });
    });
}

dateConvert(date_string) {
  if(date_string) {
    if(typeof date_string == 'string') {
      return date_string
    } else {
      var datetime = date_string.year +'-'+ date_string.month +'-'+ date_string.day +' '+date_string.hour +':'+ date_string.minute +':'+date_string.second;
      var local = new Date(datetime);
      var utcdatetime = local.toISOString();
      return utcdatetime;
    }
  } else {
    return null;
  }
}

validateFields(){
  for(let i = 0;i < this.requiredFields.length; i++)
  {
    if(this.company_details[this.requiredFields[i]] == "" && !this.company_details[this.requiredFields[i]].replace(/\s/g, '').length)
        return this.requiredFields[i];
  }

  return true;
}
}
