export class CompanyOnBoardObject {

  formData:any;

  static create(company_details)
  {

    return new CompanyOnBoardObject(company_details);
  }




  constructor(company_details)
  {

    this.formData = new FormData();

    if(company_details.com_id != undefined)
      this.formData.append('com_id', company_details.com_id);
    
    if(typeof company_details.com_logo == "object")
      this.formData.append('com_logo', company_details.com_logo);

    this.formData.append('com_name', company_details.com_name);

    this.formData.append('com_address', company_details.com_address);

    this.formData.append('contact_name', company_details.contact_name);

    this.formData.append('contact_number', company_details.contact_number);

    this.formData.append('contact_emailid', company_details.contact_emailid);

    if(company_details.cont_startdate != "")
      this.formData.append('cont_startdate', company_details.cont_startdate);

    if(company_details.cont_enddate != "")
      this.formData.append('cont_enddate',company_details.cont_enddate);

    if(company_details.GSTN != "")
      this.formData.append('GSTN', company_details.GSTN);

    if(company_details.bank_ac_name != "")
      this.formData.append('bank_ac_name', company_details.bank_ac_name);

    if(company_details.account_no != "")
      this.formData.append('account_no', company_details.account_no);

    if(company_details.bank_name != "")
      this.formData.append('bank_name', company_details.bank_name);

    if(company_details.bank_branch_name != "")
      this.formData.append('bank_branch_name', company_details.bank_branch_name);

    if(company_details.ifsc_code != "")
      this.formData.append('ifsc_code', company_details.ifsc_code);

    if(company_details.group != undefined)
      this.formData.append('group', company_details.group);

    
  }
}
