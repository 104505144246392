export class CompanytvuserUpdateObject {

    formData:any;

    static create(Tvuser_det)
    {

      return new CompanytvuserUpdateObject(Tvuser_det);
    }




    constructor(Tvuser_det)
    {

      this.formData = new FormData();

      this.formData.append('tvu_id', Tvuser_det.tv_id);

      if(Tvuser_det.username != undefined)
        this.formData.append('username', Tvuser_det.username);

      if(Tvuser_det.alias != undefined)
      this.formData.append('alias', Tvuser_det.alias);

      if(Tvuser_det.password != undefined)
        this.formData.append('password', Tvuser_det.password);

      if(Tvuser_det.box_numer != undefined)
        this.formData.append('box_numer', Tvuser_det.box_numer);

      this.formData.append('lastchange_by', Tvuser_det.lastchange_by);

      if(Tvuser_det.timezone_id != undefined)
        this.formData.append('timezone_id', Tvuser_det.timezone_id);

      if(Tvuser_det.screen_type_id != undefined)
        this.formData.append('screen_type_id', Tvuser_det.screen_type_id);

    }
  }
