export class ForgotPasswordObject {
  email:any;

  static create(email){
    return new ForgotPasswordObject(email);
  }

  constructor(email){
    this.email = email;
  }
}
