import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AddCompanyComponent } from './add-company/add-company.component';
import { HtmlLinkComponent } from './html-link/html-link.component';
import { GlobalPublisherComponent } from './global-publisher/global-publisher.component';
import { RegionalPublisherComponent } from './regional-publisher/regional-publisher.component';
import { PublisherComponent } from './publisher/publisher.component';
import { EventsComponent } from './events/events.component';
import { CompanyListComponent } from './company-list/company-list.component';
import { ApprovePanelComponent } from './approve-panel/approve-panel.component';
import { AddGroupComponent } from './add-group/add-group.component';
import { UpdateGroupComponent } from './update-group/update-group.component';
import { ViewGroupComponent } from './view-group/view-group.component';
import { AddCompanyBranchComponent } from './add-company-branch/add-company-branch.component';
import { ViewBranchComponent } from './view-branch/view-branch.component';
import { MainscreenComponent } from './mainscreen/mainscreen.component';
import { BranchListComponent } from './branch-list/branch-list.component';
import { ViewCompanyComponent } from './view-company/view-company.component';
import { ViewMediaComponent } from './view-media/view-media.component';
import { AddRibbonComponent } from './add-ribbon/add-ribbon.component';
import { ActiveBoxReportComponent } from './active-box-report/active-box-report.component';
import { LogStatusComponent } from './log-status/log-status.component';
import { DeviceMonthlyReportComponent } from './device-monthly-report/device-monthly-report.component';

const routes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: '', redirectTo: 'active-box-report', pathMatch: 'prefix' },
            { path: 'dashboard', component: DashboardComponent },
            { path: 'charts', loadChildren: './charts/charts.module#ChartsModule' },
            { path: 'tables', loadChildren: './tables/tables.module#TablesModule' },
            { path: 'forms', loadChildren: './form/form.module#FormModule' },
            { path: 'main', loadChildren: './bs-element/bs-element.module#BsElementModule' },
            { path: 'grid', loadChildren: './grid/grid.module#GridModule' },
            { path: 'components', loadChildren: './bs-component/bs-component.module#BsComponentModule' },
            { path: 'blank-page', loadChildren: './blank-page/blank-page.module#BlankPageModule' },
            { path: 'html-link', component : HtmlLinkComponent },
            { path: 'MainScreen', component : MainscreenComponent },
            { path: 'add-company', component : AddCompanyComponent },
            { path: 'global-publisher', component : GlobalPublisherComponent },
            { path: 'regional-publisher', component : RegionalPublisherComponent },
            { path: 'publisher', component : PublisherComponent },
            { path: 'events', component : EventsComponent },
            { path: 'company-list', component : CompanyListComponent },
            { path: 'approve-panel', component : ApprovePanelComponent },
            { path: 'add-group', component : AddGroupComponent },
            { path: 'update-group', component : UpdateGroupComponent },
            { path: 'view-group', component : ViewGroupComponent },
            { path: 'add-company-branch', component : AddCompanyBranchComponent },
            { path: 'view-branch', component : ViewBranchComponent },
            { path: 'branch-list', component : BranchListComponent },
            { path: 'view-company', component : ViewCompanyComponent },
            { path: 'view-media', component : ViewMediaComponent },
            { path: 'add-ribbon', component : AddRibbonComponent },
            { path: 'active-box-report', component : ActiveBoxReportComponent },
            { path: 'log-status', component: LogStatusComponent},
            { path: 'device-monthly-report/:tvu_id', component: DeviceMonthlyReportComponent }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LayoutRoutingModule {}
