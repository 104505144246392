export class CompanyUserOnBoardObject {

    formData:any;
  
    static create(User_det)
    {
  
      return new CompanyUserOnBoardObject(User_det);
    }
  
  
  
  
    constructor(User_det)
    {
  
      this.formData = new FormData();
  // console.log(User_det);
      this.formData.append('com_id', User_det.com_id);

      this.formData.append('contact_emailid', User_det.contact_emailid);

      this.formData.append('role_id', User_det.role_id);

      this.formData.append('contact_name', User_det.contact_name);

      this.formData.append('contact_number', User_det.contact_number);

      this.formData.append('is_mfa_enabled',User_det.is_mfa_enabled);

      this.formData.append('login_method',User_det.login_method);

      if(User_det.role_id == 4 || User_det.role_id == 5)
      {
        this.formData.append('is_approval_req', User_det.is_approval_req);
        this.formData.append('assigned_group', User_det.assigned_group);
      }
        
      this.formData.append('added_by', User_det.added_by);
  
    }
  }
  