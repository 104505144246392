export class CompanytvuserOnBoardObject {

    formData:any;

    static create(Tvuser_det)
    {

      return new CompanytvuserOnBoardObject(Tvuser_det);
    }




    constructor(Tvuser_det)
    {

      this.formData = new FormData();

      this.formData.append('box_numer', Tvuser_det.box_numer);

      this.formData.append('username', Tvuser_det.username);

      this.formData.append('alias', Tvuser_det.alias);

      this.formData.append('branch_id', Tvuser_det.branch_id);

      this.formData.append('lastchange_by', Tvuser_det.lastchange_by);

      this.formData.append('timezone_id', Tvuser_det.timezone_id);

      this.formData.append('screen_type_id', Tvuser_det.screen_type_id);

    }
  }
