import { Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {MatDialog,MatDialogConfig, MatDialogRef} from '@angular/material';
import { Router } from '@angular/router';
import {RequestOptions, Headers} from "@angular/http";
import {UtilService} from "../../util/utility.services";
import {SharedService} from "../../util/shared-service.services";
import {CommonURL} from "../../commons/commons";
import {CommonUIStrings} from "../../commons/commonUIStrings";
import {CompanyUserOnBoardObject} from "../../commons/classes/add_company_user_object";
import {CompanyUserUpdateObject} from "../../commons/classes/update_company_user_object";
import {DialogResultCustomDialog} from "../../dialogs/custom-dialog/custom-dialog-component";
import { TreeviewItem, TreeviewConfig, DownlineTreeviewItem, TreeviewEventParser, OrderDownlineTreeviewEventParser, } from 'ngx-treeview';
import { isNil, remove, reverse } from 'lodash';

@Component({
  selector: 'app-view-company',
  templateUrl: './view-company.component.html',
  styleUrls: ['./view-company.component.scss'],
  providers : [
    { provide: TreeviewEventParser, useClass: OrderDownlineTreeviewEventParser }
  ]
})
export class ViewCompanyComponent implements OnInit {

  userObject:any = {};
  authDetails:any = {};
  isedit:boolean = false;
  user= {
    com_id : 1,
    contact_emailid : '',
    role_id : 0,
    contact_name : '',
    contact_number : '',
    is_approval_req : 1,
    assigned_group : '',
    added_by : '',
    login_method:"",
    is_mfa_enabled:true
  };
  checked;
  userdet = { ...this.user };
  isapprove:boolean = true;
  enableMfa:number = 0;
  groups:any;
  parent = 0;
  selectedGroup:any = [];
  items : TreeviewItem[];
  values: number[];
  login_method:string = "default";
  mfaStatusChanged:boolean = false ;
  config = TreeviewConfig.create({
    hasAllCheckBox: true,
    hasFilter: true,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    maxHeight: 400
  });
  CompanyDet;
  UserDet;
  private modalRef: NgbModalRef;

  constructor(private router: Router, private util: UtilService, private sharedService : SharedService, public dialog: MatDialog,config: NgbModalConfig, private modalService: NgbModal) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit() {
    this.userObject = JSON.parse(this.util.getLocalStorageKey(CommonURL.LOCAL_STORAGE_USER_KEY));

    if(this.userObject == null || this.userObject == undefined)
      this.router.navigate(['/login']);
    else
    {
      this.authDetails = {
        auth_token : this.userObject.token,
        company_id : this.userObject.company_id,
        cu_id : this.userObject.cu_id,
        group_id: this.userObject.assigned_group,
        role_id: this.userObject.role_id
      };
      // console.log('shared : ',this.sharedService.companyObject);
      if(this.sharedService.companyObject != null)
      {
          this.authDetails.company_id = this.sharedService.companyObject.com_id;
          // delete this.sharedService.companyObject;
      }

      this.GetCompanyDetails();
      this.getCompanyUsers();
    }
  }

  GetCompanyDetails()
  {
    let headers = new Headers({ 'Authorization' : this.authDetails.auth_token });
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });

    let groupParamsObject = {
      company_id: this.authDetails.company_id
    }
    let body = JSON.stringify(groupParamsObject);

    this.sharedService.updateIsRequestOn(true);
    this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_GET_COMPANYDETAILS, body, options).subscribe(
      (response) => {

        // console.log(response.result);
        this.CompanyDet = response.result;
        this.sharedService.updateIsRequestOn(false);
      },
      (error) => {
        console.log(JSON.stringify(error));
        this.sharedService.updateIsRequestOn(false);
      }
    );
  }

  getCompanyUsers(){

    let headers = new Headers({ 'Authorization' : this.authDetails.auth_token });
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });

    let groupParamsObject = {
      company_id: this.authDetails.company_id
    }
    let body = JSON.stringify(groupParamsObject);

    this.sharedService.updateIsRequestOn(true);
    this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_GET_COMPANY_USERS, body, options).subscribe(
      (response) => {
        this.UserDet = response.result;
        // console.log(response.result, "yayy");
        this.sharedService.updateIsRequestOn(false);
      },
      (error) => {
        console.log(JSON.stringify(error));
        this.sharedService.updateIsRequestOn(false);
      }
    );
  }
  open(content,role,type) {
     if(type == 'Add'){
      //  console.log("role", role);
      //  console.log("Inside If");

       this.user= {
        com_id : 1,
        contact_emailid : '',
        role_id : 0,
        contact_name : '',
        contact_number : '',
        is_approval_req : 1,
        assigned_group : '',
        added_by : '',
        is_mfa_enabled:true,
        login_method:"default"
      };
      this.user.assigned_group = '';
      this.selectedGroup = ''
      this.items = [];
      this.isedit = false;
    }
   else if(type == "Edit")
    {
      // console.log("Inside else")
      // console.log("Inside else", role)
      this.isedit = true;
      this.user.contact_emailid = role.user_email;
      this.user.contact_name = role.user_first_name;
      this.user.contact_number = role.user_phone_number;
      this.user['cu_id'] = role.user_id;
      this.user.assigned_group = role.assigned_group;
      this.selectedGroup = role.assigned_group.split(',').map(Number);
      this.user.is_approval_req = role.is_approval_req;
      this.user.is_mfa_enabled =Boolean(role.is_mfa_enabled);
      this.user.login_method = role.login_method;
    }

    // console.log('data',this.selectedGroup);
    this.user.role_id = role.role_id;
    if(role.role_id == 4 || role.role_id == 5)
      this.GetGroupdata();
    this.modalRef = this.modalService.open(content);

  }

  GetGroupdata()
   {
    let headers = new Headers({ 'Authorization' : this.authDetails.auth_token });
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });

    let groupParamsObject = {
      company_id: this.authDetails.company_id,
      group_id: this.authDetails.group_id,
      role_id: this.authDetails.role_id
    }
    let body = JSON.stringify(groupParamsObject);

    this.sharedService.updateIsRequestOn(true);
    this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_GET_GROUP, body, options).subscribe(
      (response) => {

        // console.log(response.result);
        this.groups = this.converttree(response.result,this.parent);
        this.items = this.gettreeview(this.groups);
        this.sharedService.updateIsRequestOn(false);
      },
      (error) => {
        console.log(JSON.stringify(error));
        this.sharedService.updateIsRequestOn(false);
      }
    );
   }

  addUserToRole(){
    // console.log("selected group", this.selectedGroup);
    this.user.added_by = this.authDetails.cu_id;
    this.user.com_id = this.authDetails.company_id;

    let headers = new Headers({ 'Authorization' : this.authDetails.auth_token });
    // headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });

    let paramsobject,CALLURL;

    if(!this.mfaStatusChanged && this.isedit){
      this.user.is_mfa_enabled = null ;
    }

    if(this.user.role_id == 4 || this.user.role_id == 5 || this.user.role_id == 1)
    {
      this.user.assigned_group = this.selectedGroup.join(',');
    }
    if(this.isedit)
    {
      paramsobject = CompanyUserUpdateObject.create(this.user);
      CALLURL = CommonURL.URL_UPDATE_COMPANY_USER;
    }
    else
    {
      paramsobject = CompanyUserOnBoardObject.create(this.user);
      CALLURL = CommonURL.URL_GET_COMPANY_ASSIGN_ROLES;
    }

    let body = paramsobject;
    // console.log("body formed ",body.formData.get("is_mfa_enabled"))

    this.sharedService.updateIsRequestOn(true);
    // console.log("this is the final form data ",this.user);
    this.modalRef.close();
    this.util.callPostApi(CommonURL.BASE_URL + CALLURL, body.formData, options).subscribe(
    (response) => {
      // paramsobject = "";
      let msg;
      if(this.isedit)
        msg = CommonUIStrings.COMPANY_UPDATE_USER_SUCCESS
      else
        msg = CommonUIStrings.COMPANY_ADD_USER_SUCCESS

      this.sharedService.dialogText = {
        title : CommonUIStrings.SUCCESS,
        description : msg,
        isInfoDialog : true,
        headerBgClass : CommonUIStrings.SUCCESS_POPUP_BG_CLASS,
        headerIcon : CommonUIStrings.SUCCESS_POPUP_ICON_CLASS
      };

            let dialogRef;

            dialogRef = this.dialog.open(DialogResultCustomDialog);

            dialogRef.afterClosed().subscribe(result => {
              if(result != undefined && result != null)
              {

              }
            });
            this.user = { ...this.userdet };
            this.mfaStatusChanged = false;
            this.getCompanyUsers();
            this.sharedService.updateIsRequestOn(false);
          },
          (error) => {
            // console.log(JSON.stringify(error));
            this.mfaStatusChanged = false;
            let dialogRef;
            this.sharedService.dialogText = {
            title : CommonUIStrings.FAILURE,
            description : error.message,
            isInfoDialog : true,
            headerBgClass : CommonUIStrings.REJECT_POPUP_BG_CLASS,
            headerIcon : CommonUIStrings.REJECT_POPUP_ICON_CLASS
          };

          const dialogConfig = new MatDialogConfig();

          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

          dialogRef.afterClosed().subscribe(result => {
            // console.log(result);

            if(result != undefined && result != null)
            {
              this.getCompanyUsers();
            }
          });
            this.sharedService.updateIsRequestOn(false);
          }
        );
  }

  gettreeview(data)
   {
    const items: TreeviewItem[] = [];
    // console.log('Main data',data);
    for (let i = 0; i < data.length; i++) {
        const value: any = data[i].gd_id;
        let checked;
        if(this.selectedGroup.includes(data[i].gd_id))
          this.checked = true;

        let child:any;
        if(data[i].children)
        {
          child = this.gettreeview(data[i].children)
        }
        if(this.checked)
          checked = true;
        else
          checked = false;

        // console.log(data[i].gd_id);
        // console.log(checked);
        const item = new TreeviewItem({ text: data[i].gd_name + ' ' + data[i].timezone + ' ' + data[i].screen_type_name, value: value, checked: checked,children: child });
        // console.log(item);
        if(this.selectedGroup.includes(data[i].gd_id))
          this.checked = false;
        items.push(item);
    }
    // console.log("ITEMS",items);
    return items;
  }

   converttree(data,parent)
   {
    var out = []
    for(var i in data) {
      if(data[i].parent == parent) {
          var children = this.converttree(data, data[i].gd_id)
          if(children.length) {
              data[i].children = children
            }
            out.push(data[i])
        }
    }
    return out

    //   console.log(root);
   }

   getgrouparray(data:DownlineTreeviewItem[])
   {
    //  console.log("data", data)
      const array = [];
      data.forEach(downlineItem => {

    //     const item = downlineItem.item;
    //     const value = item.value;
    //     console.log("value", value);
    //     const texts = [item.text];
    //     console.log("Text", texts)
    //     let parent = downlineItem.parent;
    //     console.log("parent", parent);
    //     while (!isNil(parent)) {
    //         texts.push(parent.item.text);
    //         parent = parent.parent;
    //     }
    //     const reverseTexts = reverse(texts);
    //     const row = `${reverseTexts.join(' -> ')} : ${value}`;
    //     array.push(row);
    //     console.log("Array", array);
    // });
          // console.log("MAIN", downlineItem);
          let child = downlineItem.item;
          // console.log("child val", child.value);
          // console.log("child val", child);
          let parent = downlineItem.parent;
          let parent_n = downlineItem.parent
          // console.log("parent val", parent_n)
          if(parent == null){
            // console.log("When parent is null")
            array.push(downlineItem.item.value);
          }
            // if(downlineItem.item.children == undefined){
            //   array.push(downlineItem.item.value);
            // }
            // console.log("Outside", parent.parent)

            while (!isNil(parent)) {
              // console.log("parent in loop", parent);
              // console.log("INSIDE SECOND WHILE", parent) parent.item.children == undefined
              if(parent.item.checked == undefined && child.children == undefined && (parent.item.indeterminate == true || parent.parent.item.indeterminate == false)){
                // console.log("when parent is null");
                // && parent_n.item.checked == undefined
                // if(child.checked == true && parent.item.checked == undefined && parent.item.children == undefined) {
                //   console.log("###@@#####", child.value);
                //   array.push(child.value);
                // }
                if(parent.item.children){
                  // console.log("YAYY !!!")
                  for(let i = 0 ; i < parent.item.children.length ; i++){
                    // console.log("parnet.item", parent.item.children[i])
                    if(parent.item.children[i].checked == true){
                      array.push(parent.item.children[i].value);
                    }
                  }
                }
              }
              if(parent.item.checked)
              {
                // console.log("inside parent loop")
                if(parent.parent == null || parent.parent.item.checked == undefined){
                  // console.log("inside child loop", parent);
                  array.push(parent.item.value);
                  // console.log("parent value", parent.item.value)
                }
                  // array.push(parent.item.internalChildren)
              }

              parent = parent.parent;
            }
      });
      this.selectedGroup =  Array.from(new Set(array));
   }

   //Remove User
  Removeuser(user)
  {
    this.sharedService.dialogText = {
      title : 'Delete User Details',
      description :CommonUIStrings.DELETE_USER_TITLE,
      isInfoDialog : false,
      headerBgClass : CommonUIStrings.CONFIRM_POPUP_BG_CLASS,
      headerIcon : CommonUIStrings.CONFIRM_POPUP_ICON_CLASS
    };

    let dialogRef;
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

    dialogRef.afterClosed().subscribe(result => {

      if(result != undefined && result != null)
      {
        if(result)
        {
          let body = new FormData();

          body.append('com_id', user.company_id);
          body.append('cu_id', user.user_id);
          body.append('role_id', user.role_id);
          this.DeleteUser(body);
        }
        else
        {

        }
      }
    });
  }

DeleteUser(body)
{
  let headers = new Headers({
    'authorization': this.authDetails.auth_token
     });
  let options = new RequestOptions({ headers: headers});

  this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_DELETE_COMPANY_USER, body, options).subscribe(
    (response) => {
      this.sharedService.dialogText = {
        title : CommonUIStrings.SUCCESS,
        description :CommonUIStrings.USER_DELETED_SUCCESS,
        isInfoDialog : true,
        headerBgClass : CommonUIStrings.CONFIRM_POPUP_BG_CLASS,
        headerIcon : CommonUIStrings.CONFIRM_POPUP_ICON_CLASS
      };

      let dialogRef;
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

      dialogRef.afterClosed().subscribe(result => {

        if(result != undefined && result != null)
        {
          this.getCompanyUsers();
        }
      });
    }, (error) => {
      console.log(error);

      let dialogRef;
      this.sharedService.dialogText = {
      title : CommonUIStrings.FAILURE,
      description : error.message,
      isInfoDialog : true,
      headerBgClass : CommonUIStrings.REJECT_POPUP_BG_CLASS,
      headerIcon : CommonUIStrings.REJECT_POPUP_ICON_CLASS
    };

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);

      if(result != undefined && result != null)
      {
        this.getCompanyUsers();
      }
    });
    }
  );
}

//Edit Company
EditCompany(element){
  this.sharedService.companyObject = element;
  this.router.navigate(["add-company"]);
}

changeLoginOption(event){
  this.user.login_method = event.value
}

rekeyMfa(user){
  console.log("user user everywhere ",user);

  if (user.user_email) {



    this.sharedService.dialogText = {
      title : 'Generate new MFA key',
      description :CommonUIStrings.REKEY_MFA,
      isInfoDialog : false,
      headerBgClass : CommonUIStrings.CONFIRM_POPUP_BG_CLASS,
      headerIcon : CommonUIStrings.CONFIRM_POPUP_ICON_CLASS
    };

    let dialogRef;
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
         let headers = new Headers({ 'Authorization' : this.authDetails.auth_token });
      headers.append('Content-Type', 'application/json');
      let options = new RequestOptions({ headers: headers });
      this.util.callPostApi(CommonURL.BASE_URL+CommonURL.URL_REKEY_MFA,{cu_emailid:user.user_email},options).subscribe(data=>{

        this.sharedService.dialogText = {
          title : 'Success',
          description :"Successfully Generated new MFA key",
          isInfoDialog : true,
          headerBgClass : CommonUIStrings.CONFIRM_POPUP_BG_CLASS,
          headerIcon : CommonUIStrings.CONFIRM_POPUP_ICON_CLASS
        };

        let dialogRef;
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

      });
      }

    });



} else {
    // show popup here
}
}


changeMfaStatus(){
  this.mfaStatusChanged = true ;
}

}
