import { Pipe, PipeTransform } from '@angular/core';
declare var $:any;


@Pipe({ name: 'companyListFilter' })
export class CompanyListFilter implements PipeTransform {
  transform(comapnyList, searchString) {
    if (comapnyList == null) {
      return null;
    }
    if(searchString != undefined){
      searchString = searchString.toLowerCase().trim();
    }
    return comapnyList.filter(company => {
      let cond1:any;
      let cond2:any;
      let cond3:any;
      let cond4:any;

      // if(company['name'] != undefined)7
      //   cond1 = new RegExp(searchString).test(company['name'].toLowerCase());

      if(company['address'] != undefined)
        cond2 = new RegExp(searchString).test(company['address'].toLowerCase());

      if(company['company_name'] != undefined)
        cond3 = new RegExp(searchString).test(company['company_name'].toLowerCase());

      if(company['display_address'] != undefined)
        cond4 = new RegExp(searchString).test(company['display_address'].toLowerCase());

      return cond2 || cond3 || cond4;
      }
    );
  }
}
