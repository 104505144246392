import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MatDialogConfig} from '@angular/material';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import {UtilService} from "../util/utility.services";
import {CommonURL} from "../commons/commons";
import {RequestOptions, Headers} from "@angular/http";
import {SelectAccountService} from "../util/select-account.services";
import * as CryptoJS from 'crypto-js';
import {CompanyListFilter} from "../commons/pipes/companyListFilter";
import {SharedService} from "../util/shared-service.services";
import {CommonUIStrings} from "../commons/commonUIStrings";
import {DialogResultCustomDialog} from "../dialogs/custom-dialog/custom-dialog-component";
declare var $: any;


@Component({
  selector: 'select-account-root',
  templateUrl: './select-account.component.html',
  styleUrls: ['./select-account.component.scss']
})
export class SelectAccountComponent implements OnInit{
  ex= "Rajwada chowk, Baner, Main, Baner Rd, Pune, Maharashtra 411045, Rajwada chowk, Baner, Main, Baner Rd, Pune, Maharashtra 411045"


  companyList:any = [];
  userObject:any = [];
  searchText:any;
  constructor (private router: Router, private util: UtilService,  private selectAccountService : SelectAccountService, private sharedService : SharedService, public dialog: MatDialog){

    this.companyList = this.selectAccountService.companyList;
  }


  ngOnInit():void {
    // $("#loginContent").css({
    //   'height' : $(window).height()
    // });

    this.userObject = JSON.parse(this.util.getLocalStorageKey(CommonURL.LOCAL_STORAGE_USER_KEY));
    let userList = this.util.getLocalStorageKey(CommonURL.LOCAL_STORAGE_USER_LIST);
    let cmpList:any;

    if (this.userObject != null && this.userObject != undefined && userList != null && userList != undefined)
    {
      let a = CryptoJS.AES.decrypt(userList, CommonURL.SECRET_KEY);
      let list = JSON.parse(atob(a.toString(CryptoJS.enc.Utf8)));
      this.selectAccountService.companyList = list.accountList;

      cmpList = list.accountList;

      let tempList = [];
      for (let company of cmpList) {
        let temp = company;
        temp.address = (temp.address == null || temp.address == undefined) ? "" : temp.address;

        temp.display_address = temp.address;
        tempList.push(temp);
      }
      this.companyList = tempList;
    }
    else{

      // console.log(this.selectAccountService.companyList.length);
      if (this.selectAccountService.companyList.length <= 0)
      {
        let a = CryptoJS.AES.decrypt(userList, CommonURL.SECRET_KEY);
        let list = JSON.parse(atob(a.toString(CryptoJS.enc.Utf8)));
        this.selectAccountService.companyList = list.accountList;

        cmpList = list.accountList;

        if (cmpList.length <= 0) {
          this.sharedService.dialogText = {
            title: CommonUIStrings.FAILURE,
            description: "Session Expired! Please Login Again.",
            isInfoDialog: true,
            headerBgClass: CommonUIStrings.REJECT_POPUP_BG_CLASS,
            headerIcon: CommonUIStrings.REJECT_POPUP_ICON_CLASS
          };

          let dialogRef;

          dialogRef = this.dialog.open(DialogResultCustomDialog);

          dialogRef.afterClosed().subscribe(result => {
            // console.log(result);

            this.router.navigate(['/login']);
          });
        }

        let tempList = [];
        for (let company of cmpList) {
          let temp = company;
          temp.address = (temp.address == null || temp.address == undefined) ? "" : temp.address;

          temp.display_address = temp.address;
          tempList.push(temp);
        }
        this.companyList = tempList;

      }
      else
      {
        let accountList = {
          isMultipleAccountPresent: true,
          accountList: this.selectAccountService.companyList
        };

        // console.log(accountList);

        this.util.setLocalstorageKey(CommonURL.LOCAL_STORAGE_USER_LIST, CryptoJS.AES.encrypt(btoa(JSON.stringify(accountList)), CommonURL.SECRET_KEY));
        this.util.setLocalstorageKey(CommonURL.LOCAL_STORAGE_SESSION_TIMESTAMP, Date.now());

        cmpList = accountList.accountList;

        let tempList = [];
        for (let company of cmpList) {
          let temp = company;
          temp.address = (temp.address == null || temp.address == undefined) ? "" : temp.address;

          temp.display_address = temp.address;
          tempList.push(temp);
        }
        this.companyList = tempList;
      }

    }
  }
  title = 'login works!';

  result:any = {};

  TAGCX_OWNER_ID:number = CommonURL.TAGCX_OWNER_ID;

  login(company):void {


    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });

    let dataToSend = {
      email : company.email,
      password : company.password,
      company_id : company.company_id
    };

    let body = JSON.stringify(dataToSend);


   this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_LOGIN, body, headers).subscribe(
      (response) => {
        // console.log(response);
        this.result = response.result;
        this.util.setLocalstorageKey(CommonURL.LOCAL_STORAGE_USER_KEY, JSON.stringify(response.result));
        this.util.setLocalstorageKey(CommonURL.LOCAL_STORAGE_SESSION_TIMESTAMP, Date.now());

        // if(this.result.is_owner)
        // {
        //   this.router.navigate(['/moderator/settings']);
        // }
        // else
        // {
          let redirectURLs = CommonURL.redirectURL;
          for(let i = 0; i < redirectURLs.length; i++)
          {
            if(redirectURLs[i].role_id = this.result.role_id)
            {
              this.router.navigate([redirectURLs[i].url]);
              break;
            }
          }
        // }
      }, (error) => {
        console.log(error);
       if(error.status_code == 401)
       {
         console.log(error);
       }
       if(error.status_code == 400)
       {
         console.log(error);
       }
      }
    );
  }

}
