import { Component, OnInit, Directive, Input, Output, EventEmitter, ViewChildren, QueryList, PipeTransform } from '@angular/core';
import { CommonURL } from 'src/app/commons/commons';
import { UtilService } from 'src/app/util/utility.services';
import { SharedService } from 'src/app/util/shared-service.services';
import { Headers, RequestOptions } from '@angular/http';
import { Router } from '@angular/router';
import { DecimalPipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { LogsFilterService } from '../logs-filter.service';
import {Observable} from 'rxjs';

import {Country} from '../logs_status';


export type SortDirection = 'asc' | 'desc' | '';
const rotate: {[key: string]: SortDirection} = { 'asc': 'desc', 'desc': '', '': 'asc' };
export const compare = (v1, v2) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

export interface SortEvent {
  column: string;
  direction: SortDirection;
}

/* function search(text: string, pipe: PipeTransform): Country[] {
  return this.calculated_result.filter(country => {
    const term = text.toLowerCase();
    return country.branch_name.toLowerCase().includes(term)
        || pipe.transform(country.city).includes(term)
        || pipe.transform(country.branch_code).includes(term);
  });
} */
@Directive({
  selector: 'th[sortable]',
  host: {
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '(click)': 'rotate()'
  }
})

export class NgbdSortableHeader {

  @Input() sortable: string;
  @Input() direction: SortDirection = '';
  @Output() sort = new EventEmitter<SortEvent>();

  rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({column: this.sortable, direction: this.direction});
  }
}

@Component({
  selector: 'app-log-status',
  templateUrl: './log-status.component.html',
  styleUrls: ['./log-status.component.scss'],
  providers: [DecimalPipe, LogsFilterService]
})
export class LogStatusComponent implements OnInit {

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  filter = new FormControl('');

  split_tvu_id;
  split_tv_username;
  yuhuu= [];
  ace = [];
  calci;
  calculated_result;

  userObject:any = {};
  authDetails:any = {};
  slotnumber;
  logs_details;
  logs_details_mongo;


  countries$: Observable<Country[]>;
  total$: Observable<number>;

  constructor(private util: UtilService,
    private sharedService : SharedService,
    private router: Router, public logService: LogsFilterService) {
      this.countries$ = logService.countries$;
      this.total$ = logService.total$;
    }

  ngOnInit() {
    this.userObject = JSON.parse(this.util.getLocalStorageKey(CommonURL.LOCAL_STORAGE_USER_KEY));
    this.slotnumber = (this.util.getLocalStorageKey(CommonURL.SLOT_NUMBER));

    // console.log("slotnumber", this.slotnumber);
    if(this.userObject == null || this.userObject == undefined)
      this.router.navigate(['/login']);
    else
    {
      this.authDetails = {
        branch_id : this.userObject.branch_id,
        auth_token : this.userObject.token,
        company_id : this.userObject.company_id,
        cu_id : this.userObject.cu_id,
        group_id : this.userObject.assigned_group,
        role_id: this.userObject.role_id
      };
      this.showStatus("status", this.slotnumber);
  }

  }

  /****************************************************
   * show status - created for media status i.e download or published
   * api - two api are called one api is for mongo and second for mysql
   * logic - taking mysql data and checking if present in mongo data then it is published on screen
   * ******************************************************** */
  showStatus(status, slotnumber){
    // console.log("status", status);

    let headers = new Headers({ 'Authorization' : this.authDetails.auth_token });
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });

    let slotParamObject = {
      slot_number : slotnumber,
      company_id : this.authDetails.company_id
    }
    // console.log("slot", slotParamObject)
    let body = JSON.stringify(slotParamObject);
    // mysql connected api

    this.sharedService.updateIsRequestOn(false);
    // CommonURL.URL_MYSQL_DATA
    this.util.callPostApi(CommonURL.BASE_URL + 'mysql/logDetails', body, options).subscribe(
      (result) => {
        this.logs_details = result.result[0];
        var mysql_data =  result.result[0];
        // console.log("mysql", mysql_data);
            this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_MONGO_DATA, body, options).subscribe(
              (response) => {
                // console.log(response.result, "mongo");
              //   let news = new Set(response.result);
              //  let backToArray = Array.from(news);
                // console.log("New mongo", backToArray);
                this.logs_details_mongo = response.result;

                var val = response.result
                      for(let j = 0; j < mysql_data.length; j++){
                        if(!mysql_data[j].stats) {
                          mysql_data[j].stats = [];
                        }
                        this.split_tvu_id = mysql_data[j].tvu_id.split(',');
                        this.split_tv_username = mysql_data[j].username.split(',');
                            for(let z = 0; z < this.split_tvu_id.length; z++){
                              let flag = false;
                              for(let i = 0; i < val.length; i++){
                                // console.log(this.split_tvu_id[z],"MATCHES ID", val[i].tv_Id)
                                if(this.split_tvu_id[z] == val[i].tv_Id){
                                  let ex = {
                                    tvu_id : this.split_tvu_id[z],
                                    tv_username: this.split_tv_username[z],
                                    stats : "Downloaded"
                                  }
                                   flag = true;
                                   mysql_data[j]['stats'].push(ex); continue;
                                }
                              }
                              if(!flag){
                                let ex = {
                                  tvu_id: this.split_tvu_id[z],
                                  tv_username: this.split_tv_username[z],
                                  stats: "Published"
                                }
                                mysql_data[j]['stats'].push(ex);
                              }
                            }

                    }
                    this.calculated_result =  result.result[0];

                    this.logService._search(this.calculated_result)
                    this.sharedService.updateIsRequestOn(false);
                    this.logService.values = this.calculated_result
              },
              (error) => {
                console.log(error);
                this.sharedService.updateIsRequestOn(false);
              }
            );

      },
      (error) => {
        console.log(error);
        this.sharedService.updateIsRequestOn(false);
      }
    );
  }

  filters(filterValue: string){
  }

  onSort({column, direction}: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.logService.sortColumn = column;
    this.logService.sortDirection = direction;
  }

  search(text: string, pipe: PipeTransform) {
    // console.log("hi", text)
    return this.calculated_result.filter(country => {
      const term = text.toLowerCase();
      return country.branch_name.toLowerCase().includes(term)
          || pipe.transform(country.branch_code).includes(term)
          || pipe.transform(country.city).includes(term);
    });
  }

}
