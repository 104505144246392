export class LoginObject {
  email : string;
  password: string;
  company_id: string;
  company_name: string;
  address: string;
  company_logo: string;

  static create(email, password, company_id, company_name,company_logo, address){
    return new LoginObject(email, password, company_id, company_name,company_logo, address);
  }

  constructor(email, password, company_id, company_name,company_logo, address){
    this.email = email;
    this.password = password;
    this.company_id = company_id;
    this.company_name = company_name;
    this.address = address;
    this.company_logo = company_logo;
  }
}
