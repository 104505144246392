import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import {MatDialog,MatDialogConfig, MatDialogRef} from '@angular/material';
import { Router } from '@angular/router';
import {RequestOptions, Headers} from "@angular/http";
import {UtilService} from "../../util/utility.services";
import {SharedService} from "../../util/shared-service.services";
import {CommonURL} from "../../commons/commons";
import {CommonUIStrings} from "../../commons/commonUIStrings";
import {DialogResultCustomDialog} from "../../dialogs/custom-dialog/custom-dialog-component";

@Component({
  selector: 'app-add-ribbon',
  templateUrl: './add-ribbon.component.html',
  styleUrls: ['./add-ribbon.component.scss']
})
export class AddRibbonComponent implements OnInit {

  public textColor: string = '#ffffff';
  public backgroundColor: string = '#000000';
  Ribbon_data = {
    text_color: '#ffffff',
    background_color: "#ffffff",
    ribbonstring: "",
    is_active: null
    };
  constructor(private router: Router,
    private util: UtilService,
    private sharedService : SharedService,
    public dialog: MatDialog,
    private modalService: NgbModal,config: NgbModalConfig) { }

  userObject:any = {};
  authDetails:any = {};
  ribbons = [
    {
      Name : 0,
      Value : ''
    },
    {
      Name : 1,
      Value : ''
    },
    {
      Name : 2,
      Value : ''
    }
  ];
  ngOnInit() {

    this.userObject = JSON.parse(this.util.getLocalStorageKey(CommonURL.LOCAL_STORAGE_USER_KEY));

    if(this.userObject == null || this.userObject == undefined)
      this.router.navigate(['/login']);
    else
    {
      this.authDetails = {
        auth_token : this.userObject.token,
        company_id : this.userObject.company_id
      };
      // console.log(this.ribbons);
        this.GetRibbondata();
    }
  }

  DeleteSlot(i) {
    this.ribbons.splice(i, 1);
  }

   AddSlot(i)
   {
      let text = {
        Name : i+1,
        Value : undefined
      };
      this.ribbons.push(text);
   }

  ribbon(value){

  }


  GetRibbondata()
  {
    let headers = new Headers({ 'Authorization' : this.authDetails.auth_token });
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });

    let PendingParamsObject = {
      company_id: this.authDetails.company_id
    }
    let body = JSON.stringify(PendingParamsObject);

    this.sharedService.updateIsRequestOn(true);
    this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_GET_RIBBON, body, options).subscribe(
      (response) => {

        if(response.result.length)
        {
          // console.log("this", response.result);
          this.Ribbon_data = response.result;
          let ribbon = [];
          var texts = this.Ribbon_data.ribbonstring.split('~');
          for(let i =0;i < texts.length;i++)
          {
            let text = {
              Name : i,
              Value : texts[i]
            };
            ribbon.push(text);
          }
          this.ribbons = ribbon;
        }


        this.sharedService.updateIsRequestOn(false);
      },
      (error) => {
        console.log(JSON.stringify(error));
        this.sharedService.updateIsRequestOn(false);
      }
    );
  }

  AddRibbon()
  {

    var Texts = this.ribbons.map((item)  =>  item['Value']);
    this.Ribbon_data.ribbonstring = Texts.join('~');

    let headers = new Headers({ 'Authorization' : this.authDetails.auth_token });
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });

    let RibbonParamsObject = {
      ribbonstring : this.Ribbon_data.ribbonstring,
      company_id: this.authDetails.company_id,
      background_color : this.Ribbon_data.background_color,
      text_color : this.Ribbon_data.text_color,
      is_active : +this.Ribbon_data.is_active
    }
    let body = JSON.stringify(RibbonParamsObject);

    this.sharedService.updateIsRequestOn(true);
    this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_ADD_RIBBON, body, options).subscribe(
      (response) => {

      var msg;
      if(response.result == null)
        msg = CommonUIStrings.DATA_UPDATED_SUCCESS;
      else
        msg = CommonUIStrings.DATA_ADDED_SUCCESS;

        this.sharedService.dialogText = {
          title : CommonUIStrings.SUCCESS,
          description : msg,
          isInfoDialog : true,
          headerBgClass : CommonUIStrings.SUCCESS_POPUP_BG_CLASS,
          headerIcon : CommonUIStrings.SUCCESS_POPUP_ICON_CLASS
        };

        let dialogRef;

        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

        dialogRef.afterClosed().subscribe(result => {

          if(result != undefined && result != null)
          {
            this.GetRibbondata();
          }
        });


        this.sharedService.updateIsRequestOn(false);
      },
      (error) => {
        console.log(JSON.stringify(error));
        this.sharedService.dialogText = {
          title : CommonUIStrings.FAILURE,
          description :error.message,
          isInfoDialog : true,
          headerBgClass : CommonUIStrings.REJECT_POPUP_BG_CLASS,
          headerIcon : CommonUIStrings.REJECT_POPUP_ICON_CLASS
        };

        let dialogRef;
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

        dialogRef.afterClosed().subscribe(result => {

          if(result != undefined && result != null)
          {

          }
        });
        this.sharedService.updateIsRequestOn(false);
      }
    );
  }

  onOffRibbon(data:any,media){

    this.sharedService.dialogText = {
      title : 'Update Media Details',
      description :CommonUIStrings.UPDATE_DATA_TITLE,
      isInfoDialog : false,
      headerBgClass : CommonUIStrings.CONFIRM_POPUP_BG_CLASS,
      headerIcon : CommonUIStrings.CONFIRM_POPUP_ICON_CLASS
    };

    let dialogRef;
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // console.log("res", result)
      if(result != undefined && result != null)
      {
        if(result)
        {
          // console.log("Inside")
         this.AddRibbon();

          // console.log("body", this.EDMedia(body))
        }
        else
        {
          if(this.Ribbon_data.is_active == "true" || this.Ribbon_data.is_active == 1){
            this.Ribbon_data.is_active = 0
          }
          else{
            this.Ribbon_data.is_active = 1
          }
          // this.result[i].active = !data;
        }
      }
    });
  }


}
