export class UpdateMediaObject {

    formData:any;

    static create(media_details)
    {
      return new UpdateMediaObject(media_details);
    }




    constructor(media_details)
    {
      console.log("media details", media_details);
      this.formData = new FormData();

      this.formData.append('branch_id', media_details.branch_id);

      this.formData.append('branch_name', media_details.branch_name);

      this.formData.append('ctd_id', media_details.ctd_id);

      if(media_details.h_media != "")
      {
        this.formData.append('h_media', media_details.h_media);
        this.formData.append('h_duration', media_details.h_duration);
        this.formData.append('h_extension', media_details.h_extension);
        this.formData.append('h_type', media_details.h_type);
      }

      if(media_details.v_media != "")
      {
        this.formData.append('v_media', media_details.v_media);
        this.formData.append('v_duration', media_details.v_duration);
        this.formData.append('v_extension', media_details.v_extension);
        this.formData.append('v_type', media_details.v_type);
      }

      this.formData.append('company_user_id', media_details.company_user_id);




    }
  }
