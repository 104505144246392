import { Component, OnInit } from '@angular/core';
import { UtilService } from 'src/app/util/utility.services';
import {MatDialog,MatDialogConfig, MatDialogRef} from '@angular/material';
import { CommonURL } from 'src/app/commons/commons';
import {SharedService} from "../../util/shared-service.services";
import {CommonUIStrings} from "../../commons/commonUIStrings";
import { Router } from '@angular/router';
import { Headers, RequestOptions } from '@angular/http';
import {CompanyFilter} from "../../commons/pipes/companyfilter";
import {DialogResultCustomDialog} from "../../dialogs/custom-dialog/custom-dialog-component";

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss']
})
export class CompanyListComponent implements OnInit {
constructor( private util: UtilService, private sharedService : SharedService,private router: Router, public dialog: MatDialog) { }
userObject:any = {};
authDetails:any = {};
Companies:any = [];
searchText:any;

  ngOnInit() {
    this.userObject = JSON.parse(this.util.getLocalStorageKey(CommonURL.LOCAL_STORAGE_USER_KEY));

    if(this.userObject == null || this.userObject == undefined)
      this.router.navigate(['/login']);
    else
    {
      this.authDetails = {
        // branch_id : this.userObject.branch_id,
        auth_token : this.userObject.token,
        company_id : this.userObject.company_id,
        cu_id : this.userObject.cu_id
      };
        this.GetCompanyList();
    }
  }

  GetCompanyList(){
    let headers = new Headers({
      'authorization': this.authDetails.auth_token
       });
    let options = new RequestOptions({ headers: headers});

    let body = JSON.stringify({});

    this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_GET_COMPANY_LIST, body, options).subscribe(
      (response) => {
        this.Companies = response.result;
        // console.log("companies",this.Companies);
      }, (error) => {
        console.log(error)
      }
    );
  }


  isActive(data:any,company,i){
    this.sharedService.dialogText = {
      title : 'Update Company Details',
      description :CommonUIStrings.UPDATE_DATA_TITLE,
      isInfoDialog : false,
      headerBgClass : CommonUIStrings.CONFIRM_POPUP_BG_CLASS,
      headerIcon : CommonUIStrings.CONFIRM_POPUP_ICON_CLASS
    };

    let dialogRef;
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

    dialogRef.afterClosed().subscribe(result => {

      if(result != undefined && result != null)
      {
        if(result)
        {
          // console.log(company);
          this.UpdateCompany(data,company);
        }
        else
        {
          this.Companies[i].is_active = !data;
        }
      }
    });
  }

  UpdateCompany(data,company)
  {
    // console.log(data, company);
    let headers = new Headers({
      'authorization': this.authDetails.auth_token
       });
    let options = new RequestOptions({ headers: headers});

    var activeda = +data;
    let body = new FormData();
        
    body.append('com_id', company.com_id);
    body.append('is_active', activeda.toString());

    this.util.callPostApi(CommonURL.BASE_URL + CommonURL.URL_UPDATE_COMPANY, body, options).subscribe(
      (response) => {
        this.sharedService.dialogText = {
          title : CommonUIStrings.SUCCESS,
          description :CommonUIStrings.COMPANY_UPDATE_SUCCESS,
          isInfoDialog : true,
          headerBgClass : CommonUIStrings.CONFIRM_POPUP_BG_CLASS,
          headerIcon : CommonUIStrings.CONFIRM_POPUP_ICON_CLASS
        };

        let dialogRef;
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

        dialogRef.afterClosed().subscribe(result => {

          if(result != undefined && result != null)
          {
            this.GetCompanyList()
          }
        });
      }, (error) => {
        console.log(error);

        let dialogRef;
        this.sharedService.dialogText = {
        title : CommonUIStrings.FAILURE,
        description : error.message,
        isInfoDialog : true,
        headerBgClass : CommonUIStrings.REJECT_POPUP_BG_CLASS,
        headerIcon : CommonUIStrings.REJECT_POPUP_ICON_CLASS
      };

      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        // console.log(result);

        if(result != undefined && result != null)
        {
          this.GetCompanyList();
        }
      });
      }
    );
  }

  ViewCompany(element){
    this.sharedService.companyObject = element;
    this.router.navigate(["view-company"]);
  }

}
