import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {UtilService} from "../../../util/utility.services";
import {CommonURL} from "../../../commons/commons";
import { RequestOptions ,Headers} from '@angular/http';
import { SharedService } from 'src/app/util/shared-service.services';
import { CommonUIStrings } from 'src/app/commons/commonUIStrings';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { DialogResultCustomDialog } from 'src/app/dialogs/custom-dialog/custom-dialog-component';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    public pushRightClass: string;

    constructor(private translate: TranslateService, public router: Router,private util: UtilService, 
        private sharedService : SharedService,public dialog: MatDialog) {

        this.router.events.subscribe(val => {
            if (
                val instanceof NavigationEnd &&
                window.innerWidth <= 992 &&
                this.isToggled()
            ) {
                this.toggleSidebar();
            }
        });
    }

    userObject:any = {};
    authDetails:any = {};

    ngOnInit() {
        this.pushRightClass = 'push-right';
        this.userObject = JSON.parse(this.util.getLocalStorageKey(CommonURL.LOCAL_STORAGE_USER_KEY));

        if(this.userObject == null || this.userObject == undefined)
        this.router.navigate(['/login']);
        else
        {
            this.authDetails = {
                Cu_name : this.userObject.cu_name,
                cu_emailid:this.userObject.cu_emailid,
                auth_token:this.userObject.token
            };
        }
    }

    isToggled(): boolean {
        const dom: Element = document.querySelector('body');
        return dom.classList.contains(this.pushRightClass);
    }

    toggleSidebar() {
        const dom: any = document.querySelector('body');
        dom.classList.toggle(this.pushRightClass);
    }

    rltAndLtr() {
        const dom: any = document.querySelector('body');
        dom.classList.toggle('rtl');
    }
     
    logout(){
        this.util.removeLocalStorageKey(CommonURL.LOCAL_STORAGE_USER_KEY);
        this.util.removeLocalStorageKey(CommonURL.LOCAL_STORAGE_USER_LIST);
        this.util.removeLocalStorageKey(CommonURL.LOCAL_STORAGE_SESSION_TIMESTAMP);
        this.router.navigate(['/login']);
    }

    changeLang(language: string) {
        this.translate.use(language);
    }

    rekeyMfa(authDetails){


        

        if (authDetails.cu_emailid) {
     
            this.sharedService.dialogText = {
                title : 'Generate new MFA key',
                description :CommonUIStrings.REKEY_MFA,
                isInfoDialog : false,
                headerBgClass : CommonUIStrings.CONFIRM_POPUP_BG_CLASS,
                headerIcon : CommonUIStrings.CONFIRM_POPUP_ICON_CLASS
              };
          
              let dialogRef;
              const dialogConfig = new MatDialogConfig();
          
              dialogConfig.disableClose = true;
              dialogConfig.autoFocus = true;
              dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);
          
              dialogRef.afterClosed().subscribe(result => {
          
                if (result) {
                   let headers = new Headers({ 'Authorization' : this.authDetails.auth_token });
                headers.append('Content-Type', 'application/json');
                let options = new RequestOptions({ headers: headers });
                this.util.callPostApi(CommonURL.BASE_URL+CommonURL.URL_REKEY_MFA,{cu_emailid:authDetails.cu_emailid},options).subscribe(data=>{
            
                  this.sharedService.dialogText = {
                    title : 'Success',
                    description :"Successfully Generated new MFA key",
                    isInfoDialog : true,
                    headerBgClass : CommonUIStrings.CONFIRM_POPUP_BG_CLASS,
                    headerIcon : CommonUIStrings.CONFIRM_POPUP_ICON_CLASS
                  };
              
                  let dialogRef;
                  const dialogConfig = new MatDialogConfig();
              
                  dialogConfig.disableClose = true;
                  dialogConfig.autoFocus = true;
                  dialogRef = this.dialog.open(DialogResultCustomDialog,dialogConfig);
                  dialogRef.afterClosed().subscribe(()=>{

                      this.logout();
                  })
                });
                }
               
              });
          
         } else {
            // show popup here
        }
    }
}
