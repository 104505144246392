export class CompanyGroupOnBoardObject {

    formData:any;
  
    static create(Group_det)
    {
  
      return new CompanyGroupOnBoardObject(Group_det);
    }
  
  
  
  
    constructor(Group_det)
    {
  
      this.formData = new FormData();
  
      this.formData.append('gd_name', Group_det.gd_name);

      this.formData.append('timezone_id', Group_det.timezone_id);

      this.formData.append('screen_type_id', Group_det.screen_type_id);

      this.formData.append('parent', Group_det.parent);

      this.formData.append('company_id', Group_det.company_id);

      this.formData.append('is_active', Group_det.is_active);
  
    }
  }
  